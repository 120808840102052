//END Navbar Left
//Navbar top


//END Navbar top

#menu_top{

    .menu1 {
        background-color: rgba(255, 255, 255, 0.96);
        position: relative;
        z-index: 101;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
        padding-left: 0;
        padding-right: 0;
        @media #{$sm} {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
.menu2 {
        height: $menu2;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0;
        line-height: $menu2;
        position: relative;
        z-index: 100;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.15);
        transition: 0.6s;
        transition-delay: 1s;

        .inner {}

        &.fildariane {
            background-color: rgba(255, 255, 255, 0.9);
            transition: background-color .3s;
            padding-bottom: 5px;

            &:hover {
                background-color: $hover;
            }
        }
    }
//+ Menu GAUCHE

.navbar-header {
    display: flex;
    align-items: center;
    height: $menu_top_height;
    padding-top: 4px;
    transition: $transtion_affix;
    justify-content: space-between;
    @media #{$menu_switch} {
        justify-content: flex-start;
    }
    .navbar-right{
        margin-left: auto;
    }
}
.affix .navbar-header{ 
    transition: $transtion_affix;
    height: 64px;
}
#menuleft {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    transition: $transtion_affix;
}

.affix {
    #menuleft {
        height: 30px;
        width: 30px;
        transition: $transtion_affix;
    }
}
#bloc_logo_texte {
    //transform:translateY(0%);
    //transition:0.6s ease-in;
}

.affix #bloc_logo_texte {
    //transform:translateY(-200%);
}

#bloc_logo_ico {
    //transform-origin: 50% 50%;
    //transform:scale(1) translateY(0px) rotateY(0deg);
    //transition:0.3s 0.3s linear;
}

.affix #bloc_logo_ico {
    //transform:scale(0.5) translateY(-65px) rotateY(-90deg);
    //transition:0.3s linear;
}

.navbar-right {
    //float: right;
    border-color: transparent;
    overflow: visible;
    border-top: 0;
    box-shadow: none;

    .nav {
        float: left;
        margin: 0;
        padding-left: 0;
        list-style: none;

        li {
            float: left;
            display: block;
            position: relative;

            a {
                display: block;
                color: $text;
                //line-height: 20px;
                padding: 40px 15px;
                text-decoration: none;
                transition: $transtion_affix;

                &:hover, &.active {
                    color: $tool;
                    background-color: transparent;
                }

                span {
                    position: relative;
                }
            }

            &.active {
                a {
                    color: $tool;
                }
            }

            &:not(:last-of-type) > a > span:after {
                content: "/";
                color: #999999;
                font-size: 13px;
                line-height: 16px;
                position: absolute;
                top: -1px;
                right: -15px;
            }
        }
    }
}

/* .navbar-brand .clq > i{
        display: block;
        position: absolute;
        left: 17px;
        top:50%;
        //bottom: 10px;
        color: $tool;
        font-size: 40px;
        height: 100px;
        line-height: 100px;
        margin-top: -45px;
        opacity: 0;
        transform:rotateY(-90deg);
        transition:0.3s linear;
    }
 */
/* .affix .navbar-brand .clq > i {
    height: 60px;
    line-height: 60px;
    margin-top: -25px;
    opacity: 0.7;
    transform: rotateY(0deg);
    transition: 0.3s 0.3s linear;

    &:hover {
        opacity: 1;
        transition: 0.3s;
    }
} */

.clq {
    position: relative;
    top: 0;
    left: 0;
    //width: 75vw;
    //height: 100px;
    width: auto;
    height: 100%;
    margin-top: 0;
    //transition:$transtion_affix;

    svg {
        position: relative;
        top: 0;
        left: 0;
        //width: 100%;
        //height: 100px;
    width: auto;
    height: 100%;
        //transition: $transtion_affix;
    }

    @media #{$sm} {
        //width: 100%;

        svg {
           // width: auto;
        }
    }
}

.affix .clq {
    top: 0;
    left: 0;
    //height: 60px;    
    //transition: $transtion_affix;

    svg {
        top: 0;
        left: 0;
        //height: 60px;
        //transition: $transtion_affix;
    }
}

.clq {
    text-transform: initial;
}

#cliquez {
    g text {
        animation: lettre_com_opacity 1s ease-in forwards;
    }

    g:nth-of-type(1) text {
        animation-delay: 0.4s;
    }

    g:nth-of-type(2) text {
        animation-delay: 0.5s;
    }

    g:nth-of-type(3) text {
        animation-delay: 0.6s;
    }

    g:nth-of-type(4) text {
        animation-delay: 0.7s;
    }

    g:nth-of-type(5) text {
        animation-delay: 0.8s;
    }

    g:nth-of-type(6) text {
        animation-delay: 0.9s;
    }

    g:nth-of-type(7) text {
        animation-delay: 1s;
    }

    g:nth-of-type(8) text {
        animation-delay: 1.1s;
    }

    g:nth-of-type(9) text {
        animation-delay: 1.2s;
    }

    g:nth-of-type(10) text {
        animation-delay: 1.3s;
    }

    g:hover {}

    g {
        cursor: pointer;
        transform-origin: 50% 50%;
        animation: lettre_un 1s ease-in forwards;
    }

    g:nth-of-type(1) {
        animation-delay: 0.4s;
    }

    g:nth-of-type(2) {
        animation-delay: 0.5s;
    }

    g:nth-of-type(3) {
        animation-delay: 0.6s;
    }

    g:nth-of-type(4) {
        animation-delay: 0.7s;
    }

    g:nth-of-type(5) {
        animation-delay: 0.8s;
    }

    g:nth-of-type(6) {
        animation-delay: 0.9s;
    }

    g:nth-of-type(7) {
        animation-delay: 1s;
    }

    g:nth-of-type(8) {
        animation-delay: 1.1s;
    }

    g:nth-of-type(9) {
        animation-delay: 1.2s;
    }

    g:nth-of-type(10) {
        animation-delay: 1.3s;
    }

    g:hover {}
}

.fillboder {
    stroke-dasharray: 400;
    stroke-dashoffset: 0;
}

.st0:hover {
    fill: #eee;
    transition: fill 1s;
}

/***********************
************************
****** DEBUT MENU_MODULE */

.menu_module {
    text-align: center;
    @extend .bold;    
    color: #FFF;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1;
    display: block;
    padding: 50px 10px;
    margin-top: 20px;
    border-top: 7px dashed #fff;

    &.more_blog {
        margin-top: $mb;
        border-top-color: $text;
    }
}

.parent_module {
    text-align: center;

    .intro-portfolio, .blog_block {
        float: none;
        display: inline-block;
        vertical-align: top;
    }
}


/***********************
************************
****** FIN MENU_MODULE */

.navbar-header {

    .navbar-brand {
        transition: $transtion_affix;
        position: relative;
        padding: 0;    
        width: auto;
        height: 100%;

        img {
            display: block;
            padding: 10px;
            height: 100px;
            transition: $transtion_affix;
        }
    }
}

.navbar {
    text-transform: uppercase;
    font-size: 12px;
}

#menu_top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
    }

    > div {
        position: relative;

        &:before {
            z-index: 1000;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi├ö├ç┬¬gd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
            background-size: 100%;
            background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #add072), color-stop(7.69231%, #add072), color-stop(15.38462%, #e5ea46), color-stop(23.07692%, #e5ea46), color-stop(30.76923%, #e3c227), color-stop(38.46154%, #e3c227), color-stop(46.15385%, #e1a93c), color-stop(53.84615%, #e1a93c), color-stop(61.53846%, #e28d4a), color-stop(69.23077%, #e28d4a), color-stop(76.92308%, #da633b), color-stop(84.61538%, #da633b), color-stop(92.30769%, #d2442e), color-stop(100%, #d2442e));
            background-image: -moz-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
            background-image: -webkit-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
            background-image: linear-gradient(to right, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
        }
    }
}

#menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 1030;
    height: $menu2;

    > div {
        position: relative;
    }

    .scroll-line {
        position: absolute;
        overflow: hidden;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        height: 4px;
        margin-bottom: 0px;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi├ö├ç┬¬gd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
        background-size: 100%;
        background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #add072), color-stop(7.69231%, #add072), color-stop(15.38462%, #e5ea46), color-stop(23.07692%, #e5ea46), color-stop(30.76923%, #e3c227), color-stop(38.46154%, #e3c227), color-stop(46.15385%, #e1a93c), color-stop(53.84615%, #e1a93c), color-stop(61.53846%, #e28d4a), color-stop(69.23077%, #e28d4a), color-stop(76.92308%, #da633b), color-stop(84.61538%, #da633b), color-stop(92.30769%, #d2442e), color-stop(100%, #d2442e));
        background-image: -moz-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
        background-image: -webkit-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
        background-image: linear-gradient(to right, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
        width: 0%;
    }
}


#titre {
    text-align: right;
    padding-right: 110px;
    display: inline-block;
    height: 1.6rem;

    span {
        position: relative;
        padding: 10px 15px;
        line-height: 20px;
        display: inline-block;
        font-weight: 700;
        font-size: 1.6rem;

        &.separator {
            padding: 10px 0px;
        }
    }

    .titrejq {
        h2 {
            margin: 0;
            padding: 10px 0px;
            font-size: 1.3rem;
            display: inline;
            vertical-align: sub;
            overflow: hidden;
            font-family: lato;
            font-weight: 700;
        }
    }
}

.menu3 {
    @extend .menu2;

    box-shadow: none;
    height: inherit;
    line-height: inherit;
    background-color: transparent;
    display: none;
    @media #{$menu_switch} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .inner {
        position: relative;
        display: inline-block;

        #type_filtre {
            background-color: rgba(255, 255, 255, 0.3);
            font-family: lato;
            text-transform: uppercase;
            border: none;
            padding: 2px 5px;
            border-radius: 10px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
        }
    }

    > div {
        width: 100%;

        //cursor: pointer;
        .inner {
            width: 100%;
        }

        @media #{$md} {
            width: auto;

            .inner {
                width: auto;
            }
        }

        @media #{$lg} {}

        @media #{$lg} {}

        &.filtre_gauche, &.filtre_droite {
            .inner {
                background-color: rgba(255, 255, 255, 0.9);

                &:after {}
            }
        }

        &.filtre_gauche {
            padding-right: 0;
            height: $menu3-g;

            .inner {
                height: $menu3-g;

                &:after {
                    right: -$menu3-g;
                    border-width: $menu3-g $menu3-g 0 0;
                }
            }

            > div:hover {
                a {
                    margin-right: 15px;
                }
            }
        }

        &.filtre_droite {
            text-align: right;
            padding-left: 0;
            height: $menu3-d;

            .inner {
                height: $menu3-d;

                &:after {
                    left: -$menu3-d;
                    border-width: $menu3-d 0 0 $menu3-d;
                }
            }

            > div:hover {
                a {
                    margin-left: 15px;
                }
            }
        }

        .inner:hover {
            background-color: rgba(255, 255, 255, 0);

            &:after {
                border-color: rgba(255, 255, 255, 0) transparent transparent transparent;
            }

            a {
                background-color: $blanc;
                border-radius: 0px 0px 4px 4px;
            }
        }
    }
}

.social-buttons {
    height: $size;
    margin: auto;
    font-size: 30px;
    text-align: center;

    .social-button {
        display: inline-block;
        width: $size + 20px;
        height: $size;
        border-radius: 0px;
        margin: 0 0px;
        text-align: center;
        position: relative;
        overflow: hidden;

        //overflow bug fix
        opacity: .99;
        transition: $transition;

        &:before {
            content: '';
            width: 140%;
            height: 170%;
            position: absolute;
            top: 90%;
            left: -110%;
            transform: rotate(45deg);
            transition: $transition;
        }

        .myfont {
            vertical-align: middle;
            transform: scale(0.8);
            transition: $transition;
            display: block;
            height: 50px;

            //line-height: $size;
            padding: 0;
            top: 0;

            &:before {
                font-size: $size - 10px;
                line-height: 50px;
                height: 50px;
            }
        }

        &.gauche {
            &:before {
                background-color: $tool;
            }

            .myfont {
                position: relative;
                color: $tool;
                opacity: 0.7;

                &:before {
                    font-size: 35px;

                    //top: 5px;
               
                }
            }

            span {
                position: relative;
                display: block;
                padding: 10px 0px;
                font-size: 1.1rem;
                color: #fff;
                @extend .bold;
                top: 65px;
                transition: $transition;
            }

            &:hover {
                .myfont {
                    opacity: 1;
                    top: -43px;
                }

                span {
                    top: -43px;
                }
            }

            &.active {
                .myfont {
                    opacity: 1;
                }
            }
        }

        &.droit {
            @each $class, $color in $colors {
                &.#{$class} {
                    &:before {
                        background-color: #{$color};
                    }

                    .myfont {
                        color: #{$color};
                    }
                }
            }
        }

        &:focus,
        &:hover {
            text-decoration: none;
            opacity: 1;

            &:before {
                top: -35%;
                left: -20%;
            }

            .myfont {
                color: #fff !important;
                transform: scale(1);

                &:before {
                    position: relative;
                }
            }
        }

        &.active {
            opacity: 1;

            &:before {
                top: -35%;
                left: -20%;
            }

            .myfont {
                color: #fff !important;

                &:before {
                    position: relative;
                }
            }
        }
    }
}


//+ Menu GAUCHE
#menuleft {
    position: relative;
    text-decoration: none;
    //vertical-align: middle;

    @media #{$md} {
        margin-right: 21px;
    }

    div {
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: $tool;
        opacity: 0.7;
        transition: 0.2s ease-in-out;

        $num: 0;

        @for $i from 1 through 9 {
            @if $i % 3 == 1 {
                $num: 0;
            }

            &:nth-of-type(#{$i}) {
                transform: translate(12px * $num, 12px * floor(($i - 1) / 3));
            }

            $num: $num + 1;
        }
    }

    &:hover {
        .pix {
            opacity: 1;

            &:nth-of-type(1) {
                transform: translate(-4px * 1.5, -4px * 1.5);
            }

            &:nth-of-type(3) {
                transform: translate(20px * 1.5, -4px * 1.5);
            }

            &:nth-of-type(7) {
                transform: translate(-4px * 1.5, 20px * 1.5);
            }

            &:nth-of-type(9) {
                transform: translate(20px * 1.5, 20px * 1.5);
            }
        }
    }

    &.show_menu {
        .pix {
            &:nth-of-type(1) {
                transform: translate(0, 0 * 1.5);
            }

            &:nth-of-type(2) {
                transform: translate(4px * 1.5, 4px * 1.5);
            }

            &:nth-of-type(3) {
                transform: translate(16px * 1.5, 0 * 1.5);
            }

            &:nth-of-type(4) {
                transform: translate(4px * 1.5, 12px * 1.5);
            }

            &:nth-of-type(6) {
                transform: translate(12px * 1.5, 4px * 1.5);
            }

            &:nth-of-type(7) {
                transform: translate(0, 16px * 1.5);
            }

            &:nth-of-type(8) {
                transform: translate(12px * 1.5, 12px * 1.5);
            }

            &:nth-of-type(9) {
                transform: translate(16px * 1.5, 16px * 1.5);
            }
        }

        &:hover {
            .pix {
                &:nth-of-type(1) {
                    transform: translate(-4px * 1.5, -4px * 1.5);
                }

                &:nth-of-type(2) {
                    transform: translate(2px * 1.5, 2px * 1.5);
                }

                &:nth-of-type(3) {
                    transform: translate(20px * 1.5, -4px * 1.5);
                }

                &:nth-of-type(4) {
                    transform: translate(2px * 1.5, 14px * 1.5);
                }

                &:nth-of-type(6) {
                    transform: translate(14px * 1.5, 2px * 1.5);
                }

                &:nth-of-type(7) {
                    transform: translate(-4px * 1.5, 20px * 1.5);
                }

                &:nth-of-type(8) {
                    transform: translate(14px * 1.5, 14px * 1.5);
                }

                &:nth-of-type(9) {
                    transform: translate(20px * 1.5, 20px * 1.5);
                }
            }
        }
    }
}

body {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 0.5s ease-out;

    #menu_wrapper {
        width: $largeur_menu;
        height: 100%;
        overflow-y: auto;
        position: absolute;
        top: 0;
        left: -$largeur_menu;
        z-index: 100;
        opacity: 1;
        perspective: 1000px;
        transform-style: preserve-3d;

        h2 {
            cursor: pointer;
            margin: 0;
            padding: 3.4rem 0.5rem 3.4rem 2rem;
            @extend .bold;
            font-size: 2em;
            background: $tool;
            font-family: lato;
            .icon-close{    
                display: block;
                font-size: 4rem;
                color: #fff;
                text-align: right;
                position: absolute;
                right: 1rem;
                top: 2.4rem;
                line-height: 1;
                font-weight: 400;
            }

            span, i {
                display: inline-block;
                vertical-align: middle;
            }

            i {
                color: rgba(0, 0, 0, 0.3);
                margin-right: 1.5rem;
            }

            span {
                color: rgba(255, 255, 255, 0.8);
            }
        }

        .nav.menu {
            padding-left: 0;
            height: 100%;
            transform-origin: 100% 50%;
            transform: rotateY(-90deg);
            background-color: $tool;
            transition: all 0.6s;

            li {
                list-style: none;
                &.current.active {
                    > a {
                        opacity: 1;
                        background: #fff;
                        color: $tool;
                    }
                }
            }

            > li {
                 a {
                    position: relative;
                    font-size: 2rem;
                    line-height: 1;
                    padding: 1.7rem 0.5rem 1.7rem 2rem;
                    color: #fff;
                    display: block;
                    background-color: $tool;
                    opacity: 0.9;
                    transition: .3s;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background: rgba(255, 255, 255, 0.1);
                    }

                    span {
                        &:before {
                            font-size: 2.5rem;
                            display: inline-block;
                            vertical-align: text-top;
                            margin-right: 1.5rem;
                            font-family: "myfont" !important;
                            font-style: normal !important;
                            font-weight: normal !important;
                            font-variant: normal !important;
                            text-transform: none !important;
                            speak: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                    }

                    &:hover, &:focus, &.active {
                        opacity: 1;
                        background: #fff;
                        color: $tool;

                        span {
                            &:before {}
                        }
                    }
                }


                &.item-101 > a > span:before {
                    content: "\45";
                }

                // accueil home
                &.item-105 > a > span:before {
                    content: "\47";
                }

                // Blog coffee
                &.item-107 > a > span:before {
                    content: "\71";
                }

                // r├®a attache-case
                .item-118 > a > span:before {
                    content: "\71";
                }

                // tous r├®a
                .item-108 > a > span:before {
                    content: "\63";
                }

                // comm comm
                .item-109 > a > span:before {
                    content: "\66";
                }

                // siteweb site-web
                .item-117 > a > span:before {
                    content: "\68";
                }

                // webmarket webmarketing
                &.item-106 > a > span:before {
                    content: "\49";
                }

                // clients
                &.item-116 > a > span:before {
                    content: "\69";
                }

                // portail portail
                &.item-122 > a > span:before {
                    content: "\79";
                }

                // rechercher search
                &.item-132 > a > span:before {
                    content: "\6a";
                }

                // contact envelope-o
                &.item-123 > a > span:before {
                    content: "\42";
                }

                // admin edit

                &.dropdown {
                    .dropdown-menu {
                        > li {
                            > a {
                                padding: 1.4rem 0.5rem 1.4rem 1rem;
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &.show_menu {
        transform: translateX($largeur_menu);
        overflow-y: hidden;
        transition: all 0.5s;

        #menu_wrapper {
            .nav.menu {
                transform: rotateY(0deg);
                transition: all 0.6s ease-in-out;
            }
        }
    }
}


