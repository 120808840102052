/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

//@import "compass/reset";
//@import "compass/css3/images";
//@import "compass/css3/box-sizing";
//@import "compass/css3/box-sizing";

@mixin background-image($images...) {
    background-image: linear-gradient($images...);
}


@import "includes/reset.scss";
@import "includes/flexboxgrid.scss";
@import "includes/mixins.scss";
@import "includes/variables.scss";
@import "includes/typography.scss";
@import "includes/common.scss";
@import "includes/layout.scss";
@import "includes/menu.scss";
@import "includes/animations.scss";
@import "includes/icon.scss";
@import "includes/forms.scss";
@import "includes/banner.scss";
@import "includes/accueil.scss";
@import "includes/footer.scss";
@import "includes/blog.scss";
@import "includes/realisations.scss";
@import "includes/clients.scss";
@import "includes/portail.scss";
@import "includes/recherche.scss";

* {
    box-sizing :border-box;
}

html {
    margin: 0;
    height: 100%;
    //font-size: 10px;
    @media #{$sm} {
        //font-size: 12px;
    }
    @media #{$md} {
        //font-size: 14px;
    }
    @media #{$lg} {
        //font-size: 16px;
    }
    @media #{$xl} {
        //font-size: 18px;
    }
}


body {
    position: relative;
	height: 100%;
	@include lato;
    overflow-x: hidden;
}
a, a:hover, a:focus, a:link, a:visited{
    outline: 0;
    color:inherit;
    text-decoration: none;
}

.container-fluid{
    &.no_padding{
        padding-left: 0;
        padding-right: 0;
    }    
}
.row{
    &.no_margin{
        margin-left: 0;
        margin-right: 0;
    }
}
.row > div.no_padding{
    padding-left: 0;
    padding-right: 0;
}


.pt_menu{    
    padding-top: $menu_top_height;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.max-width{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.relative{
    position: relative;
}
.shadow_container{
    margin-bottom: 3rem;
}
.shadow{    
    opacity: 0.8;
    position: absolute;
    width: 60%;
    height: 40px;
    left: 40%;
    margin-left: -20%;
    z-index: -1;
    bottom: -3rem ;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    background: #000;
    border-radius: 50%;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
}

a.back-to-top{
    opacity: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: $gris_fonce2;
    font-size: 2rem;
    z-index: 6000;
}
.scroll-line {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3003;
    height: 6px;
    margin-bottom: 0px;
    width: 0%;
    border-radius: 30px;
    background: $tool;
}

#system-message-container{
    font-size: 2rem;
    letter-spacing: 1px;
    margin-top:  $menu_top_height;
    margin-bottom:  $menu_top_height;
    dt{
        margin-bottom:  1rem;
        font-size: 160%;
    }
}

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  //-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  //filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  //-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  //filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}



.filtres{
  //display: none;
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: $blanc;
  overflow-y: hidden;
  text-align: left;
  text-align: center;
  @media #{$md}{
    position: relative;
    z-index: 0;
    bottom: initial;
    left: initial;
    right: initial;
  }
  .buttonn-group{
    display: flex;
    justify-content:flex-start;
    align-items:center;
    flex-direction:row;    
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative;      
    &::-webkit-scrollbar {
        width: 8px;
        padding-left: 10px;
        padding-right: 10px;
    }
    &::-webkit-scrollbar-thumb { 
        border: 7px solid transparent;
        background: $tool;
        background-clip: content-box;
        min-height: 100px; 
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    @media #{$sm}{
    justify-content:center;
    }
    .buttonn{
      white-space: nowrap;
      text-transform: capitalize;
      font-size: 1.6rem;
      @media #{$md} {
       font-size: 1.6rem; 
      }
      @media #{$xl} {
       font-size: 2rem; 
      }
      border-radius: 2rem;
      //box-shadow: 0 0 0 rgba(0,0,0,0);
      padding: 0.5rem 0.5rem;
      //letter-spacing: 1px;
      margin: 1rem 0.5rem;
      color: $gris_fonce;
      transition: all 0.3s ease-out;
      &.is-checked,&.active{
        color:$blanc;
        background-color: $gris_fonce2;
        box-shadow: 0 1px 3px rgba(0,0,0,0.3);
      }
      &.disabled{
        color:$blanc;
        background-color: #cacaca;
      }
      span{font-size: 80%;}
    }
    i{
      &:after{}
    }
    
  }  
}
