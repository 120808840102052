

//Responsive variable
$xs: "(min-width: 0px)";
$sm: "(min-width: 544px)";
$md: "(min-width: 768px)";
$lg: "(min-width: 992px)";
$xl: "(min-width: 1280px)";
$xxl: "(min-width: 1920px)";
$xxxl: "(min-width: 2000px)";

$lato: 'Lato', sans-serif;

$gutter: 2rem;

$padding: 20px;
$mb: 60px;
$transtion_affix: 0.4s cubic-bezier(.64,.55,.4,1.47);

$line-height: 1.5em;
//menu gauche
$largeur_menu: 300px;

$menu1: 101px;
$menu2: 50px;
$menu3-g: 50px;
$menu3-d: 50px;
$hover: rgba(255, 255, 255, 1);

$transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
$size: 50px;
$colors: ("facebook": "#3B5998", "linkedin": "#3CF", "google": "#DC4A38", "enveloppe": "#F26798", "viadeo": "#ED7256");

$temps_anime: all 0.3s ease-out;
$ombre_bulle_avant: 0 1px 2px rgba(0, 0, 0, 0.2);
$ombre_bulle_apres: 0px 10px 10px rgba(0, 0, 0, 0.4);


$pc_width: 48vw;
$pc_height: $pc_width * (534/800);
$pc_bord: $pc_width * (20/800);
$pc_bord_bas: $pc_width * (40/800);
$pc_bouton_top: $pc_width * (6/800);
$pc_bouton_bottom: $pc_width * (8/800);
$pc_base_width: $pc_width * (260/800);
$pc_base_height: $pc_width * (157.12/800);


$tel_width:17vw;
$tel_height:$tel_width * (300/200);
$tel_br:$tel_width * (10/200);
$tel_bt:$tel_width * (50/200);
$tel_bb:$tel_width * (65/200);
$tel_side:$tel_width * (5/200);

$tel_before_width:$tel_width * (60/200);
$tel_before_height:$tel_width * (2/200);
$tel_after_width:$tel_width * (35/200);
$tel_after_bottom:$tel_width * (50/200);
$tel_after_ml:$tel_width * (20/200);



$tablet_width:25vw;
$tablet_height:$tablet_width * (499/382);
$tablet_bord:$tablet_width * (24/382);
$tablet_brad:$tablet_width * (10/382);
$tablet_bouton: $tablet_width * (6/382);

////home

$wimgpiedecran: 195px;
$himgpiedpecran: 103px;

$wecran: 600px;
$hecran: 350px;
$rightborderecran:25px;
$topborderecran:25px;
$bottomborderecran:50px;
$radiusecran:15px;

$wtablet: 260px;
$htablet: 330px;
$rightbordertablet:25px;
$topbordertablet:25px;
$radiustablet:15px;

$wmobile: 150px;
$hmobile: 300px;
$rightbordermobile:6px;
$topbordermobile:30px;
$bottombordermobile:35px;
$radiusmobile:25px;


// colors
$menu: #263238;
$text: #6E6E6E;
$blanc: #fff;
$button: #00A5BC;
$sh1: 0px 3px 2px rgba(0,0,0,0.3);
$sh2: 0px 2px 1px rgba(0,0,0,0.8);
$sh3: 0px 1px 2.5px rgba(0, 0, 0, 0.6);
$sp: 0px 3px 0.7px rgba(0,0,0,0.8);
$bar: 0 1px 3px rgba(0, 0, 0, .25), inset 0 -1px 0 rgba(0, 0, 0, .1);

$brad: 6px;

$rougelink: #be1e2d;

$tool : #ab2828;
$toolw : rgba(171, 40, 40, 0.7);

$bleuciel:#339ebf;
$bleucielw:rgba(51,158,191,0.7); 


$turquoise1:#16a085;
$turquoise2:#1abc9c;

$vert1:#27ae60;
$vert2:#2ecc71;
$bleu1:#3498DB;
$bleu2:#75C0D7;
$violet1:#8e44ad;
$violet2:#9b59b6;
$nuit1:#2c3e50;
$nuit2:#34495e;
$jaune1:#F39B12;
$jaune2:#F1B20F;
$orange1:#d35400;
$orange2:#e67e22;
$rouge1:#c0392b;
$rouge2:#e74c3c;
$gris3:#bdc3c7;
$gris4:#ecf0f1;
$gris1:#7f8c8d;
$gris2:#95a5a6;





















// typography variables
$rem: 1rem;
$vw: 1vw;
$vh: 1vh;
$em: 1em;
$lheight: 1.4;

// typography variables

$padding: 2rem;
$margin: 2rem;

$mb : 40px;

$scroll-line_height : 4px;

// layout variables
$menu_top_height: 104px;
$margin_container: 80px;
.menu_top_pt {
	padding-top: $menu_top_height;
}

$menu_switch: $md;
$menu_switch_var : 'md';



$blanc: #fff;
$blanc_opacity: rgba(255,255,255,0.8);
$noir: #000;
$noir_opacity: rgba(0,0,0,0.5);
$gris_fonce: rgba(66, 66, 66,1);
$gris_fonce2: rgba(50, 53, 52,1);
$gris_fonce_opacity: rgba(66, 66, 66,0.8);
$gris_fonce2_opacity: rgba(50, 53, 52,0.8);

.blanc{color: $blanc;}
.noir{color: $noir;}
.noir_opacity{color: $noir_opacity;}
.gris_fonce{color: $gris_fonce;}
.gris_fonce_opacity{color: $gris_fonce_opacity;}
.gris_fonce2{color: $gris_fonce2;}
.gris_fonce2_opacity{color: $gris_fonce2_opacity;}

.bg-blanc{background-color: $blanc;}
.bg-blanc_opacity{background-color: $blanc_opacity;}
.bg-noir{background-color: $noir;}
.bg-noir_opacity{background-color: $noir_opacity;}
.bg-gris_fonce{background-color: $gris_fonce;}
.bg-gris_fonce_opacity{background-color: $gris_fonce_opacity;}
.bg-gris_fonce2{background-color: $gris_fonce2;}
.bg-gris_fonce2_opacity{background-color: $gris_fonce2_opacity;}


.bg-grad-bv{
background-image: -moz-linear-gradient(left, rgba(41,170,225,1) 0%, rgba(0,151,88,1) 100%);
background-image: -webkit-linear-gradient(left, rgba(41,170,225,1) 0%,rgba(0,151,88,1) 100%);
background-image: linear-gradient(to right, rgba(41,170,225,1) 0%,rgba(0,151,88,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29aae1', endColorstr='#009758',GradientType=1 );
}
.bg-grad-bv-opacity{
background-image: -moz-linear-gradient(left, rgba(41,170,225,0.8) 0%, rgba(0,151,88,0.8) 100%);
background-image: -webkit-linear-gradient(left, rgba(41,170,225,0.8) 0%,rgba(0,151,88,0.8) 100%);
background-image: linear-gradient(to right, rgba(41,170,225,0.8) 0%,rgba(0,151,88,0.8) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29aae1', endColorstr='#009758',GradientType=0.8 );
}

.bg-grad-top-bottom-opacity{
background-image: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
}
.bg-grad-tricone{
background-image: -moz-linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%,rgba(0,0,0,0) 100%);
background-image: -webkit-linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%,rgba(0,0,0,0) 100%);
background-image: linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%,rgba(0,0,0,0) 100%);
}
.bg-grad-bottom-top-opacity{
background-image: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
background-image: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 );
}

.bg-grad-entree{
background: rgb(36,150,76);
background-image: -moz-linear-gradient(top, rgba(36,150,76,1) 1%, rgba(0,110,46,1) 100%);
background-image: -webkit-linear-gradient(top, rgba(36,150,76,1) 1%,rgba(0,110,46,1) 100%);
background-image: linear-gradient(to bottom, rgba(36,150,76,1) 1%,rgba(0,110,46,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24964c', endColorstr='#006e2e',GradientType=0 );
}

.bg-grad-plat{
background: rgb(242,197,33);
background-image: -moz-linear-gradient(top, rgba(242,197,33,1) 0%, rgba(221,164,31,1) 100%);
background-image: -webkit-linear-gradient(top, rgba(242,197,33,1) 0%,rgba(221,164,31,1) 100%);
background-image: linear-gradient(to bottom, rgba(242,197,33,1) 0%,rgba(221,164,31,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2c521', endColorstr='#dda41f',GradientType=0 );
}

.bg-grad-dessert{
background: rgb(37,124,150);
background-image: -moz-linear-gradient(top, rgba(37,124,150,1) 1%, rgba(28,83,115,1) 100%);
background-image: -webkit-linear-gradient(top, rgba(37,124,150,1) 1%,rgba(28,83,115,1) 100%);
background-image: linear-gradient(to bottom, rgba(37,124,150,1) 1%,rgba(28,83,115,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#257c96', endColorstr='#1c5373',GradientType=0 );
}


.h100 {height: 100%;}
.w100 {width: 100%;}
.img_resp{    
	max-width: 100%;
    height: auto;
}
.img_fullw{
	width: 100%;
	height: auto;
}

.posr{position: relative;}
.flex_center{
	align-items: center;
    justify-content: center;
}
.flex_center_space-between{
	align-items: center;
    justify-content: space-between;
}
.flex_center_space-around{
	align-items: center;
    justify-content: space-around;
}

.br_1px{border:solid 1px;}
.br_blanc{border-color: $blanc;}
.ovh{overflow: hidden;}

.mxw1920{
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	@media #{$xxl}{
		max-width: 1280px;
	}
	@media #{$xxxl}{
		max-width: 1920px;
	}
}


