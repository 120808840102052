
.container {
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 auto 100px;
    padding: 0;
    @media #{$md}{
        padding: 15px;
    }

    .card {
        position: relative;
        background: #ffffff;
        border-radius: 5px;
        padding: 60px 0 40px 0;
        box-sizing: border-box;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: .3s ease;

        &:first-child {
            background: #fafafa;
            height: 10px;
            border-radius: 5px 5px 0 0;
            margin: 0 10px;
            padding: 0;
        }
        
    }
}

body.modal-open{
    overflow-y: hidden;
}
.mamodal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    z-index: 1040;
    background-color: #000;
    transition:0.5s;
    &.gogo{
        display: block;        
        opacity: 0.3;
    }
}


.mamodal{    
    opacity: 0;
    position: fixed;
    max-width: 500px;
    transform: translate3d(-50%, -100%, 0);
    top: -200%;
    left: 50%;
    outline: 0;
    z-index: 1050;
    transition: .6s ease-out;
    &.gogo{ 
        opacity: 1;
        overflow: hidden;
        top: 0; 
        bottom: 0;
        /* animation: gogo 1s forwards; */
        transform: translate3d(-50%, 0%, 0);
        transition: 1s 0.7s cubic-bezier(.3,.3,0,1);
    }

    .modal-dialog {
        .modal-content {
            .modal-title {
                color: #fff;
                text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
                font-size: 3rem;
                text-align: center;
            }
        }
    }

    &.motscles {
        .modal-dialog {
            .modal-content {

                .modal-header {
                    padding: 20px 0 0px;
                    border: none;
                    background: rgba(245, 245, 245, 0.95);

                    h2.title_modal {
                        padding-bottom: 0px;
                        margin-bottom: 20px;
                    }
                }

                .modal-title {
                    margin: 0;
                    line-height: 1.42857;
                    color: #fff;
                    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
                    font-size: 3rem;
                    text-align: center;
                }
                .modal-body{
                    text-align: center;
                    background: rgba(245, 245, 245, 0.95);
                    text-align: center;
                    .jmoddiv.jmodinside{
                        > div{
                            display: inline-block;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &.formulaire, &.connexion,&.rechercher {
        .modal-dialog {
            .modal-content {
                background: transparent;
                box-shadow: none;
                border: none;

                .modal_header {
                    padding: 60px 0;
                }

                .modal-body {
                    padding: 0;
                    .motcle {}
                }
            }
        }
    }
}


.cck_forms {
    position: relative;
    margin: 0 2rem 5rem;
    @media #{$sm}{
        margin: 0 4rem 5rem;
    }

    .cck_form_text, .cck_form_textarea,.cck_form_search_generic {
        input, textarea {
            outline: none;
            z-index: 1;
            position: relative;
            background: none;
            width: 100%;
            height: 6rem;
            border: 0;
            color: #212121;
            font-size: 2.4rem;
            font-weight: 400;
        }
        textarea {
            height: 8rem;
        }
    }

    .cck_label_text, .cck_label_textarea,.cck_label_search_generic {
        label {
            position: absolute;
            top: 0;
            left: 0;
            color: #ccc;
            font-size: 2.4rem;
            line-height: 1;
            font-weight: 300;
            display: inline;
            line-height: 6rem;
            height: 6rem;
            overflow-y: hidden;
            transition: 0.2s ease;

            &.focused {
                color: #9d9d9d;
                transform: translate(-12%, -50%) scale(0.75);
            }
        }
    }

    .bar {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #757575;
        width: 100%;
        height: 1px;

        &:before, &:after {
            content: '';
            position: absolute;
            background: $tool;
            width: 0;
            height: 2px;
            -webkit-transition: .2s ease;
            transition: .2s ease;
        }

        &:before {
            left: 50%;
        }

        &:after {
            right: 50%;
        }
    }

    &:hover {
        .bar {
            &:before, &:after {
                width: 50%;
                height: 3px;
            }
        }
    }
}
.recherche_content {
    .cck_forms {
        margin: 70px 0 10px;
        .cck_form_search_generic{
            padding: 10px 0px;
            input{
                @extend .lato;
                color: #fff;
                font-size: 13rem;
                height: 13rem;
                position: relative;
            }
        }
        .cck_label_search_generic{
            label{
                left: 10px;
                color: #fff;
                font-size: 5rem;
                line-height: 1;
                line-height: 13rem;
                &.focused {
                    font-size: 2.4rem;
                    line-height: 1;
                    transform: translate(-15%, -50%) scale(0.8);
                }
            }
        }
        .cck_form_button_submit{
            margin-top: 1rem;
            margin-bottom: 3rem;;
            input{
                background: rgba(255, 255, 255, 0.3);
                padding: 2rem 0px;
                font-size: 1.7rem;
                font-weight: 400;
                color: #fff;
            }
        }
        .bar{
            background-color: #fff;
            height: 2px;
        }
        &.cck_recherche_bouton{
            margin-top: 2rem;
        }
    }
    .nbre_resultats{
        color: #fff;
        font-size: 1.8rem; 
        line-height: 1.2;
        .search_motcle{
            font-weight: 900;
            color: #fff;
            border-bottom: 3px dashed #fff;
        }       
    }
}
.resultat{
    .banner{
        box-shadow: none;
        transition:0.6s;
    }
    &:hover .banner{
        box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, .4),inset 0 -2px 12px 0 rgba(0, 0, 0, .2);
    }

}

.cck_form_select_simple{
    select{
        background: rgba(151, 174, 181, 0.71);
        padding: 2rem 0px;
        font-size: 1.7rem;
        font-weight: 400;
        color: #fff;
        option{
            padding: 2rem 0px;
        }
    }
}
.cck_form_select_dynamic{
    select{
        background: rgba(151, 174, 181, 0.71);
        padding: 20px 0px;
        font-size: 1.7rem;
        font-weight: 400;
        color: #fff;
        option{
            padding: 2rem 0px;
        }
    }
}
.cck_form_calendar{
    input{
        background: rgba(255, 255, 255, 0.18);
        padding: 2rem 0px;
        font-size: 1.7rem;
        font-weight: 400;
        color: #fff;
    }
    .btn .icon-calendar{
        padding: 0px 1.5rem;
        font-size: 3rem;
        line-height: 1.9;
        color: $tool;    
    }
}
.title_modal {
    position: relative;
    z-index: 1;
    border-left: 5px solid $tool;
    margin: 0 0 35px;
    padding: 1rem 0rem 1rem 1rem;
    color: $tool;
    font-size: 2.8rem;
    font-weight: 600;
    text-transform: uppercase;
    @media #{$sm}{
        padding: 1rem 0rem 1rem 5rem;
        font-size: 3rem;
    }
}

#seblod_form_m108 .cck_module_search {
    text-align: center;
    margin-top: 2rem;

    .cck_form_button_submit {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.erreur.icon-close{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 6.5rem 1rem;
    font-size: 4rem;
    color: #fff;
    font-weight: 600;
    line-height: 1;
    z-index: 11;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.4);
    &:before{
        color:$tool;
    }
}
.cck_form_button_submit,.cck_form_button_free,.ck_form_button_submit {
    text-align: center;
    display: inline-block;
    #boutonmotcle {
        position: relative;
        margin: 2rem;
    }
    .bouton_new {
        color: rgb(110, 110, 110);
    }
    input, div, .galerie_lien,.bouton_new {
        outline: 0;
        cursor: pointer;
        position: relative;
        display: inline-block;
        background: 0;
        width: 230px;
        margin: 0 auto rem;
        border: 2px solid #e3e3e3;
        padding: 1.5rem 0;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        overflow: hidden;
        transition: .3s ease;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            background: $tool;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            margin: -15px 0 0 -15px;
            opacity: 0;
            transition: .3s ease;
        }

        span {
            position: relative;
            z-index: 1;
            color: #ddd;
            transition: .3s ease;
        }

        &:hover, &:active, &:focus {
            border-color: $tool;
        }
    }
}


.panel{
        text-align: center;
    h3{
        margin-top: $mb/2;
        margin-bottom: $mb/3;
    }
    .filtre_panel{
        > div {
            margin-top: $mb/2;
            margin-bottom: $mb/2;
            text-align: center;
            display: block;
        }
    }

}


.motcle {
    display: inline-block;
    margin: 0 10px 5px 0;
    padding: 10px 14px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    overflow: hidden;
    color: $text;
    cursor: pointer;
    transition: .4s;

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }

    span {
        position: relative;
        z-index: 2;
        display: inline-block;

        &:before {
            content: "\6f";
            text-transform: initial;
            font-family: myfont;
            font-size: 1.3rem;
            padding-right: 2px;
            display: inline-block;
            opacity: 0.5;
            transform: rotate(0deg);
            transition: .4s;
        }
    }

    &:before {
        position: absolute;
        content: '';
        width: 0;
        left: 50%;
        bottom: 0;
        height: 3px;
        background-color: $tool;
        height: 120%;
        left: -10%;
        transform: skewX(15deg);
        z-index: 1;
        transition: .4s;
    }

    &:hover, &.selected {
        color: $blanc;
        text-decoration: none;

        &:before {
            width: 100%;
            left: -8%;
            color: #5bcaff;
            width: 120%;
        }

        span {
            &:before {
                opacity: 1;
            }
        }
    }

    &.selected:hover {
        span {
            &:before {
                content: "\73";
                transform: rotate(720deg);
            }
        }
    }
}

.filtre {
    margin-bottom: 10px;
    margin-top: 10px;

    > a {
        display: block;
        position: relative;
        height: 80px;
        color: #fff;

        img {
            display: inline-block;
            vertical-align: middle;
            background-color: $rouge1;
            padding: 25px;
            height: 80px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            background-color: $rouge2;
            padding: 10px 20px;
            height: 80px;
            line-height: 60px;

            @include heading-font;
        }

        &.avecico {
            span {
                position: absolute;
                overflow: hidden;
                left: 80px;
                top: 0;
                right: 0;
            }
        }

        &.sansico {
            span {
                display: block;
                text-align: center;
            }
        }
    }
}


.croix {
    position: relative;
    display: inline-block;
    vertical-align: super;
    margin-right: 12px;

    .rectangle {
        position: absolute;
        width: 10px;
        height: 2px;
        background: $text;

        &.croix1 {
            transform: rotate(45deg);
        }

        &.croix2 {
            transform: rotate(-45deg);
        }
    }
}

.admin_motcle{
    position: relative;
    text-transform: initial;
    font-family: myfont;
    font-size: 1.8rem;
    padding-right: 5px;
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    color: $text;
    z-index: 2;
    transition:0.3s;
    &:first-of-type{
        margin-left: 5px;
    }
    &:last-of-type{
        padding-right: 0px;
    }
    &:hover{
        text-decoration: none;
        color: #fff;
        opacity: 1;
    }
}


.cck_group_x{
    display: block;
    .ui-sortable{
        .cck_form_group_x {
            position: relative;
            margin-bottom: 50px;            
            z-index: 3;
            aside{
                float: none;
                position: absolute;
                width: 60px;
                left: 50%;
                bottom: 35px;
                margin-left: -30px;
                z-index: 1000;
            }
            .cck_cgx_form{
                float: none;                
                text-align: center;
                border: 1px solid rgba(177, 31, 31, 0.4);
                padding: 30px 20px;
            }
        }
    }
    #cck1r_label_p_galerie{
        height: 60px;
    }
}
.galerie_showcase{
}

div.adminformlist{
    float: none;
}
