
.admin_ico{
    &.icon-delete{
        right: 135px;
    }
    &.icon-edit{
        right: 180px;
    }
    &.icon-facebook{
        right: 225px;
    }
}

.back-to-top,.icosearch,.admin_ico {
    font-size: 2rem;
    color: $rouge1;
    position: fixed;
    bottom: 10px;
    margin-right: -1rem;
    z-index: 1032;
    opacity: 0.7;
    cursor: pointer;
    &:hover,&:focus {
        outline: 0;
        opacity: 1;
        text-decoration: none;
        color: $rouge1;
    }
}

.back-to-top {
    right: 30px;
    opacity: 0;
}


.icosearch {
    right: 45px;
}

.icotext {
    display: inline;
    vertical-align: middle;
    text-align: center;
    line-height: $line-height;

    > img {
        display: inline-block;
        margin-right: 1.5rem;
    }

    > span, h1, h2, h3 {
        display: inline-block;
        vertical-align: middle;

        @include heading-font;
    }

    &.bulle {
        padding: 18px 12px;
        background: #fff;
        border-radius: $brad;

        > span {
            font-size: 2rem;
        }
    }
}