@charset "UTF-8";
/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
@viewport {
  width: device-width;
  zoom: 1.0; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer,
header, hgroup, main, menu, nav, section, summary {
  display: block; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

html {
  background: #fff;
  text-size-adjust: 100%; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

body {
  line-height: 1.5; }

ol, ul {
  list-style: none; }

a {
  background-color: transparent; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img,
video {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

iframe,
object,
embed {
  max-width: 100%; }

img {
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; }

input[type="search"] {
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

* {
  -webkit-overflow-scrolling: touch; }

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto; }

.row, .item_list_wrapper, #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

.row.reverse, .reverse.item_list_wrapper, .reverse#system.intro.blog, .reverse#system.intro.portfolio, .reverse#system.intro.clients {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto; }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%; }

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%; }

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%; }

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%; }

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  margin-left: 0; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.start-xs {
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto; }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    margin-left: 0; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .start-sm {
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto; }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    margin-left: 0; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .start-md {
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto; }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    margin-left: 0; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .start-lg {
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.menu_top_pt {
  padding-top: 104px; }

.blanc {
  color: #fff; }

.noir {
  color: #000; }

.noir_opacity {
  color: rgba(0, 0, 0, 0.5); }

.gris_fonce {
  color: #424242; }

.gris_fonce_opacity {
  color: rgba(66, 66, 66, 0.8); }

.gris_fonce2 {
  color: #323534; }

.gris_fonce2_opacity {
  color: rgba(50, 53, 52, 0.8); }

.bg-blanc {
  background-color: #fff; }

.bg-blanc_opacity {
  background-color: rgba(255, 255, 255, 0.8); }

.bg-noir {
  background-color: #000; }

.bg-noir_opacity {
  background-color: rgba(0, 0, 0, 0.5); }

.bg-gris_fonce {
  background-color: #424242; }

.bg-gris_fonce_opacity {
  background-color: rgba(66, 66, 66, 0.8); }

.bg-gris_fonce2 {
  background-color: #323534; }

.bg-gris_fonce2_opacity {
  background-color: rgba(50, 53, 52, 0.8); }

.bg-grad-bv {
  background-image: -moz-linear-gradient(left, #29aae1 0%, #009758 100%);
  background-image: -webkit-linear-gradient(left, #29aae1 0%, #009758 100%);
  background-image: linear-gradient(to right, #29aae1 0%, #009758 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29aae1', endColorstr='#009758',GradientType=1 ); }

.bg-grad-bv-opacity {
  background-image: -moz-linear-gradient(left, rgba(41, 170, 225, 0.8) 0%, rgba(0, 151, 88, 0.8) 100%);
  background-image: -webkit-linear-gradient(left, rgba(41, 170, 225, 0.8) 0%, rgba(0, 151, 88, 0.8) 100%);
  background-image: linear-gradient(to right, rgba(41, 170, 225, 0.8) 0%, rgba(0, 151, 88, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29aae1', endColorstr='#009758',GradientType=0.8 ); }

.bg-grad-top-bottom-opacity {
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); }

.bg-grad-tricone {
  background-image: -moz-linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(199, 199, 199, 0.3) 0%, rgba(0, 0, 0, 0) 100%); }

.bg-grad-bottom-top-opacity {
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 ); }

.bg-grad-entree {
  background: #24964c;
  background-image: -moz-linear-gradient(top, #24964c 1%, #006e2e 100%);
  background-image: -webkit-linear-gradient(top, #24964c 1%, #006e2e 100%);
  background-image: linear-gradient(to bottom, #24964c 1%, #006e2e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24964c', endColorstr='#006e2e',GradientType=0 ); }

.bg-grad-plat {
  background: #f2c521;
  background-image: -moz-linear-gradient(top, #f2c521 0%, #dda41f 100%);
  background-image: -webkit-linear-gradient(top, #f2c521 0%, #dda41f 100%);
  background-image: linear-gradient(to bottom, #f2c521 0%, #dda41f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2c521', endColorstr='#dda41f',GradientType=0 ); }

.bg-grad-dessert {
  background: #257c96;
  background-image: -moz-linear-gradient(top, #257c96 1%, #1c5373 100%);
  background-image: -webkit-linear-gradient(top, #257c96 1%, #1c5373 100%);
  background-image: linear-gradient(to bottom, #257c96 1%, #1c5373 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#257c96', endColorstr='#1c5373',GradientType=0 ); }

.h100 {
  height: 100%; }

.w100 {
  width: 100%; }

.img_resp {
  max-width: 100%;
  height: auto; }

.img_fullw {
  width: 100%;
  height: auto; }

.posr {
  position: relative; }

.flex_center {
  align-items: center;
  justify-content: center; }

.flex_center_space-between {
  align-items: center;
  justify-content: space-between; }

.flex_center_space-around {
  align-items: center;
  justify-content: space-around; }

.br_1px {
  border: solid 1px; }

.br_blanc {
  border-color: #fff; }

.ovh {
  overflow: hidden; }

.mxw1920 {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 1920px) {
    .mxw1920 {
      max-width: 1280px; } }
  @media (min-width: 2000px) {
    .mxw1920 {
      max-width: 1920px; } }

@font-face {
  font-family: placard;
  src: url(../fonts/placard-condensed-regular-webfont.eot);
  src: url(../fonts/placard-condensed-regular-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/placard-condensed-regular-webfont.woff) format("woff"), url(../fonts/placard-condensed-regular-webfont.ttf) format("truetype"), url(../fonts/placard-condensed-regular-webfont.svg#placard_condensedregular) format("svg");
  font-weight: 400;
  font-style: normal; }

.placard {
  font-family: 'placard', sans-serif;
  font-weight: 400; }

.lato, .recherche_content .cck_forms .cck_form_search_generic input, .pix_album .titre_pix h2, .pix_album .container_tablet span, .pix_album .container_phone span, .pix_album .container_pc span, .container_flyer.une_page_rv .control a, .container_flyer.cartevisite_1_page_rv .control a {
  font-family: 'lato', sans-serif;
  font-weight: 400; }

.italic {
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-style: italic; }

.bold, h1, h2, h3, h4, #cboxTitle, .menu_module, .social-buttons .social-button.gauche span, body #menu_wrapper h2, .icotext > span, .icotext h1, .icotext h2, .icotext h3, .filtre > a span, .search_titre .megatitre > span:last-of-type i, .search_titre .megatitre > span:last-of-type .mot, .banner.intro .intro-titre span, .tableau_prix > div > div, .tableau_prix > div > div .head .prix .euros, #block_haut #text_rotate .text_rotate, #block_haut #anim_top_home .bon_message, .titre_home p, #footer .menu ul li a {
  font-family: 'lato', sans-serif;
  font-weight: 900; }

.caps {
  text-transform: uppercase; }

.tal {
  text-align: left; }

.tac {
  text-align: center; }

.tar {
  text-align: right; }

.txt_bg {
  padding: 6px; }

.message_system {
  position: fixed;
  z-index: 20000;
  bottom: 30px;
  left: 0;
  background: rgba(171, 40, 40, 0.7); }
  .message_system .message_system_wrapper {
    padding: 20px 30px;
    font-size: 1.2rem;
    text-transform: uppercase; }
    .message_system .message_system_wrapper span:before {
      margin-right: 1.2rem; }

p {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 0;
  text-align: center; }
  p a {
    font-weight: 700;
    transition: color 0.3s; }
  @media (min-width: 544px) {
    p {
      font-size: 1.8rem; } }
  @media (min-width: 768px) {
    p {
      text-align: center;
      font-size: 2rem; } }

p + p {
  text-indent: 2rem;
  margin-top: 0; }

h1 {
  text-transform: uppercase;
  font-size: 3.5rem;
  line-height: 3.7rem;
  margin: 0;
  padding: 1.45833rem 0; }

h2 {
  text-transform: uppercase;
  font-size: 2.33333rem;
  line-height: 2.53333rem;
  margin: 0;
  padding: 1.25rem 0; }

h3 {
  font-size: 1.16667rem;
  line-height: 1.36667rem;
  margin: 0;
  padding: 1.51786rem 0; }

h4 {
  font-size: 1.1rem;
  line-height: 1.3rem;
  margin: 0;
  padding: 1.21786rem 0; }

@font-face {
  font-family: "myfont";
  src: url("../fonts/myfont.eot");
  src: url("../fonts/myfont.eot?#iefix") format("embedded-opentype"), url("../fonts/myfont.woff") format("woff"), url("../fonts/myfont.ttf") format("truetype"), url("../fonts/myfont.svg#myfont") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "myfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "myfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-astuces:before {
  content: "\62"; }

.icon-comm:before {
  content: "\63"; }

.icon-info:before {
  content: "\64"; }

.icon-news:before {
  content: "\65"; }

.icon-site-web:before {
  content: "\66"; }

.icon-voir:before {
  content: "\67"; }

.icon-webmarketing:before {
  content: "\68"; }

.icon-facebook:before {
  content: "\6b"; }

.icon-google:before {
  content: "\6c"; }

.icon-linkedin:before {
  content: "\6d"; }

.icon-quote-right:before {
  content: "\61"; }

.icon-quote-left:before {
  content: "\6e"; }

.icon-hashtag:before {
  content: "\6f"; }

.icon-viadeo:before {
  content: "\70"; }

.icon-attache-case:before {
  content: "\71"; }

.icon-arrow-up:before {
  content: "\72"; }

.icon-close:before {
  content: "\73"; }

.icon-arrow-left:before {
  content: "\74"; }

.icon-arrow-right:before {
  content: "\75"; }

.icon-refresh:before {
  content: "\76"; }

.icon-play:before {
  content: "\77"; }

.icon-pause:before {
  content: "\78"; }

.icon-envelope-o:before {
  content: "\6a"; }

.icon-search:before {
  content: "\79"; }

.icon-delete:before {
  content: "\41"; }

.icon-edit:before {
  content: "\42"; }

.icon-calendar:before {
  content: "\7a"; }

.icon-minus:before {
  content: "\43"; }

.icon-plus:before {
  content: "\44"; }

.icon-circle:before {
  content: "\46"; }

.icon-liste:before {
  content: "\48"; }

.icon-coffee:before {
  content: "\47"; }

.icon-portail:before {
  content: "\69"; }

.icon-clients:before {
  content: "\49"; }

.icon-home:before {
  content: "\4a"; }

.icon-logo-clq:before {
  content: "\45"; }

.icon-home-1:before {
  content: "\4b"; }

.icon-google-plus:before {
  content: "\4c"; }

.icon-infos:before {
  content: "\4d"; }

.icon-phone:before {
  content: "\4f"; }

.icon-like:before {
  content: "\50"; }

.icon-copyright:before {
  content: "\4e"; }

.icon-ecommerce:before {
  content: "\51"; }

.icon-application:before {
  content: "\52"; }

.icon-vitrine:before {
  content: "\53"; }

.icon-check:before {
  content: "\54"; }

.icon-img-thumb:before {
  content: "\55"; }

.icon-map:before {
  content: "\56"; }

.icon-burger:before {
  content: "\57"; }

.icon-left:before {
  content: "\58"; }

.icon-right:before {
  content: "\59"; }

.icon-user:before {
  content: "\5a"; }

.icon-likew:before {
  content: "\30"; }

.icon-shop:before {
  content: "\31"; }

.icon-link:before {
  content: "\32"; }

.icon-share:before {
  content: "\33"; }

.icon-wifi:before {
  content: "\34"; }

.icon-graph:before {
  content: "\35"; }

.icon-eur:before {
  content: "\36"; }

.icon-filtre:before {
  content: "\37"; }

.icon-filtre-1:before {
  content: "\38"; }

.icon-circle-1:before {
  content: "\39"; }

.icon-diagram:before {
  content: "\21"; }

.icon-network:before {
  content: "\22"; }

.icon-package:before {
  content: "\23"; }

.icon-targeting:before {
  content: "\24"; }

.icon-down-right:before {
  content: "\25"; }

.icon-bulle-droite:before {
  content: "\26"; }

.icon-bulle-gauche:before {
  content: "\27"; }

.icon-map-pin:before {
  content: "\28"; }

.icon-personne:before {
  content: "\29"; }

.icon-flag:before {
  content: "\2a"; }

.icon-left-arrow:before {
  content: "\2b"; }

.icon-right-arrow:before {
  content: "\2c"; }

/***********************
************************
****** DEBUT COMMON */
a, a:hover, a:focus {
  outline: 0; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */ }

*::selection {
  background: #cc0000;
  color: #fff; }

*::-moz-selection {
  background: #cc0000;
  color: #fff; }

.no-gutters {
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0; }

.no-gutters-span [class^="col-"],
.no-gutters-span [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

html {
  height: 100%;
  font-size: 8px; }
  @media (min-width: 768px) {
    html {
      font-size: 8.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1280px) {
    html {
      font-size: 9.5px; } }
  @media (min-width: 1920px) {
    html {
      font-size: 10px; } }

body {
  height: 100%;
  color: #6E6E6E;
  background-color: #ececec;
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 12px;
  padding-left: 2px;
  padding-right: 2px; }

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background: #ab2828;
  background-clip: content-box;
  min-height: 100px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px; }

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  background: #fff; }

.component {
  padding-bottom: 40px; }
  .component img {
    max-width: 100%;
    height: auto; }

.flex-xs-middle, .search_titre .block_titre, .content .block_titre, .content .block_titre_clients, .flex-items-xs-center, .item_list_wrapper, #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; }

.ma {
  margin-right: auto;
  margin-left: auto; }

.hidden-sm-down {
  display: none; }
  @media (min-width: 544px) {
    .hidden-sm-down {
      display: block; } }

.hidden-md-down {
  display: none; }
  @media (min-width: 768px) {
    .hidden-md-down {
      display: block; } }

.hidden-md-up {
  display: block; }
  @media (min-width: 768px) {
    .hidden-md-up {
      display: none; } }

.hidden-menu-switch-down {
  display: none; }
  @media (min-width: 768px) {
    .hidden-menu-switch-down {
      display: block; } }

.hidden-menu-switch-up {
  display: block; }
  @media (min-width: 768px) {
    .hidden-menu-switch-up {
      display: none; } }

/* .item_list_wrapper {
    @extend .row, .flex-items-xs-center;
    padding-left:  2%;
    padding-right: 2%;
    @media (min-width: 544px) {
        padding-left:  3%;
        padding-right: 3%;
        > div{
            display: flex;
        }
    }
    @media (min-width: 768px) {
        padding-left:  4%;
        padding-right: 4%;
    }
    @media (min-width: 992px) {
        padding-left:  5%;
        padding-right: 5%;
    }
    @media (min-width: 1280px) {
        padding-left:  6%;
        padding-right: 6%;
    }
    @media (min-width: 1920px) {
        padding-left:  7%;
        padding-right: 7%;
    }
} */
.item_list_wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  /* @media (min-width: 768px) {
        width: 92%;
    } */
  /* @media (min-width: 992px) {
        width: 90%;
    } */
  /* @media (min-width: 1280px) {
        width: 88%;
    } */ }
  .item_list_wrapper > div {
    display: flex;
    padding-right: 2rem;
    padding-left: 2rem; }
  @media (min-width: 544px) {
    .item_list_wrapper {
      width: 94%; } }
  @media (min-width: 1920px) {
    .item_list_wrapper {
      width: 90%; } }

#system.intro.blog, #system.intro.portfolio, #system.intro.clients {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (min-width: 544px) {
    #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
      padding-left: 3rem;
      padding-right: 3rem; }
      #system.intro.blog > div, #system.intro.portfolio > div, #system.intro.clients > div {
        display: flex; } }
  @media (min-width: 768px) {
    #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 992px) {
    #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
      padding-left: 5rem;
      padding-right: 5rem; } }
  @media (min-width: 1280px) {
    #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 1920px) {
    #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
      padding-left: 7rem;
      padding-right: 7rem; } }

#autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (min-width: 544px) {
    #autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
      padding-left: 3rem;
      padding-right: 3rem; } }
  @media (min-width: 768px) {
    #autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
      padding-left: 4rem;
      padding-right: 4rem; } }
  @media (min-width: 992px) {
    #autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
      padding-left: 5rem;
      padding-right: 5rem; } }
  @media (min-width: 1280px) {
    #autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 1920px) {
    #autres_realisations_portfolio, #autres_tutos, .pix_album, .item.clients {
      padding-left: 7rem;
      padding-right: 7rem; } }

.block_titre_showcase, .item.portfolio {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (min-width: 544px) {
    .block_titre_showcase, .item.portfolio {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 768px) {
    .block_titre_showcase, .item.portfolio {
      padding-left: 2rem;
      padding-right: 2rem; } }

h1, h2, h3, h4, h5, a {
  text-decoration: none; }

ul {
  list-style: none; }

.actions {
  display: none; }

.btn.jmodedit {
  display: none; }

.cck_button_save_view, .cck_button_cancel {
  position: fixed !important;
  z-index: 10000;
  bottom: -33px;
  right: 120px; }

.cck_button_save_view {
  right: 30px; }

/* 
.shadow{
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -5px;
        left: 15%;
        right: 15%;
        height: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
        background: #000;
        border-radius: 50%;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
    }
} */
/***********************
************************
****** FIN COMMON */
/***********************
************************
****** DEBUT TAGS */
.tags {
  padding-bottom: 50px; }
  .tags.intro.portfolio {
    padding-bottom: 0; }
  .tags .tags-links .tag-link {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 1px;
    display: inline-block;
    padding: 1rem 1rem;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
    background: #fff;
    box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.25);
    transition: .5s; }
    @media (min-width: 992px) {
      .tags .tags-links .tag-link {
        font-size: 1.4rem;
        letter-spacing: 2px;
        padding: 1rem 1.6rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0px 1px 0.5rem rgba(0, 0, 0, 0.25);
        transition: .5s; } }
    .tags .tags-links .tag-link.hover {
      text-decoration: none; }

/***********************
************************
****** FIN TAGS */
.cck_module_breadcrumbs a, .cck_module_breadcrumbs span {
  position: relative;
  padding: 10px 15px;
  line-height: 20px;
  display: inline-block; }

.cck_module_breadcrumbs a {
  color: #777; }

.cck_module_breadcrumbs span.pathway-last {
  color: #be1e2d;
  overflow-x: hidden;
  display: inline; }

.cck_module_breadcrumbs span.separator {
  padding: 10px 0px; }

.arrow-left {
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-right: 100px solid blue;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6); }

.svg-triangle {
  margin: 0 auto; }

.svg-triangle polygon {
  fill: #98d02e; }
  .svg-triangle polygon filer {
    opacity: 0.1; }

.clear {
  display: block;
  clear: both;
  text-align: center; }

.cck_page .megatitre.admin_titre {
  margin: 200px 0 60px;
  display: block; }

.video-container {
  position: relative;
  overflow: hidden; }
  .video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container.iframe {
    padding-top: 56.25%; }

.video-js {
  padding-top: 56.25% !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 0 27px 0px 0;
  overflow: hidden; }

.vjs-fullscreen {
  padding-top: 0px !important; }

.share_twitter {
  background-color: #0087BA; }

.share_facebook {
  background-color: #2D4373; }

.share_gplus {
  background-color: #C23321; }

#cboxPrevious, #cboxTitle, #cboxNext, #cboxClose {
  font-size: 2.5rem;
  color: #3a3a3a; }

#cboxPrevious, #cboxTitle, #cboxNext {
  padding: 20px; }

#cboxPrevious {
  left: 30px; }

#cboxNext {
  right: 30px;
  left: initial; }

#cboxTitle {
  font-family: lato;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.4); }

#cboxCurrent {
  left: 47px; }

/***********************
************************
****** DEBUT dégradé */
.noir {
  color: #333; }

.turquoise {
  color: #1abc9c; }

.vert {
  color: #2ecc71; }

.bleu {
  color: #75C0D7; }

.violet {
  color: #9b59b6; }

.nuit {
  color: #34495e; }

.jaune {
  color: #F1B20F; }

.orange {
  color: #e67e22; }

.rouge {
  color: #e74c3c; }

.gris {
  color: #95a5a6; }

.ctext {
  color: #6E6E6E !important; }

.blanc {
  color: #fff !important; }

.bnoir {
  background-color: #333; }

.bturquoise {
  background-color: #1abc9c; }

.bvert {
  background-color: #2ecc71; }

.bbleu {
  background-color: #75C0D7; }

.bviolet {
  background-color: #9b59b6; }

.bnuit {
  background-color: #34495e; }

.bjaune {
  background-color: #F1B20F; }

.borange {
  background-color: #e67e22; }

.brouge {
  background-color: #e74c3c; }

.bgris {
  background-color: #95a5a6; }

.bctext {
  background-color: #6E6E6E !important; }

.bblanc {
  background-color: #fff !important; }

.btool {
  background-color: #ab2828; }

.bbleuciel {
  background-color: #339ebf; }

.gnoir {
  background-image: linear-gradient(#333, #222); }

.gturquoise {
  background-image: linear-gradient(#1abc9c, #16a085); }

.gvert {
  background-image: linear-gradient(#2ecc71, #27ae60); }

.gbleu {
  background-image: linear-gradient(#75C0D7, #3498DB); }

.gviolet {
  background-image: linear-gradient(#9b59b6, #8e44ad); }

.gnuit {
  background-image: linear-gradient(#34495e, #2c3e50); }

.gjaune {
  background-image: linear-gradient(#F1B20F, #F39B12); }

.gorange {
  background-image: linear-gradient(#e67e22, #d35400); }

.grouge {
  background-image: linear-gradient(#e74c3c, #c0392b); }

.ggris {
  background-image: linear-gradient(#95a5a6, #7f8c8d); }

.gtool {
  background-image: linear-gradient(#821e1e, #ab2828); }

.rturquoise {
  background-image: radial-gradient(closest-side at 45px 45px, #1abc9c 10px, #16a085 500px); }

.rvert {
  background-image: radial-gradient(closest-side at 45px 45px, #2ecc71 10px, #27ae60 500px); }

.rbleu {
  background-image: radial-gradient(closest-side at 45px 45px, #75C0D7 10px, #3498DB 500px); }

.rviolet {
  background-image: radial-gradient(closest-side at 45px 45px, #9b59b6 10px, #8e44ad 500px); }

.rnuit {
  background-image: radial-gradient(closest-side at 45px 45px, #34495e 10px, #2c3e50 500px); }

.rjaune {
  background-image: radial-gradient(closest-side at 45px 45px, #F1B20F 10px, #F39B12 500px); }

.rorange {
  background-image: radial-gradient(closest-side at 45px 45px, #e67e22 10px, #d35400 500px); }

.rrouge {
  background-image: radial-gradient(closest-side at 45px 45px, #e74c3c 10px, #c0392b 500px); }

.rgris {
  background-image: radial-gradient(closest-side at 45px 45px, #95a5a6 10px, #7f8c8d 500px); }

.rtool {
  background-image: radial-gradient(closest-side at 45px 45px, #d03636 10px, #ab2828 500px); }

.rtool {
  background-image: radial-gradient(closest-side at 45px 45px, #d03636 10px, #ab2828 500px); }

.rtrick {
  background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dffffff', endColorstr='#00ffffff',GradientType=1 ); }

.gtrick {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#4dffffff',GradientType=0 ); }

.diagtrick {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=1 ); }

/***********************
************************
****** FIN dégradé */
.item_list_wrapper {
  display: flex;
  flex-flow: wrap; }

.row, .item_list_wrapper, #system.intro.blog, #system.intro.portfolio, #system.intro.clients {
  width: 100%; }

#menu_top .menu1 {
  background-color: rgba(255, 255, 255, 0.96);
  position: relative;
  z-index: 101;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 544px) {
    #menu_top .menu1 {
      padding-left: 15px;
      padding-right: 15px; } }

.menu2, .menu3 {
  height: 50px;
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
  line-height: 50px;
  position: relative;
  z-index: 100;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.15);
  transition: 0.6s;
  transition-delay: 1s; }
  .menu2.fildariane, .fildariane.menu3 {
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color .3s;
    padding-bottom: 5px; }
    .menu2.fildariane:hover, .fildariane.menu3:hover {
      background-color: white; }

.navbar-header {
  display: flex;
  align-items: center;
  height: 104px;
  padding-top: 4px;
  transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47);
  justify-content: space-between; }
  @media (min-width: 768px) {
    .navbar-header {
      justify-content: flex-start; } }
  .navbar-header .navbar-right {
    margin-left: auto; }

.affix .navbar-header {
  transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47);
  height: 64px; }

#menuleft {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47); }

.affix #menuleft {
  height: 30px;
  width: 30px;
  transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47); }

.navbar-right {
  border-color: transparent;
  overflow: visible;
  border-top: 0;
  box-shadow: none; }
  .navbar-right .nav {
    float: left;
    margin: 0;
    padding-left: 0;
    list-style: none; }
    .navbar-right .nav li {
      float: left;
      display: block;
      position: relative; }
      .navbar-right .nav li a {
        display: block;
        color: #6E6E6E;
        padding: 40px 15px;
        text-decoration: none;
        transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
        .navbar-right .nav li a:hover, .navbar-right .nav li a.active {
          color: #ab2828;
          background-color: transparent; }
        .navbar-right .nav li a span {
          position: relative; }
      .navbar-right .nav li.active a {
        color: #ab2828; }
      .navbar-right .nav li:not(:last-of-type) > a > span:after {
        content: "/";
        color: #999999;
        font-size: 13px;
        line-height: 16px;
        position: absolute;
        top: -1px;
        right: -15px; }

/* .navbar-brand .clq > i{
        display: block;
        position: absolute;
        left: 17px;
        top:50%;
        //bottom: 10px;
        color: $tool;
        font-size: 40px;
        height: 100px;
        line-height: 100px;
        margin-top: -45px;
        opacity: 0;
        transform:rotateY(-90deg);
        transition:0.3s linear;
    }
 */
/* .affix .navbar-brand .clq > i {
    height: 60px;
    line-height: 60px;
    margin-top: -25px;
    opacity: 0.7;
    transform: rotateY(0deg);
    transition: 0.3s 0.3s linear;

    &:hover {
        opacity: 1;
        transition: 0.3s;
    }
} */
.clq {
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  margin-top: 0; }
  .clq svg {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: 100%; }

.affix .clq {
  top: 0;
  left: 0; }
  .affix .clq svg {
    top: 0;
    left: 0; }

.clq {
  text-transform: initial; }

#cliquez g text {
  animation: lettre_com_opacity 1s ease-in forwards; }

#cliquez g:nth-of-type(1) text {
  animation-delay: 0.4s; }

#cliquez g:nth-of-type(2) text {
  animation-delay: 0.5s; }

#cliquez g:nth-of-type(3) text {
  animation-delay: 0.6s; }

#cliquez g:nth-of-type(4) text {
  animation-delay: 0.7s; }

#cliquez g:nth-of-type(5) text {
  animation-delay: 0.8s; }

#cliquez g:nth-of-type(6) text {
  animation-delay: 0.9s; }

#cliquez g:nth-of-type(7) text {
  animation-delay: 1s; }

#cliquez g:nth-of-type(8) text {
  animation-delay: 1.1s; }

#cliquez g:nth-of-type(9) text {
  animation-delay: 1.2s; }

#cliquez g:nth-of-type(10) text {
  animation-delay: 1.3s; }

#cliquez g {
  cursor: pointer;
  transform-origin: 50% 50%;
  animation: lettre_un 1s ease-in forwards; }

#cliquez g:nth-of-type(1) {
  animation-delay: 0.4s; }

#cliquez g:nth-of-type(2) {
  animation-delay: 0.5s; }

#cliquez g:nth-of-type(3) {
  animation-delay: 0.6s; }

#cliquez g:nth-of-type(4) {
  animation-delay: 0.7s; }

#cliquez g:nth-of-type(5) {
  animation-delay: 0.8s; }

#cliquez g:nth-of-type(6) {
  animation-delay: 0.9s; }

#cliquez g:nth-of-type(7) {
  animation-delay: 1s; }

#cliquez g:nth-of-type(8) {
  animation-delay: 1.1s; }

#cliquez g:nth-of-type(9) {
  animation-delay: 1.2s; }

#cliquez g:nth-of-type(10) {
  animation-delay: 1.3s; }

.fillboder {
  stroke-dasharray: 400;
  stroke-dashoffset: 0; }

.st0:hover {
  fill: #eee;
  transition: fill 1s; }

/***********************
************************
****** DEBUT MENU_MODULE */
.menu_module {
  text-align: center;
  color: #FFF;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
  display: block;
  padding: 50px 10px;
  margin-top: 20px;
  border-top: 7px dashed #fff; }
  .menu_module.more_blog {
    margin-top: 40px;
    border-top-color: #6E6E6E; }

.parent_module {
  text-align: center; }
  .parent_module .intro-portfolio, .parent_module .blog_block {
    float: none;
    display: inline-block;
    vertical-align: top; }

/***********************
************************
****** FIN MENU_MODULE */
.navbar-header .navbar-brand {
  transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47);
  position: relative;
  padding: 0;
  width: auto;
  height: 100%; }
  .navbar-header .navbar-brand img {
    display: block;
    padding: 10px;
    height: 100px;
    transition: 0.4s cubic-bezier(0.64, 0.55, 0.4, 1.47); }

.navbar {
  text-transform: uppercase;
  font-size: 12px; }

#menu_top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030; }
  #menu_top:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px; }
  #menu_top > div {
    position: relative; }
    #menu_top > div:before {
      z-index: 1000;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi├ö├ç┬¬gd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
      background-size: 100%;
      background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #add072), color-stop(7.69231%, #add072), color-stop(15.38462%, #e5ea46), color-stop(23.07692%, #e5ea46), color-stop(30.76923%, #e3c227), color-stop(38.46154%, #e3c227), color-stop(46.15385%, #e1a93c), color-stop(53.84615%, #e1a93c), color-stop(61.53846%, #e28d4a), color-stop(69.23077%, #e28d4a), color-stop(76.92308%, #da633b), color-stop(84.61538%, #da633b), color-stop(92.30769%, #d2442e), color-stop(100%, #d2442e));
      background-image: -moz-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
      background-image: -webkit-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
      background-image: linear-gradient(to right, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e); }

#menu_bottom {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 1030;
  height: 50px; }
  #menu_bottom > div {
    position: relative; }
  #menu_bottom .scroll-line {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 4px;
    margin-bottom: 0px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgi├ö├ç┬¬gd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
    background-size: 100%;
    background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #add072), color-stop(7.69231%, #add072), color-stop(15.38462%, #e5ea46), color-stop(23.07692%, #e5ea46), color-stop(30.76923%, #e3c227), color-stop(38.46154%, #e3c227), color-stop(46.15385%, #e1a93c), color-stop(53.84615%, #e1a93c), color-stop(61.53846%, #e28d4a), color-stop(69.23077%, #e28d4a), color-stop(76.92308%, #da633b), color-stop(84.61538%, #da633b), color-stop(92.30769%, #d2442e), color-stop(100%, #d2442e));
    background-image: -moz-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
    background-image: -webkit-linear-gradient(left, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
    background-image: linear-gradient(to right, #add072, #add072, #e5ea46, #e5ea46, #e3c227, #e3c227, #e1a93c, #e1a93c, #e28d4a, #e28d4a, #da633b, #da633b, #d2442e, #d2442e);
    width: 0%; }

#titre {
  text-align: right;
  padding-right: 110px;
  display: inline-block;
  height: 1.6rem; }
  #titre span {
    position: relative;
    padding: 10px 15px;
    line-height: 20px;
    display: inline-block;
    font-weight: 700;
    font-size: 1.6rem; }
    #titre span.separator {
      padding: 10px 0px; }
  #titre .titrejq h2 {
    margin: 0;
    padding: 10px 0px;
    font-size: 1.3rem;
    display: inline;
    vertical-align: sub;
    overflow: hidden;
    font-family: lato;
    font-weight: 700; }

.menu3 {
  box-shadow: none;
  height: inherit;
  line-height: inherit;
  background-color: transparent;
  display: none; }
  @media (min-width: 768px) {
    .menu3 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; } }
  .menu3 .inner {
    position: relative;
    display: inline-block; }
    .menu3 .inner #type_filtre {
      background-color: rgba(255, 255, 255, 0.3);
      font-family: lato;
      text-transform: uppercase;
      border: none;
      padding: 2px 5px;
      border-radius: 10px; }
    .menu3 .inner:after {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent; }
  .menu3 > div {
    width: 100%; }
    .menu3 > div .inner {
      width: 100%; }
    @media (min-width: 768px) {
      .menu3 > div {
        width: auto; }
        .menu3 > div .inner {
          width: auto; } }
    .menu3 > div.filtre_gauche .inner, .menu3 > div.filtre_droite .inner {
      background-color: rgba(255, 255, 255, 0.9); }
    .menu3 > div.filtre_gauche {
      padding-right: 0;
      height: 50px; }
      .menu3 > div.filtre_gauche .inner {
        height: 50px; }
        .menu3 > div.filtre_gauche .inner:after {
          right: -50px;
          border-width: 50px 50px 0 0; }
      .menu3 > div.filtre_gauche > div:hover a {
        margin-right: 15px; }
    .menu3 > div.filtre_droite {
      text-align: right;
      padding-left: 0;
      height: 50px; }
      .menu3 > div.filtre_droite .inner {
        height: 50px; }
        .menu3 > div.filtre_droite .inner:after {
          left: -50px;
          border-width: 50px 0 0 50px; }
      .menu3 > div.filtre_droite > div:hover a {
        margin-left: 15px; }
    .menu3 > div .inner:hover {
      background-color: rgba(255, 255, 255, 0); }
      .menu3 > div .inner:hover:after {
        border-color: rgba(255, 255, 255, 0) transparent transparent transparent; }
      .menu3 > div .inner:hover a {
        background-color: #fff;
        border-radius: 0px 0px 4px 4px; }

.social-buttons {
  height: 50px;
  margin: auto;
  font-size: 30px;
  text-align: center; }
  .social-buttons .social-button {
    display: inline-block;
    width: 70px;
    height: 50px;
    border-radius: 0px;
    margin: 0 0px;
    text-align: center;
    position: relative;
    overflow: hidden;
    opacity: .99;
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59); }
    .social-buttons .social-button:before {
      content: '';
      width: 140%;
      height: 170%;
      position: absolute;
      top: 90%;
      left: -110%;
      transform: rotate(45deg);
      transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59); }
    .social-buttons .social-button .myfont {
      vertical-align: middle;
      transform: scale(0.8);
      transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
      display: block;
      height: 50px;
      padding: 0;
      top: 0; }
      .social-buttons .social-button .myfont:before {
        font-size: 40px;
        line-height: 50px;
        height: 50px; }
    .social-buttons .social-button.gauche:before {
      background-color: #ab2828; }
    .social-buttons .social-button.gauche .myfont {
      position: relative;
      color: #ab2828;
      opacity: 0.7; }
      .social-buttons .social-button.gauche .myfont:before {
        font-size: 35px; }
    .social-buttons .social-button.gauche span {
      position: relative;
      display: block;
      padding: 10px 0px;
      font-size: 1.1rem;
      color: #fff;
      top: 65px;
      transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59); }
    .social-buttons .social-button.gauche:hover .myfont {
      opacity: 1;
      top: -43px; }
    .social-buttons .social-button.gauche:hover span {
      top: -43px; }
    .social-buttons .social-button.gauche.active .myfont {
      opacity: 1; }
    .social-buttons .social-button.droit.facebook:before {
      background-color: #3B5998; }
    .social-buttons .social-button.droit.facebook .myfont {
      color: #3B5998; }
    .social-buttons .social-button.droit.linkedin:before {
      background-color: #3CF; }
    .social-buttons .social-button.droit.linkedin .myfont {
      color: #3CF; }
    .social-buttons .social-button.droit.google:before {
      background-color: #DC4A38; }
    .social-buttons .social-button.droit.google .myfont {
      color: #DC4A38; }
    .social-buttons .social-button.droit.enveloppe:before {
      background-color: #F26798; }
    .social-buttons .social-button.droit.enveloppe .myfont {
      color: #F26798; }
    .social-buttons .social-button.droit.viadeo:before {
      background-color: #ED7256; }
    .social-buttons .social-button.droit.viadeo .myfont {
      color: #ED7256; }
    .social-buttons .social-button:focus, .social-buttons .social-button:hover {
      text-decoration: none;
      opacity: 1; }
      .social-buttons .social-button:focus:before, .social-buttons .social-button:hover:before {
        top: -35%;
        left: -20%; }
      .social-buttons .social-button:focus .myfont, .social-buttons .social-button:hover .myfont {
        color: #fff !important;
        transform: scale(1); }
        .social-buttons .social-button:focus .myfont:before, .social-buttons .social-button:hover .myfont:before {
          position: relative; }
    .social-buttons .social-button.active {
      opacity: 1; }
      .social-buttons .social-button.active:before {
        top: -35%;
        left: -20%; }
      .social-buttons .social-button.active .myfont {
        color: #fff !important; }
        .social-buttons .social-button.active .myfont:before {
          position: relative; }

#menuleft {
  position: relative;
  text-decoration: none; }
  @media (min-width: 768px) {
    #menuleft {
      margin-right: 21px; } }
  #menuleft div {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #ab2828;
    opacity: 0.7;
    transition: 0.2s ease-in-out; }
    #menuleft div:nth-of-type(1) {
      transform: translate(0px, 0px); }
    #menuleft div:nth-of-type(2) {
      transform: translate(12px, 0px); }
    #menuleft div:nth-of-type(3) {
      transform: translate(24px, 0px); }
    #menuleft div:nth-of-type(4) {
      transform: translate(0px, 12px); }
    #menuleft div:nth-of-type(5) {
      transform: translate(12px, 12px); }
    #menuleft div:nth-of-type(6) {
      transform: translate(24px, 12px); }
    #menuleft div:nth-of-type(7) {
      transform: translate(0px, 24px); }
    #menuleft div:nth-of-type(8) {
      transform: translate(12px, 24px); }
    #menuleft div:nth-of-type(9) {
      transform: translate(24px, 24px); }
  #menuleft:hover .pix {
    opacity: 1; }
    #menuleft:hover .pix:nth-of-type(1) {
      transform: translate(-6px, -6px); }
    #menuleft:hover .pix:nth-of-type(3) {
      transform: translate(30px, -6px); }
    #menuleft:hover .pix:nth-of-type(7) {
      transform: translate(-6px, 30px); }
    #menuleft:hover .pix:nth-of-type(9) {
      transform: translate(30px, 30px); }
  #menuleft.show_menu .pix:nth-of-type(1) {
    transform: translate(0, 0); }
  #menuleft.show_menu .pix:nth-of-type(2) {
    transform: translate(6px, 6px); }
  #menuleft.show_menu .pix:nth-of-type(3) {
    transform: translate(24px, 0); }
  #menuleft.show_menu .pix:nth-of-type(4) {
    transform: translate(6px, 18px); }
  #menuleft.show_menu .pix:nth-of-type(6) {
    transform: translate(18px, 6px); }
  #menuleft.show_menu .pix:nth-of-type(7) {
    transform: translate(0, 24px); }
  #menuleft.show_menu .pix:nth-of-type(8) {
    transform: translate(18px, 18px); }
  #menuleft.show_menu .pix:nth-of-type(9) {
    transform: translate(24px, 24px); }
  #menuleft.show_menu:hover .pix:nth-of-type(1) {
    transform: translate(-6px, -6px); }
  #menuleft.show_menu:hover .pix:nth-of-type(2) {
    transform: translate(3px, 3px); }
  #menuleft.show_menu:hover .pix:nth-of-type(3) {
    transform: translate(30px, -6px); }
  #menuleft.show_menu:hover .pix:nth-of-type(4) {
    transform: translate(3px, 21px); }
  #menuleft.show_menu:hover .pix:nth-of-type(6) {
    transform: translate(21px, 3px); }
  #menuleft.show_menu:hover .pix:nth-of-type(7) {
    transform: translate(-6px, 30px); }
  #menuleft.show_menu:hover .pix:nth-of-type(8) {
    transform: translate(21px, 21px); }
  #menuleft.show_menu:hover .pix:nth-of-type(9) {
    transform: translate(30px, 30px); }

body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.5s ease-out; }
  body #menu_wrapper {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: -300px;
    z-index: 100;
    opacity: 1;
    perspective: 1000px;
    transform-style: preserve-3d; }
    body #menu_wrapper h2 {
      cursor: pointer;
      margin: 0;
      padding: 3.4rem 0.5rem 3.4rem 2rem;
      font-size: 2em;
      background: #ab2828;
      font-family: lato; }
      body #menu_wrapper h2 .icon-close {
        display: block;
        font-size: 4rem;
        color: #fff;
        text-align: right;
        position: absolute;
        right: 1rem;
        top: 2.4rem;
        line-height: 1;
        font-weight: 400; }
      body #menu_wrapper h2 span, body #menu_wrapper h2 i {
        display: inline-block;
        vertical-align: middle; }
      body #menu_wrapper h2 i {
        color: rgba(0, 0, 0, 0.3);
        margin-right: 1.5rem; }
      body #menu_wrapper h2 span {
        color: rgba(255, 255, 255, 0.8); }
    body #menu_wrapper .nav.menu {
      padding-left: 0;
      height: 100%;
      transform-origin: 100% 50%;
      transform: rotateY(-90deg);
      background-color: #ab2828;
      transition: all 0.6s; }
      body #menu_wrapper .nav.menu li {
        list-style: none; }
        body #menu_wrapper .nav.menu li.current.active > a {
          opacity: 1;
          background: #fff;
          color: #ab2828; }
      body #menu_wrapper .nav.menu > li a {
        position: relative;
        font-size: 2rem;
        line-height: 1;
        padding: 1.7rem 0.5rem 1.7rem 2rem;
        color: #fff;
        display: block;
        background-color: #ab2828;
        opacity: 0.9;
        transition: .3s; }
        body #menu_wrapper .nav.menu > li a:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: rgba(255, 255, 255, 0.1); }
        body #menu_wrapper .nav.menu > li a span:before {
          font-size: 2.5rem;
          display: inline-block;
          vertical-align: text-top;
          margin-right: 1.5rem;
          font-family: "myfont" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        body #menu_wrapper .nav.menu > li a:hover, body #menu_wrapper .nav.menu > li a:focus, body #menu_wrapper .nav.menu > li a.active {
          opacity: 1;
          background: #fff;
          color: #ab2828; }
      body #menu_wrapper .nav.menu > li.item-101 > a > span:before {
        content: "\45"; }
      body #menu_wrapper .nav.menu > li.item-105 > a > span:before {
        content: "\47"; }
      body #menu_wrapper .nav.menu > li.item-107 > a > span:before {
        content: "\71"; }
      body #menu_wrapper .nav.menu > li .item-118 > a > span:before {
        content: "\71"; }
      body #menu_wrapper .nav.menu > li .item-108 > a > span:before {
        content: "\63"; }
      body #menu_wrapper .nav.menu > li .item-109 > a > span:before {
        content: "\66"; }
      body #menu_wrapper .nav.menu > li .item-117 > a > span:before {
        content: "\68"; }
      body #menu_wrapper .nav.menu > li.item-106 > a > span:before {
        content: "\49"; }
      body #menu_wrapper .nav.menu > li.item-116 > a > span:before {
        content: "\69"; }
      body #menu_wrapper .nav.menu > li.item-122 > a > span:before {
        content: "\79"; }
      body #menu_wrapper .nav.menu > li.item-132 > a > span:before {
        content: "\6a"; }
      body #menu_wrapper .nav.menu > li.item-123 > a > span:before {
        content: "\42"; }
      body #menu_wrapper .nav.menu > li.dropdown .dropdown-menu > li > a {
        padding: 1.4rem 0.5rem 1.4rem 1rem;
        font-size: 1.6rem; }
  body.show_menu {
    transform: translateX(300px);
    overflow-y: hidden;
    transition: all 0.5s; }
    body.show_menu #menu_wrapper .nav.menu {
      transform: rotateY(0deg);
      transition: all 0.6s ease-in-out; }

/***********************
************************
****** DEBUT ANIMATIONS */
@keyframes lettre_com {
  from {
    transform: translateX(-40px) rotate(-90deg); }
  to {
    transform: translateX(0px) rotate(0deg); } }

@keyframes lettre_un {
  from {
    transform: rotateX(-360deg); }
  to {
    transform: rotateX(0deg); } }

@keyframes lettre_com_opacity {
  from {
    fill-opacity: 0; }
  50% {
    fill-opacity: 0; }
  to {
    fill-opacity: 1; } }

@keyframes dash {
  from {
    stroke-dashoffset: 400; }
  to {
    stroke-dashoffset: 0; } }

@keyframes gradopacity {
  from {
    fill-opacity: 0; }
  70% {
    fill-opacity: 0; }
  to {
    fill-opacity: 1; } }

@keyframes avion {
  from {
    transform: rotateZ(0deg) rotateY(0deg) translateY(0px); }
  50% {
    transform: rotateZ(1deg) rotateY(1deg) translateY(1px);
    text-shadow: 1px 9px 13px rgba(0, 0, 0, 0.3); }
  to {
    transform: rotateZ(0deg) rotateY(0deg) translateY(0px); } }

@keyframes aviongo {
  from {
    transform: rotateZ(0deg) rotateY(0deg) translateY(0%) translateX(0%);
    opacity: 1;
    text-shadow: 1px 8px 14px rgba(0, 0, 0, 0.3); }
  7% {
    transform: rotateZ(35deg) rotateY(0deg) translateY(0px);
    opacity: 1; }
  40% {
    transform: rotateZ(35deg) rotateY(0deg) translateY(0px) translateX(2000px) translateY(-1600px);
    opacity: 1; }
  55% {
    opacity: 0; }
  80% {
    transform: rotateZ(0deg) rotateY(0deg) translateY(0%) translateX(0%);
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes rotateshow {
  from {
    transform: rotateY(-10deg) rotateX(15deg); }
  40% {
    transform: rotateY(10deg) rotateX(15deg); }
  80% {
    transform: rotateY(0deg) rotateX(0deg); }
  to {
    transform: rotateY(0deg) rotateX(0deg); } }

@keyframes piedpc {
  from {
    opacity: 0;
    bottom: 0; }
  98% {
    opacity: 0; }
  to {
    opacity: 1;
    bottom: -103px; } }

@keyframes avion_top {
  from {
    right: -50%; }
  to {
    right: 100%; } }

@keyframes bon_message {
  from {
    right: -50%;
    opacity: 0; }
  to {
    right: 15%;
    opacity: 1; } }

@keyframes zoomchill {
  from {
    transform: scale(0.95); }
  to {
    transform: scale(1); } }

@keyframes ombre_bounce {
  0%, 20%, 53%, 80%, 100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scale(1);
    opacity: 1; }
  40%, 43% {
    transform: scale(0.8);
    opacity: 0.5; }
  40%, 43%, 70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  70% {
    transform: scale(0.8);
    opacity: 0.7; }
  90% {
    transform: scale(1);
    opacity: 1; } }

@keyframes affiche {
  from {
    transform: perspective(300px) rotateX(0deg); }
  30% {
    transform: perspective(300px) rotateX(-4deg); }
  50% {
    transform: perspective(300px) rotateX(3deg); }
  70% {
    transform: perspective(300px) rotateX(-2deg); }
  90% {
    transform: perspective(300px) rotateX(1deg); }
  to {
    transform: perspective(300px) rotateX(0deg); } }

/* .intro-portfolio.item.portfolio, */
.intro-clients.item, .blog_block.intro-blog, .blog_block.autre_blog {
  animation: fadeInUp 1s reverse forwards; }
  .intro-clients.item.inview:nth-of-type(3n+3), .blog_block.intro-blog.inview:nth-of-type(3n+3), .blog_block.autre_blog.inview:nth-of-type(3n+3) {
    animation: fadeInRight 1s forwards; }
  .intro-clients.item.inview:nth-of-type(3n+2), .blog_block.intro-blog.inview:nth-of-type(3n+2), .blog_block.autre_blog.inview:nth-of-type(3n+2) {
    animation: fadeInDown 1s forwards; }
  .intro-clients.item.inview:nth-of-type(3n+1), .blog_block.intro-blog.inview:nth-of-type(3n+1), .blog_block.autre_blog.inview:nth-of-type(3n+1) {
    animation: fadeInLeft 1s forwards; }

.pix_album .galerie_photo {
  animation: fadeInUp 1s reverse forwards; }
  .pix_album .galerie_photo.inview:nth-of-type(3n+3) {
    animation: fadeInRight 1s forwards; }
  .pix_album .galerie_photo.inview:nth-of-type(3n+2) {
    animation: fadeInDown 1s forwards; }
  .pix_album .galerie_photo.inview:nth-of-type(3n+1) {
    animation: fadeInLeft 1s forwards; }

/* .intro-portfolio.intro {
animation: fadeInUp 1s reverse forwards;
    &.inview{
        &:nth-of-type(3n+3){
            animation: fadeInRight 1s forwards;
        }
        &:nth-of-type(3n+2){
            animation: fadeInDown 1s forwards;
        }
        &:nth-of-type(3n+1){
            animation: fadeInLeft 1s forwards;
        }
    }
    
}*/
/* .pix_album > div.container_pc,.pix_album > div.container_phone,.pix_album > div.container_tablet{
     animation: fadeInUp 1s reverse forwards; 
    &.inview{
        &.container_tablet{
            animation: fadeInRight 1s forwards;
        }
        &.container_pc{
            animation: fadeInDown 1s forwards;
        }
        &.container_phone{
            animation: fadeInLeft 1s forwards;
        }
    }
} */
.cover_client, .video-container {
  animation: bounceInLeft 1s reverse forwards; }
  .cover_client.inview, .video-container.inview {
    animation: bounceInRight 1s forwards; }

/***********************
************************
****** FIN ANIMATIONS */
@keyframes bgmove {
  from {
    background-position: 50% 110%; }
  to {
    background-position: 50% 0%; } }

@keyframes bgmoveecran {
  from {
    background-position: 50% 100%; }
  to {
    background-position: 50% 0%; } }

@keyframes bgmoveafter {
  from {
    background-color: black; }
  15% {
    background-color: rgba(0, 0, 0, 0.3); }
  85% {
    background-color: rgba(0, 0, 0, 0.3); }
  to {
    background-color: black; } }

@keyframes bgtop {
  from {
    background-position: 50% 10%; }
  to {
    background-position: 50% 0%; } }

@keyframes bgimgportolio {
  from {
    background-image: url("../../../images/bg/bg22.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/bg22.jpg"); }
  25% {
    background-image: url("../../../images/bg/bg59.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/bg59.jpg"); }
  50% {
    background-image: url("../../../images/bg/bg55.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/bg55.jpg"); }
  75% {
    background-image: url("../../../images/bg/bg69.jpg"); }
  to {
    background-image: url("../../../images/bg/bg69.jpg"); } }

@keyframes bgimgblog {
  from {
    background-image: url("../../../images/bg/bg26.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/bg26.jpg"); }
  25% {
    background-image: url("../../../images/bg/bg35.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/bg35.jpg"); }
  50% {
    background-image: url("../../../images/bg/bg60.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/bg60.jpg"); }
  75% {
    background-image: url("../../../images/bg/bg63.jpg"); }
  to {
    background-image: url("../../../images/bg/bg63.jpg"); } }

@keyframes affichemove {
  from {
    transform: rotateY(0deg); }
  20% {
    transform: rotateY(-4deg); }
  40% {
    transform: rotateY(3deg); }
  60% {
    transform: rotateY(-2deg); }
  80% {
    transform: rotateY(1deg); }
  to {
    transform: rotateY(0deg); } }

@keyframes affichemoveshadow {
  from {
    opacity: 0.3; }
  to {
    opacity: 0.1; } }

@keyframes anim_carte {
  from {
    transform: scale(1) rotateY(0deg); }
  50% {
    transform: scale(0.95) rotateY(180deg); }
  to {
    transform: scale(1) rotateY(360deg); } }

@keyframes anim_carte_shadow_light {
  from {
    opacity: 0.1; }
  50% {
    opacity: 0.3; }
  to {
    opacity: 0.1; } }

@keyframes anim_carte_shadow_dark {
  from {
    opacity: 0.1; }
  50% {
    opacity: 0.3; }
  to {
    opacity: 0.1; } }

@keyframes anim_carte_back {
  from {
    transform: scale(1) rotateY(90deg); }
  to {
    transform: scale(1) rotateY(0deg); } }

@keyframes gogo {
  0% {
    opacity: 0;
    transform: translate3d(-50%, -100%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0%, 0); } }

@keyframes bulle {
  from {
    top: -35px;
    width: 60px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }
  70% {
    top: -100px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); }
  to {
    top: -100px;
    width: 160px;
    border-radius: 40px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); } }

@keyframes bulleback {
  from {
    top: -100px;
    width: 160px;
    border-radius: 40px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); }
  to {
    top: -35px;
    width: 60px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); } }

@keyframes bulledeux {
  from {
    width: 60px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }
  to {
    width: 160px;
    border-radius: 40px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); } }

@keyframes bullebackdeux {
  from {
    width: 160px;
    border-radius: 40px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); }
  to {
    width: 60px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); } }

.admin_ico.icon-delete {
  right: 135px; }

.admin_ico.icon-edit {
  right: 180px; }

.admin_ico.icon-facebook {
  right: 225px; }

.back-to-top, .icosearch, .admin_ico {
  font-size: 2rem;
  color: #c0392b;
  position: fixed;
  bottom: 10px;
  margin-right: -1rem;
  z-index: 1032;
  opacity: 0.7;
  cursor: pointer; }
  .back-to-top:hover, .back-to-top:focus, .icosearch:hover, .icosearch:focus, .admin_ico:hover, .admin_ico:focus {
    outline: 0;
    opacity: 1;
    text-decoration: none;
    color: #c0392b; }

.back-to-top {
  right: 30px;
  opacity: 0; }

.icosearch {
  right: 45px; }

.icotext {
  display: inline;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5em; }
  .icotext > img {
    display: inline-block;
    margin-right: 1.5rem; }
  .icotext > span, .icotext h1, .icotext h2, .icotext h3 {
    display: inline-block;
    vertical-align: middle; }
  .icotext.bulle {
    padding: 18px 12px;
    background: #fff;
    border-radius: 6px; }
    .icotext.bulle > span {
      font-size: 2rem; }

.container {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 0 auto 100px;
  padding: 0; }
  @media (min-width: 768px) {
    .container {
      padding: 15px; } }
  .container .card {
    position: relative;
    background: #ffffff;
    border-radius: 5px;
    padding: 60px 0 40px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: .3s ease; }
    .container .card:first-child {
      background: #fafafa;
      height: 10px;
      border-radius: 5px 5px 0 0;
      margin: 0 10px;
      padding: 0; }

body.modal-open {
  overflow-y: hidden; }

.mamodal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 100%;
  z-index: 1040;
  background-color: #000;
  transition: 0.5s; }
  .mamodal-backdrop.gogo {
    display: block;
    opacity: 0.3; }

.mamodal {
  opacity: 0;
  position: fixed;
  max-width: 500px;
  transform: translate3d(-50%, -100%, 0);
  top: -200%;
  left: 50%;
  outline: 0;
  z-index: 1050;
  transition: .6s ease-out; }
  .mamodal.gogo {
    opacity: 1;
    overflow: hidden;
    top: 0;
    bottom: 0;
    /* animation: gogo 1s forwards; */
    transform: translate3d(-50%, 0%, 0);
    transition: 1s 0.7s cubic-bezier(0.3, 0.3, 0, 1); }
  .mamodal .modal-dialog .modal-content .modal-title {
    color: #fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    font-size: 3rem;
    text-align: center; }
  .mamodal.motscles .modal-dialog .modal-content .modal-header {
    padding: 20px 0 0px;
    border: none;
    background: rgba(245, 245, 245, 0.95); }
    .mamodal.motscles .modal-dialog .modal-content .modal-header h2.title_modal {
      padding-bottom: 0px;
      margin-bottom: 20px; }
  .mamodal.motscles .modal-dialog .modal-content .modal-title {
    margin: 0;
    line-height: 1.42857;
    color: #fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    font-size: 3rem;
    text-align: center; }
  .mamodal.motscles .modal-dialog .modal-content .modal-body {
    text-align: center;
    background: rgba(245, 245, 245, 0.95);
    text-align: center; }
    .mamodal.motscles .modal-dialog .modal-content .modal-body .jmoddiv.jmodinside > div {
      display: inline-block;
      text-align: center; }
  .mamodal.formulaire .modal-dialog .modal-content, .mamodal.connexion .modal-dialog .modal-content, .mamodal.rechercher .modal-dialog .modal-content {
    background: transparent;
    box-shadow: none;
    border: none; }
    .mamodal.formulaire .modal-dialog .modal-content .modal_header, .mamodal.connexion .modal-dialog .modal-content .modal_header, .mamodal.rechercher .modal-dialog .modal-content .modal_header {
      padding: 60px 0; }
    .mamodal.formulaire .modal-dialog .modal-content .modal-body, .mamodal.connexion .modal-dialog .modal-content .modal-body, .mamodal.rechercher .modal-dialog .modal-content .modal-body {
      padding: 0; }

.cck_forms {
  position: relative;
  margin: 0 2rem 5rem; }
  @media (min-width: 544px) {
    .cck_forms {
      margin: 0 4rem 5rem; } }
  .cck_forms .cck_form_text input, .cck_forms .cck_form_text textarea, .cck_forms .cck_form_textarea input, .cck_forms .cck_form_textarea textarea, .cck_forms .cck_form_search_generic input, .cck_forms .cck_form_search_generic textarea {
    outline: none;
    z-index: 1;
    position: relative;
    background: none;
    width: 100%;
    height: 6rem;
    border: 0;
    color: #212121;
    font-size: 2.4rem;
    font-weight: 400; }
  .cck_forms .cck_form_text textarea, .cck_forms .cck_form_textarea textarea, .cck_forms .cck_form_search_generic textarea {
    height: 8rem; }
  .cck_forms .cck_label_text label, .cck_forms .cck_label_textarea label, .cck_forms .cck_label_search_generic label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 300;
    display: inline;
    line-height: 6rem;
    height: 6rem;
    overflow-y: hidden;
    transition: 0.2s ease; }
    .cck_forms .cck_label_text label.focused, .cck_forms .cck_label_textarea label.focused, .cck_forms .cck_label_search_generic label.focused {
      color: #9d9d9d;
      transform: translate(-12%, -50%) scale(0.75); }
  .cck_forms .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #757575;
    width: 100%;
    height: 1px; }
    .cck_forms .bar:before, .cck_forms .bar:after {
      content: '';
      position: absolute;
      background: #ab2828;
      width: 0;
      height: 2px;
      -webkit-transition: .2s ease;
      transition: .2s ease; }
    .cck_forms .bar:before {
      left: 50%; }
    .cck_forms .bar:after {
      right: 50%; }
  .cck_forms:hover .bar:before, .cck_forms:hover .bar:after {
    width: 50%;
    height: 3px; }

.recherche_content .cck_forms {
  margin: 70px 0 10px; }
  .recherche_content .cck_forms .cck_form_search_generic {
    padding: 10px 0px; }
    .recherche_content .cck_forms .cck_form_search_generic input {
      color: #fff;
      font-size: 13rem;
      height: 13rem;
      position: relative; }
  .recherche_content .cck_forms .cck_label_search_generic label {
    left: 10px;
    color: #fff;
    font-size: 5rem;
    line-height: 1;
    line-height: 13rem; }
    .recherche_content .cck_forms .cck_label_search_generic label.focused {
      font-size: 2.4rem;
      line-height: 1;
      transform: translate(-15%, -50%) scale(0.8); }
  .recherche_content .cck_forms .cck_form_button_submit {
    margin-top: 1rem;
    margin-bottom: 3rem; }
    .recherche_content .cck_forms .cck_form_button_submit input {
      background: rgba(255, 255, 255, 0.3);
      padding: 2rem 0px;
      font-size: 1.7rem;
      font-weight: 400;
      color: #fff; }
  .recherche_content .cck_forms .bar {
    background-color: #fff;
    height: 2px; }
  .recherche_content .cck_forms.cck_recherche_bouton {
    margin-top: 2rem; }

.recherche_content .nbre_resultats {
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.2; }
  .recherche_content .nbre_resultats .search_motcle {
    font-weight: 900;
    color: #fff;
    border-bottom: 3px dashed #fff; }

.resultat .banner {
  box-shadow: none;
  transition: 0.6s; }

.resultat:hover .banner {
  box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.4), inset 0 -2px 12px 0 rgba(0, 0, 0, 0.2); }

.cck_form_select_simple select {
  background: rgba(151, 174, 181, 0.71);
  padding: 2rem 0px;
  font-size: 1.7rem;
  font-weight: 400;
  color: #fff; }
  .cck_form_select_simple select option {
    padding: 2rem 0px; }

.cck_form_select_dynamic select {
  background: rgba(151, 174, 181, 0.71);
  padding: 20px 0px;
  font-size: 1.7rem;
  font-weight: 400;
  color: #fff; }
  .cck_form_select_dynamic select option {
    padding: 2rem 0px; }

.cck_form_calendar input {
  background: rgba(255, 255, 255, 0.18);
  padding: 2rem 0px;
  font-size: 1.7rem;
  font-weight: 400;
  color: #fff; }

.cck_form_calendar .btn .icon-calendar {
  padding: 0px 1.5rem;
  font-size: 3rem;
  line-height: 1.9;
  color: #ab2828; }

.title_modal {
  position: relative;
  z-index: 1;
  border-left: 5px solid #ab2828;
  margin: 0 0 35px;
  padding: 1rem 0rem 1rem 1rem;
  color: #ab2828;
  font-size: 2.8rem;
  font-weight: 600;
  text-transform: uppercase; }
  @media (min-width: 544px) {
    .title_modal {
      padding: 1rem 0rem 1rem 5rem;
      font-size: 3rem; } }

#seblod_form_m108 .cck_module_search {
  text-align: center;
  margin-top: 2rem; }
  #seblod_form_m108 .cck_module_search .cck_form_button_submit {
    margin-left: 1rem;
    margin-right: 1rem; }

.erreur.icon-close {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 6.5rem 1rem;
  font-size: 4rem;
  color: #fff;
  font-weight: 600;
  line-height: 1;
  z-index: 11;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.4); }
  .erreur.icon-close:before {
    color: #ab2828; }

.cck_form_button_submit, .cck_form_button_free, .ck_form_button_submit {
  text-align: center;
  display: inline-block; }
  .cck_form_button_submit #boutonmotcle, .cck_form_button_free #boutonmotcle, .ck_form_button_submit #boutonmotcle {
    position: relative;
    margin: 2rem; }
  .cck_form_button_submit .bouton_new, .cck_form_button_free .bouton_new, .ck_form_button_submit .bouton_new {
    color: #6e6e6e; }
  .cck_form_button_submit input, .cck_form_button_submit div, .cck_form_button_submit .galerie_lien, .cck_form_button_submit .bouton_new, .cck_form_button_free input, .cck_form_button_free div, .cck_form_button_free .galerie_lien, .cck_form_button_free .bouton_new, .ck_form_button_submit input, .ck_form_button_submit div, .ck_form_button_submit .galerie_lien, .ck_form_button_submit .bouton_new {
    outline: 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: 0;
    width: 230px;
    margin: 0 auto rem;
    border: 2px solid #e3e3e3;
    padding: 1.5rem 0;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;
    transition: .3s ease; }
    .cck_form_button_submit input:before, .cck_form_button_submit div:before, .cck_form_button_submit .galerie_lien:before, .cck_form_button_submit .bouton_new:before, .cck_form_button_free input:before, .cck_form_button_free div:before, .cck_form_button_free .galerie_lien:before, .cck_form_button_free .bouton_new:before, .ck_form_button_submit input:before, .ck_form_button_submit div:before, .ck_form_button_submit .galerie_lien:before, .ck_form_button_submit .bouton_new:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      background: #ab2828;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin: -15px 0 0 -15px;
      opacity: 0;
      transition: .3s ease; }
    .cck_form_button_submit input span, .cck_form_button_submit div span, .cck_form_button_submit .galerie_lien span, .cck_form_button_submit .bouton_new span, .cck_form_button_free input span, .cck_form_button_free div span, .cck_form_button_free .galerie_lien span, .cck_form_button_free .bouton_new span, .ck_form_button_submit input span, .ck_form_button_submit div span, .ck_form_button_submit .galerie_lien span, .ck_form_button_submit .bouton_new span {
      position: relative;
      z-index: 1;
      color: #ddd;
      transition: .3s ease; }
    .cck_form_button_submit input:hover, .cck_form_button_submit input:active, .cck_form_button_submit input:focus, .cck_form_button_submit div:hover, .cck_form_button_submit div:active, .cck_form_button_submit div:focus, .cck_form_button_submit .galerie_lien:hover, .cck_form_button_submit .galerie_lien:active, .cck_form_button_submit .galerie_lien:focus, .cck_form_button_submit .bouton_new:hover, .cck_form_button_submit .bouton_new:active, .cck_form_button_submit .bouton_new:focus, .cck_form_button_free input:hover, .cck_form_button_free input:active, .cck_form_button_free input:focus, .cck_form_button_free div:hover, .cck_form_button_free div:active, .cck_form_button_free div:focus, .cck_form_button_free .galerie_lien:hover, .cck_form_button_free .galerie_lien:active, .cck_form_button_free .galerie_lien:focus, .cck_form_button_free .bouton_new:hover, .cck_form_button_free .bouton_new:active, .cck_form_button_free .bouton_new:focus, .ck_form_button_submit input:hover, .ck_form_button_submit input:active, .ck_form_button_submit input:focus, .ck_form_button_submit div:hover, .ck_form_button_submit div:active, .ck_form_button_submit div:focus, .ck_form_button_submit .galerie_lien:hover, .ck_form_button_submit .galerie_lien:active, .ck_form_button_submit .galerie_lien:focus, .ck_form_button_submit .bouton_new:hover, .ck_form_button_submit .bouton_new:active, .ck_form_button_submit .bouton_new:focus {
      border-color: #ab2828; }

.panel {
  text-align: center; }
  .panel h3 {
    margin-top: 20px;
    margin-bottom: 13.33333px; }
  .panel .filtre_panel > div {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: block; }

.motcle {
  display: inline-block;
  margin: 0 10px 5px 0;
  padding: 10px 14px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 1px;
  background: white;
  position: relative;
  overflow: hidden;
  color: #6E6E6E;
  cursor: pointer;
  transition: .4s; }
  .motcle [type="checkbox"]:not(:checked),
  .motcle [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .motcle span {
    position: relative;
    z-index: 2;
    display: inline-block; }
    .motcle span:before {
      content: "\6f";
      text-transform: initial;
      font-family: myfont;
      font-size: 1.3rem;
      padding-right: 2px;
      display: inline-block;
      opacity: 0.5;
      transform: rotate(0deg);
      transition: .4s; }
  .motcle:before {
    position: absolute;
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background-color: #ab2828;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: 1;
    transition: .4s; }
  .motcle:hover, .motcle.selected {
    color: #fff;
    text-decoration: none; }
    .motcle:hover:before, .motcle.selected:before {
      width: 100%;
      left: -8%;
      color: #5bcaff;
      width: 120%; }
    .motcle:hover span:before, .motcle.selected span:before {
      opacity: 1; }
  .motcle.selected:hover span:before {
    content: "\73";
    transform: rotate(720deg); }

.filtre {
  margin-bottom: 10px;
  margin-top: 10px; }
  .filtre > a {
    display: block;
    position: relative;
    height: 80px;
    color: #fff; }
    .filtre > a img {
      display: inline-block;
      vertical-align: middle;
      background-color: #c0392b;
      padding: 25px;
      height: 80px; }
    .filtre > a span {
      display: inline-block;
      vertical-align: middle;
      background-color: #e74c3c;
      padding: 10px 20px;
      height: 80px;
      line-height: 60px; }
    .filtre > a.avecico span {
      position: absolute;
      overflow: hidden;
      left: 80px;
      top: 0;
      right: 0; }
    .filtre > a.sansico span {
      display: block;
      text-align: center; }

.croix {
  position: relative;
  display: inline-block;
  vertical-align: super;
  margin-right: 12px; }
  .croix .rectangle {
    position: absolute;
    width: 10px;
    height: 2px;
    background: #6E6E6E; }
    .croix .rectangle.croix1 {
      transform: rotate(45deg); }
    .croix .rectangle.croix2 {
      transform: rotate(-45deg); }

.admin_motcle {
  position: relative;
  text-transform: initial;
  font-family: myfont;
  font-size: 1.8rem;
  padding-right: 5px;
  padding-left: 5px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  color: #6E6E6E;
  z-index: 2;
  transition: 0.3s; }
  .admin_motcle:first-of-type {
    margin-left: 5px; }
  .admin_motcle:last-of-type {
    padding-right: 0px; }
  .admin_motcle:hover {
    text-decoration: none;
    color: #fff;
    opacity: 1; }

.cck_group_x {
  display: block; }
  .cck_group_x .ui-sortable .cck_form_group_x {
    position: relative;
    margin-bottom: 50px;
    z-index: 3; }
    .cck_group_x .ui-sortable .cck_form_group_x aside {
      float: none;
      position: absolute;
      width: 60px;
      left: 50%;
      bottom: 35px;
      margin-left: -30px;
      z-index: 1000; }
    .cck_group_x .ui-sortable .cck_form_group_x .cck_cgx_form {
      float: none;
      text-align: center;
      border: 1px solid rgba(177, 31, 31, 0.4);
      padding: 30px 20px; }
  .cck_group_x #cck1r_label_p_galerie {
    height: 60px; }

div.adminformlist {
  float: none; }

.center_titre {
  display: block;
  text-align: center; }

/***********************
************************
****** DEBUT MEGATIRE */
.megatitre {
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 0 !important;
  margin: 0;
  font-size: 4rem;
  line-height: 1.2;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    .megatitre {
      font-size: 5rem; } }

/***********************
************************
****** FIN search_titre */
.search_titre {
  height: 349px;
  background-size: auto 110%; }
  .search_titre .block_titre {
    margin-top: 101px; }
  @media (min-width: 544px) {
    .search_titre {
      height: 400px; } }
  @media (min-width: 768px) {
    .search_titre {
      height: 600px; } }
  @media (min-width: 992px) {
    .search_titre {
      height: 750px; }
      .search_titre .block_titre {
        margin-top: 151px; } }
  @media (min-width: 1920px) {
    .search_titre {
      background-size: 100% 110%; } }

.search_titre {
  position: relative;
  background-repeat: no-repeat;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 40px; }
  .search_titre .block_titre {
    position: relative;
    z-index: 10; }
  .search_titre:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.42);
    animation: bgmoveafter 5s linear infinite alternate-reverse; }
  .search_titre .megatitre svg {
    width: 100%; }
    .search_titre .megatitre svg text {
      font-size: 0.65rem; }
  .search_titre .megatitre > span:not(:last-of-type) {
    position: relative;
    font-size: 3rem;
    font-weight: 700;
    font-family: lato;
    letter-spacing: 4px;
    padding-bottom: 7px;
    display: block;
    background: rgba(255, 255, 255, 0.05); }
    @media (min-width: 544px) {
      .search_titre .megatitre > span:not(:last-of-type) {
        font-size: 7rem; } }
    @media (min-width: 768px) {
      .search_titre .megatitre > span:not(:last-of-type) {
        font-size: 9rem; } }
    @media (min-width: 992px) {
      .search_titre .megatitre > span:not(:last-of-type) {
        font-weight: 900; } }
    .search_titre .megatitre > span:not(:last-of-type):before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: -30px;
      top: -30px;
      background: transparent;
      border-left: 4px solid #fff;
      border-top: 4px solid #fff; }
    .search_titre .megatitre > span:not(:last-of-type):after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: -30px;
      top: -30px;
      background: transparent;
      border-right: 4px solid #fff;
      border-top: 4px solid #fff; }
  .search_titre .megatitre > span:last-of-type {
    font-size: 4rem;
    box-shadow: initial; }
    .search_titre .megatitre > span:last-of-type i, .search_titre .megatitre > span:last-of-type .mot {
      display: inline-block;
      font-family: lato;
      font-size: 1.5rem; }
  .search_titre .megatitre:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -30px;
    bottom: -30px;
    background: transparent;
    border-left: 4px solid #fff;
    border-bottom: 4px solid #fff; }
  .search_titre .megatitre:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -30px;
    bottom: -30px;
    background: transparent;
    border-right: 4px solid #fff;
    border-bottom: 4px solid #fff; }
  .search_titre.portfolio {
    margin-bottom: 0;
    animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite; }
  .search_titre.rechercher {
    margin-bottom: 0;
    animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite; }
  .search_titre.accueil {
    animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite; }
  .search_titre.clients {
    animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite; }
  .search_titre.blog {
    animation: bgmove 5s linear infinite alternate-reverse, bgimgblogxs 20s linear infinite; }
    @media (min-width: 544px) {
      .search_titre.blog {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgblogsm 20s linear infinite; } }
    @media (min-width: 768px) {
      .search_titre.blog {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgblogmd 20s linear infinite; } }
    @media (min-width: 992px) {
      .search_titre.blog {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgbloglg 20s linear infinite; } }
    @media (min-width: 1280px) {
      .search_titre.blog {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgblogxl 20s linear infinite; } }

@keyframes bgimgblogxs {
  from {
    background-image: url("../../../images/bg/cliquez-la.com-1-xs.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/cliquez-la.com-1-xs.jpg"); }
  25% {
    background-image: url("../../../images/bg/cliquez-la.com-2-xs.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/cliquez-la.com-2-xs.jpg"); }
  50% {
    background-image: url("../../../images/bg/cliquez-la.com-3-xs.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/cliquez-la.com-3-xs.jpg"); }
  75% {
    background-image: url("../../../images/bg/cliquez-la.com-4-xs.jpg"); }
  to {
    background-image: url("../../../images/bg/cliquez-la.com-4-xs.jpg"); } }

@keyframes bgimgblogsm {
  from {
    background-image: url("../../../images/bg/cliquez-la.com-1-sm.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/cliquez-la.com-1-sm.jpg"); }
  25% {
    background-image: url("../../../images/bg/cliquez-la.com-2-sm.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/cliquez-la.com-2-sm.jpg"); }
  50% {
    background-image: url("../../../images/bg/cliquez-la.com-3-sm.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/cliquez-la.com-3-sm.jpg"); }
  75% {
    background-image: url("../../../images/bg/cliquez-la.com-4-sm.jpg"); }
  to {
    background-image: url("../../../images/bg/cliquez-la.com-4-sm.jpg"); } }

@keyframes bgimgblogmd {
  from {
    background-image: url("../../../images/bg/cliquez-la.com-1-md.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/cliquez-la.com-1-md.jpg"); }
  25% {
    background-image: url("../../../images/bg/cliquez-la.com-2-md.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/cliquez-la.com-2-md.jpg"); }
  50% {
    background-image: url("../../../images/bg/cliquez-la.com-3-md.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/cliquez-la.com-3-md.jpg"); }
  75% {
    background-image: url("../../../images/bg/cliquez-la.com-4-md.jpg"); }
  to {
    background-image: url("../../../images/bg/cliquez-la.com-4-md.jpg"); } }

@keyframes bgimgbloglg {
  from {
    background-image: url("../../../images/bg/cliquez-la.com-1-lg.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/cliquez-la.com-1-lg.jpg"); }
  25% {
    background-image: url("../../../images/bg/cliquez-la.com-2-lg.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/cliquez-la.com-2-lg.jpg"); }
  50% {
    background-image: url("../../../images/bg/cliquez-la.com-3-lg.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/cliquez-la.com-3-lg.jpg"); }
  75% {
    background-image: url("../../../images/bg/cliquez-la.com-4-lg.jpg"); }
  to {
    background-image: url("../../../images/bg/cliquez-la.com-4-lg.jpg"); } }

@keyframes bgimgblogxl {
  from {
    background-image: url("../../../images/bg/cliquez-la.com-1-xl.jpg"); }
  24.9% {
    background-image: url("../../../images/bg/cliquez-la.com-1-xl.jpg"); }
  25% {
    background-image: url("../../../images/bg/cliquez-la.com-2-xl.jpg"); }
  49.9% {
    background-image: url("../../../images/bg/cliquez-la.com-2-xl.jpg"); }
  50% {
    background-image: url("../../../images/bg/cliquez-la.com-3-xl.jpg"); }
  74.9% {
    background-image: url("../../../images/bg/cliquez-la.com-3-xl.jpg"); }
  75% {
    background-image: url("../../../images/bg/cliquez-la.com-4-xl.jpg"); }
  to {
    background-image: url("../../../images/bg/cliquez-la.com-4-xl.jpg"); } }

/***********************
************************
****** FIN MEGATITRE */
/***********************
************************
****** DEBUT BANNER */
.banner {
  position: relative;
  margin-top: 0;
  padding-bottom: 30px;
  text-align: center;
  width: 100%; }
  .banner > *, .banner > * > * {
    position: relative; }
  .banner .bg {
    position: absolute;
    left: 0;
    right: 0;
    height: 85%;
    bottom: 0;
    box-shadow: inset 0 2px 5px rgba(128, 128, 128, 0.2), inset 0 -2px 5px rgba(80, 80, 80, 0.3), 0 1px 1px rgba(0, 0, 0, 0.3), 0 -1px 1px rgba(0, 0, 0, 0.3); }
  .banner.home_pc_morph {
    margin: 0; }
    .banner.home_pc_morph .bg {
      height: 100%;
      transition: background-color 1.5s ease-in-out; }
      @media (min-width: 992px) {
        .banner.home_pc_morph .bg {
          height: 80%; } }
  .banner.showcase .bg {
    height: 80%; }
  .banner.showcase > img {
    max-width: 90%; }
    @media (min-width: 992px) {
      .banner.showcase > img {
        max-width: 800px; } }
  .banner.blog {
    padding-bottom: 80px; }
    .banner.blog .bg {
      height: 80%; }
    .banner.blog .img_affiche {
      margin-bottom: 10px; }
    .banner.blog .descr p {
      font-size: 2.8rem;
      font-weight: 300;
      line-height: 1.3;
      padding: 10px 10px 10px;
      color: #fff; }
  .banner.client .bg {
    height: 100%; }
  .banner.clients .bg {
    height: 90%;
    top: 5%; }
  .banner.clients .cover {
    display: inline-block; }
    .banner.clients .cover > img {
      transform: scale(0.9);
      transition: transform 6s ease-in 0.2s;
      max-width: 100%;
      height: auto; }
    .banner.clients .cover a {
      display: block;
      /* 
                width: 80%;
                margin-left: 10%; */ }
  .banner.clients > img {
    max-height: 300px;
    width: 80%; }
  .banner.clients .intro-titre {
    bottom: 0% !important;
    opacity: 0;
    transition: .5s; }
  .banner.clients:hover .intro-titre {
    bottom: 10% !important;
    opacity: 1; }
  .banner.clients:hover .cover > img {
    transform: scale(1); }
  .banner.affiche {
    margin-top: -120px; }
    @media (min-width: 992px) {
      .banner.affiche .cover {
        transform-style: preserve-3d; }
        .banner.affiche .cover .img_affiche {
          transform-origin: top;
          perspective-origin: top;
          max-height: 90%;
          transform: perspective(300px) rotateX(0deg);
          animation-duration: .5s;
          transition: .5s;
          /* &:hover{
                        animation: affiche 1s ease-out forwards;
                    } */ } }
  .banner.album {
    padding-bottom: 50px;
    margin-bottom: 80px; }
    .banner.album .bg {
      height: 92%; }
    .banner.album:last-of-type {
      margin-bottom: 40px; }
  .banner.meme_client {
    padding-bottom: 0px;
    margin-bottom: 20px; }
    .banner.meme_client .bg {
      height: 90%;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3); }
    .banner.meme_client a {
      display: block; }
      .banner.meme_client a:hover {
        animation: bounce 0.7s; }
    .banner.meme_client img {
      max-width: 100%;
      height: auto; }
  .banner.autre_rea_client {
    padding-bottom: 0; }
  .banner.portfolio_client {
    padding-bottom: 0; }
  .banner.content.flyer .img_affiche {
    margin-top: -110px; }
  .banner.content.flocage .img_affiche {
    margin-top: -150px; }
  .banner.content.client {
    padding-bottom: 0; }
  .banner.content.rechercher.content .img_affiche {
    max-width: 80%;
    height: auto; }
  .banner.intro.clients {
    width: 100%;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 0;
    padding-bottom: 0; }
    .banner.intro.clients .intro-titre {
      display: none; }
    @media (min-width: 544px) {
      .banner.intro.clients {
        min-height: 320px; }
        .banner.intro.clients .intro-titre {
          display: block; } }
    @media (min-width: 768px) {
      .banner.intro.clients {
        min-height: 380px; } }
    @media (min-width: 992px) {
      .banner.intro.clients {
        min-height: 400px; } }
  .banner.intro.affiche {
    margin-top: 0px;
    padding-top: 0px; }
    .banner.intro.affiche .cover {
      position: absolute;
      top: 0; }
  .banner.intro:not(.affiche) {
    margin-top: 20px; }
    @media (min-width: 544px) {
      .banner.intro:not(.affiche) {
        margin-top: 0; } }
    .banner.intro:not(.affiche) .cover {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; }
  .banner.intro.cartevisite .cover img {
    display: block;
    padding-top: 30px; }
  .banner.intro .cover {
    position: relative;
    z-index: 2; }
  .banner.intro .bg {
    box-shadow: inset 0 -1px 0px rgba(80, 80, 80, 0.3), 0 1px 1px rgba(0, 0, 0, 0.3), 0 -1px 1px rgba(0, 0, 0, 0.1); }
  .banner.intro.portfolio {
    min-height: 320px;
    padding-bottom: 0; }
    .banner.intro.portfolio .wrapper_intro {
      width: 100%;
      padding-bottom: 30px;
      padding-right: 30px;
      padding-left: 30px;
      position: relative; }
    .banner.intro.portfolio .bg {
      height: 60%;
      left: 5%;
      right: 5%; }
    @media (min-width: 544px) {
      .banner.intro.portfolio {
        min-height: 320px; }
        .banner.intro.portfolio .wrapper_intro {
          height: 100%;
          position: absolute;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center; } }
    @media (min-width: 768px) {
      .banner.intro.portfolio {
        min-height: 380px; } }
    @media (min-width: 992px) {
      .banner.intro.portfolio {
        min-height: 400px; } }
  .banner.intro .intro-titre {
    position: relative;
    display: block;
    padding-right: 2rem;
    padding-left: 2rem;
    z-index: 1;
    text-align: center;
    text-decoration: none;
    color: #fff; }
    .banner.intro .intro-titre span {
      display: inline;
      text-transform: uppercase;
      font-size: 1.3rem;
      position: relative; }
      .banner.intro .intro-titre span:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 8px;
        right: 8px;
        height: 1px;
        background: #fff; }
    .banner.intro .intro-titre h3 {
      font-size: 2rem;
      line-height: 1.2;
      font-weight: normal;
      margin: 10px 0 0 0;
      padding: 0;
      overflow: hidden; }
      @media (min-width: 544px) {
        .banner.intro .intro-titre h3 {
          max-height: 4.4rem; } }
  @media (min-width: 768px) {
    .banner.intro.siteweb .cover img, .banner.intro.cartevisite .cover img {
      display: inline;
      padding-top: 0; } }
  .banner.rechercher {
    padding-top: 30px; }
    .banner.rechercher .bg {
      height: 100%;
      padding-top: 30px; }
    .banner.rechercher .megatitre {
      display: block;
      font-size: 2.5rem;
      margin-bottom: 10px;
      margin-top: 10px; }
    .banner.rechercher .tags {
      margin-bottom: 10px;
      padding-bottom: 0; }
    .banner.rechercher .tags-links .tag-link {
      margin: 20px 5px 10px; }
  .banner p {
    margin: 10px auto 20px;
    font-size: 2.8rem;
    font-weight: 300; }
  .banner .quote {
    font-weight: 900;
    font-size: 2rem;
    line-height: 3.7rem;
    padding: 30px 30px 50px;
    color: #fff; }
    .banner .quote i {
      font-size: 4rem;
      line-height: 4.7rem; }

.blog .blog_contexte {
  margin-bottom: 20px;
  text-align: center; }
  .blog .blog_contexte h2, .blog .blog_contexte h3 {
    position: relative;
    text-align: center;
    display: block;
    padding: 0 !important;
    margin: 0;
    margin-top: 60px; }
  .blog .blog_contexte h2 {
    font-size: 4rem;
    line-height: 1.3;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.25rem; }
  .blog .blog_contexte h3 {
    font-family: lato;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.2;
    color: #969696; }
  .blog .blog_contexte p {
    text-align: center;
    position: relative;
    margin-bottom: 40px; }
    .blog .blog_contexte p.hr {
      margin-bottom: 40px; }
      .blog .blog_contexte p.hr:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        bottom: -13.33333px;
        left: 30%;
        right: 30%;
        z-index: 10; }
  .blog .blog_contexte img {
    display: inline;
    margin-top: 20px;
    margin-bottom: 20px; }

/***********************
************************
****** FIN BANNER */
#home {
  text-align: center; }
  #home .morph_block {
    height: 45.0672vw; }
  #home .pc {
    display: inline-block;
    position: relative; }
    #home .pc:after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 10px;
      left: 5%;
      right: 5%;
      height: 40px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      background: #000;
      border-radius: 50%;
      background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 ); }
    #home .pc .inner {
      position: relative;
      transform-style: preserve-3d;
      perspective: 800px; }
      #home .pc .inner:before {
        content: '';
        background-image: url("../../../images/stand-pc.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        z-index: -1;
        opacity: 0;
        bottom: 0px;
        height: 9.4272vw;
        width: 15.6vw;
        left: 50%;
        margin-left: -7.8vw;
        transition: 1.4s ease-in-out; }
      #home .pc .inner .blockin {
        box-sizing: content-box;
        position: relative;
        margin-bottom: 9.4272vw;
        background-color: rgba(255, 255, 255, 0.7);
        border: solid #0f0f0f;
        transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-align: center;
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.65);
        overflow-y: hidden;
        overflow-x: hidden;
        transition: 1.4s ease-in-out; }
      #home .pc .inner #pcanim .anim_header_pc {
        display: none;
        display: block;
        /* padding: 0.2em 1%; */
        transition: 0.3s; }
        #home .pc .inner #pcanim .anim_header_pc.ecran {
          font-size: 3px; }
          @media (min-width: 544px) {
            #home .pc .inner #pcanim .anim_header_pc.ecran {
              font-size: 5px; } }
          @media (min-width: 768px) {
            #home .pc .inner #pcanim .anim_header_pc.ecran {
              font-size: 7px; } }
          @media (min-width: 992px) {
            #home .pc .inner #pcanim .anim_header_pc.ecran {
              font-size: 10px; } }
          @media (min-width: 1280px) {
            #home .pc .inner #pcanim .anim_header_pc.ecran {
              font-size: 12px; } }
          @media (min-width: 1920px) {
            #home .pc .inner #pcanim .anim_header_pc.ecran {
              font-size: 14px; } }
          #home .pc .inner #pcanim .anim_header_pc.ecran .conteneur {
            position: relative;
            display: inline-block;
            padding-left: 1em;
            padding-right: 1em;
            width: 80%; }
          #home .pc .inner #pcanim .anim_header_pc.ecran .block {
            margin-bottom: 2em;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            background-color: rgba(255, 255, 255, 0.9);
            color: rgba(171, 40, 40, 0.7);
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.ecran .block:hover {
              background-color: rgba(171, 40, 40, 0.7);
              color: rgba(255, 255, 255, 0.9); }
          #home .pc .inner #pcanim .anim_header_pc.ecran .block_reverse {
            background-color: rgba(171, 40, 40, 0.7);
            color: rgba(255, 255, 255, 0.9);
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.ecran .block_reverse:hover {
              background-color: rgba(255, 255, 255, 0.9);
              color: rgba(171, 40, 40, 0.7); }
          #home .pc .inner #pcanim .anim_header_pc.ecran .w1 {
            width: 96%;
            margin-left: 2%;
            margin-right: 2%; }
          #home .pc .inner #pcanim .anim_header_pc.ecran .w2 {
            width: 46%;
            margin-left: 2%;
            margin-right: 2%; }
          #home .pc .inner #pcanim .anim_header_pc.ecran .w3 {
            width: 29.33333%;
            margin-left: 2%;
            margin-right: 2%; }
          #home .pc .inner #pcanim .anim_header_pc.ecran .w4 {
            width: 21%;
            margin-left: 2%;
            margin-right: 2%; }
        #home .pc .inner #pcanim .anim_header_pc .header_pc, #home .pc .inner #pcanim .anim_header_pc .liste .block, #home .pc .inner #pcanim .anim_header_pc .ico_left .block, #home .pc .inner #pcanim .anim_header_pc .conteneur .block, #home .pc .inner #pcanim .anim_header_pc .conteneur_left .titrep {
          opacity: 0;
          margin-top: 2em;
          transition: 0.3s; }
        #home .pc .inner #pcanim .anim_header_pc .conteneur {
          position: relative;
          display: inline-block;
          padding-left: 1em;
          padding-right: 1em;
          width: 80%; }
        #home .pc .inner #pcanim .anim_header_pc .conteneur_left {
          position: relative;
          display: inline-block;
          padding-left: 1em;
          padding-right: 0;
          width: 20%;
          vertical-align: top; }
          #home .pc .inner #pcanim .anim_header_pc .conteneur_left .titrep {
            margin-bottom: 0.4em; }
        #home .pc .inner #pcanim .anim_header_pc .blocktext {
          font-family: blokk;
          font-size: 0.7em;
          line-height: 1.5;
          text-align: right;
          display: inline-block;
          overflow: hidden; }
        #home .pc .inner #pcanim .anim_header_pc .titrep {
          display: block;
          padding: 1em 1em;
          font-size: 0.9em; }
        #home .pc .inner #pcanim .anim_header_pc .tal {
          text-align: left; }
        #home .pc .inner #pcanim .anim_header_pc .tar {
          text-align: right; }
        #home .pc .inner #pcanim .anim_header_pc .inner_block {
          display: block;
          position: relative;
          width: 100%; }
          #home .pc .inner #pcanim .anim_header_pc .inner_block .blocktext {
            width: 70%; }
        #home .pc .inner #pcanim .anim_header_pc .header_pc {
          background-color: rgba(255, 255, 255, 0.9);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }
          #home .pc .inner #pcanim .anim_header_pc .header_pc .votre_logo {
            font-family: placard;
            display: inline-block;
            font-size: 4em;
            margin-top: 0.2em;
            text-align: left;
            margin-left: 2%; }
            #home .pc .inner #pcanim .anim_header_pc .header_pc .votre_logo:before {
              vertical-align: middle; }
          #home .pc .inner #pcanim .anim_header_pc .header_pc .icon-burger {
            text-align: right;
            display: inline-block;
            vertical-align: middle;
            font-size: 3em;
            margin-right: 0.2em; }
        #home .pc .inner #pcanim .anim_header_pc .liste {
          text-align: left;
          margin-bottom: 0; }
          #home .pc .inner #pcanim .anim_header_pc .liste li.block {
            padding: 1em 2em;
            margin-bottom: 1em; }
        #home .pc .inner #pcanim .anim_header_pc .ico_left > div {
          display: inline-block;
          vertical-align: middle;
          width: 23%;
          margin: 1%;
          font-size: 1.5em; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-left, #home .pc .inner #pcanim .anim_header_pc .slider .icon-right, #home .pc .inner #pcanim .anim_header_pc .slider .icon-img-thumb, #home .pc .inner #pcanim .anim_header_pc .slider .icon-webmarketing {
          display: inline-block;
          vertical-align: middle;
          position: relative; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-left, #home .pc .inner #pcanim .anim_header_pc .slider .icon-right {
          width: 25%;
          font-size: 2em; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-img-thumb, #home .pc .inner #pcanim .anim_header_pc .slider .icon-webmarketing {
          width: 50%; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-left {
          text-align: left;
          padding-left: 0.4em; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-right {
          text-align: right;
          padding-right: 0.4em; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-webmarketing:before {
          font-size: 2em;
          margin-right: 0em;
          vertical-align: top; }
        #home .pc .inner #pcanim .anim_header_pc .slider .icon-img-thumb {
          font-size: 4em; }
        #home .pc .inner #pcanim .anim_header_pc .image {
          display: inline-block;
          vertical-align: middle;
          text-align: left; }
          #home .pc .inner #pcanim .anim_header_pc .image .inner_block > div {
            display: inline-block;
            vertical-align: middle;
            width: 30%;
            position: relative;
            font-size: 4em;
            text-align: center; }
          #home .pc .inner #pcanim .anim_header_pc .image .inner_block .blocktext {
            display: inline-block;
            width: 60%;
            margin-left: 10%;
            position: relative;
            text-align: right;
            padding-right: 2em;
            vertical-align: top; }
        #home .pc .inner #pcanim .anim_header_pc .carre {
          text-align: center; }
          #home .pc .inner #pcanim .anim_header_pc .carre.w2 {
            width: 48%;
            margin-left: 1%;
            margin-right: 1%;
            padding-top: 0.4em;
            padding-bottom: 0.7em; }
          #home .pc .inner #pcanim .anim_header_pc .carre.w3 {
            width: 31%;
            margin-left: 1%;
            margin-right: 1%;
            padding-top: 0.4em;
            padding-bottom: 0.7em; }
          #home .pc .inner #pcanim .anim_header_pc .carre > div {
            display: block;
            width: 100%;
            font-size: 3.8em;
            padding-bottom: 0.1em; }
          #home .pc .inner #pcanim .anim_header_pc .carre .blocktext {
            display: block;
            width: 100%;
            text-align: center; }
        #home .pc .inner #pcanim .anim_header_pc.tablet {
          font-size: 8px; }
          #home .pc .inner #pcanim .anim_header_pc.tablet .block {
            margin-bottom: 1em;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            background-color: rgba(255, 255, 255, 0.9);
            color: rgba(51, 158, 191, 0.7);
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.tablet .block:hover {
              background-color: rgba(51, 158, 191, 0.7);
              color: rgba(255, 255, 255, 0.9); }
          #home .pc .inner #pcanim .anim_header_pc.tablet .block_reverse {
            background-color: rgba(51, 158, 191, 0.7);
            color: rgba(255, 255, 255, 0.9);
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.tablet .block_reverse:hover {
              background-color: rgba(255, 255, 255, 0.9);
              color: rgba(51, 158, 191, 0.7); }
          #home .pc .inner #pcanim .anim_header_pc.tablet .header_pc {
            margin-bottom: 0em;
            padding-top: 0.4em;
            padding-bottom: 0.6em; }
            #home .pc .inner #pcanim .anim_header_pc.tablet .header_pc .votre_logo {
              font-size: 3em;
              margin-left: 0; }
            #home .pc .inner #pcanim .anim_header_pc.tablet .header_pc .icon-burger {
              font-size: 2.3em;
              margin-right: 0;
              margin-left: 0.7em; }
          #home .pc .inner #pcanim .anim_header_pc.tablet .conteneur {
            position: relative;
            display: block;
            padding-left: 0;
            padding-right: 0;
            width: 100%; }
          #home .pc .inner #pcanim .anim_header_pc.tablet .slider .inner_block {
            font-size: 1.6em;
            padding-top: 0.6em;
            padding-bottom: 0.4em;
            line-height: 1; }
        #home .pc .inner #pcanim .anim_header_pc.tel {
          font-size: 6px; }
          #home .pc .inner #pcanim .anim_header_pc.tel .block {
            margin-bottom: 1em;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            background-color: rgba(255, 255, 255, 0.9);
            color: #e67e22;
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.tel .block:hover {
              background-color: #e67e22;
              color: rgba(255, 255, 255, 0.9); }
          #home .pc .inner #pcanim .anim_header_pc.tel .block_reverse {
            background-color: #e67e22;
            color: rgba(255, 255, 255, 0.9);
            transition: 0.3s; }
            #home .pc .inner #pcanim .anim_header_pc.tel .block_reverse:hover {
              background-color: rgba(255, 255, 255, 0.9);
              color: #e67e22; }
          #home .pc .inner #pcanim .anim_header_pc.tel .header_pc {
            margin-bottom: 0em;
            padding-top: 0.4em;
            padding-bottom: 0.6em; }
            #home .pc .inner #pcanim .anim_header_pc.tel .header_pc .votre_logo {
              font-size: 3em;
              margin-left: 0; }
            #home .pc .inner #pcanim .anim_header_pc.tel .header_pc .icon-burger {
              font-size: 2.3em;
              margin-right: 0;
              margin-left: 0.7em; }
          #home .pc .inner #pcanim .anim_header_pc.tel .conteneur {
            position: relative;
            display: block;
            padding-left: 0;
            padding-right: 0;
            width: 100%; }
          #home .pc .inner #pcanim .anim_header_pc.tel .image .inner_block > div {
            display: block;
            width: 100%;
            font-size: 3em;
            padding-top: 0.4em;
            padding-bottom: 0.4em;
            line-height: 1; }
          #home .pc .inner #pcanim .anim_header_pc.tel .image .inner_block .icon-shop {
            width: 30%;
            padding-left: 1.4em;
            display: inline-block; }
          #home .pc .inner #pcanim .anim_header_pc.tel .image .inner_block .blocktext {
            vertical-align: middle; }
      #home .pc .inner #pcanim.start .header_pc, #home .pc .inner #pcanim.start .liste .block, #home .pc .inner #pcanim.start .ico_left .block, #home .pc .inner #pcanim.start .conteneur .block, #home .pc .inner #pcanim.start .conteneur_left .titrep {
        margin-top: 0em;
        opacity: 1;
        transition: opacity 1.2s linear, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68); }
      #home .pc .inner #pcanim.start .ico_left .block:nth-of-type(1) {
        transition: opacity 1.2s linear 0.3s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.3s; }
      #home .pc .inner #pcanim.start .ico_left .block:nth-of-type(2) {
        transition: opacity 1.2s linear 0.6s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.6s; }
      #home .pc .inner #pcanim.start .ico_left .block:nth-of-type(3) {
        transition: opacity 1.2s linear 0.9s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.9s; }
      #home .pc .inner #pcanim.start .ico_left .block:nth-of-type(4) {
        transition: opacity 1.2s linear 1.2s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 1.2s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(1) {
        transition: opacity 1.2s linear 0.3s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.3s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(2) {
        transition: opacity 1.2s linear 0.4s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.4s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(3) {
        transition: opacity 1.2s linear 0.5s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.5s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(4) {
        transition: opacity 1.2s linear 0.6s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.6s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(5) {
        transition: opacity 1.2s linear 0.7s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.7s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(6) {
        transition: opacity 1.2s linear 0.8s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.8s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(7) {
        transition: opacity 1.2s linear 0.9s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 0.9s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(8) {
        transition: opacity 1.2s linear 1s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 1s; }
      #home .pc .inner #pcanim.start .conteneur .block:nth-of-type(9) {
        transition: opacity 1.2s linear 1.1s, margin-top 1.2s cubic-bezier(0.57, 0.59, 0, 1.68) 1.1s; }
      #home .pc .inner#pcmorph {
        /* 
                &:after{
                    content: "\53";
                    opacity: 1;                    
                    font-size: 26rem;
                    z-index: -1;
                    transition: 1s ease-in-out 1.2s;
                } */ }
        #home .pc .inner#pcmorph .blockin {
          width: 48vw;
          height: 32.04vw;
          border-width: 1.2vw;
          border-bottom-width: 2.4vw;
          border-radius: 15px;
          background-color: rgba(250, 250, 250, 0.95);
          transform: rotateY(0deg) rotateX(0deg); }
          #home .pc .inner#pcmorph .blockin > div, #home .pc .inner#pcmorph .blockin > div > div {
            height: 32.04vw; }
        #home .pc .inner#pcmorph:before {
          opacity: 1;
          bottom: -9.4272vw; }
      #home .pc .inner#telmorph .blockin {
        width: 17vw;
        height: 25.5vw;
        border-width: 4.25vw;
        border-bottom-width: 5.525vw;
        border-left-width: 0.425vw;
        border-right-width: 0.425vw;
        border-radius: 0.85vw;
        transform: translateY(3.1424vw) rotateY(-10deg) rotateX(15deg); }
        #home .pc .inner#telmorph .blockin > div, #home .pc .inner#telmorph .blockin > div > div {
          height: 25.5vw; }
      #home .pc .inner#tabletmorph .blockin {
        width: 25vw;
        height: 32.65707vw;
        border-width: 1.57068vw;
        border-radius: 0.65445vw;
        transform: translateY(0.21815vw) rotateY(10deg) rotateX(15deg); }
        #home .pc .inner#tabletmorph .blockin > div, #home .pc .inner#tabletmorph .blockin > div > div {
          height: 32.65707vw; }

.h5 {
  height: 5%; }

.h15 {
  height: 15%; }

.h25 {
  height: 25%; }

.h35 {
  height: 35%; }

.h45 {
  height: 45%; }

.h55 {
  height: 55%; }

.h65 {
  height: 65%; }

.h75 {
  height: 75%; }

.h85 {
  height: 85%; }

.h95 {
  height: 95%; }

.h10 {
  height: 10%; }

.h20 {
  height: 20%; }

.h30 {
  height: 30%; }

.h40 {
  height: 40%; }

.h50 {
  height: 50%; }

.h60 {
  height: 60%; }

.h70 {
  height: 70%; }

.h80 {
  height: 80%; }

.h90 {
  height: 90%; }

.h100 {
  height: 100%; }

.commandes, #textpc {
  color: #dfdfdf; }
  .commandes > a, .commandes > div, #textpc > a, #textpc > div {
    padding: 30px; }

.commandes {
  display: block;
  text-align: center; }
  .commandes i {
    display: block;
    font-weight: 400;
    font-size: 8rem;
    margin-bottom: 1rem; }
  .commandes a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 2rem;
    text-decoration: none;
    line-height: 1;
    font-weight: 700;
    color: #dfdfdf;
    opacity: 0.7;
    transition: 0.3s; }
    .commandes a.active_morph {
      opacity: 1; }
    .commandes a:hover {
      opacity: 1; }

#textpc {
  font-size: 1.5rem;
  text-align: right; }
  #textpc > div {
    display: none;
    margin: 0;
    font-size: 2rem;
    text-align: left; }
    #textpc > div h2 {
      display: block;
      text-transform: uppercase;
      font-family: lato;
      font-size: 2.6rem;
      text-decoration: none;
      line-height: 1;
      font-weight: 700;
      color: #fff;
      margin: 0;
      padding-top: 0;
      padding-bottom: 10px; }
      #textpc > div h2 i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px; }
    #textpc > div ul {
      display: block; }
      #textpc > div ul li {
        padding: 10px 0; }
        #textpc > div ul li:before {
          margin-right: 15px;
          vertical-align: middle; }

#textsouspc {
  text-align: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 2.5rem;
  display: block; }
  #textsouspc h2 {
    display: none;
    text-transform: none;
    position: absolute;
    left: 15px;
    right: 15px;
    color: rgba(255, 255, 255, 0.7);
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: lato;
    font-size: 3.4rem;
    letter-spacing: 1px;
    height: 2.5rem;
    font-style: italic;
    font-weight: 700; }
    #textsouspc h2 i {
      font-size: 1.2rem;
      line-height: 2.5rem;
      font-weight: 400;
      vertical-align: super; }
      #textsouspc h2 i.icon-quote-left {
        margin-right: 10px; }
      #textsouspc h2 i.icon-quote-right {
        margin-left: 10px; }

.bottom_intro_home {
  position: relative; }
  .bottom_intro_home .bg {
    height: 75%;
    transition: background-color 0.8s ease-in-out; }
  .bottom_intro_home .cck_forms {
    margin: 40px auto 0;
    transition: 0.3s; }
    .bottom_intro_home .cck_forms .cck_label_text label {
      font-size: 1.8em;
      text-align: center;
      color: #fff;
      opacity: 0.9; }
      .bottom_intro_home .cck_forms .cck_label_text label:before {
        vertical-align: middle;
        margin-right: 10px; }

#intro_pc .navigation {
  margin-bottom: 20px;
  margin-top: 10px;
  display: block; }
  #intro_pc .navigation ul li {
    display: inline-block;
    margin: 10px 10px;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 300; }
    #intro_pc .navigation ul li a, #intro_pc .navigation ul li span {
      padding: 10px 15px;
      background: #fff;
      color: #6E6E6E;
      text-decoration: none;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      transition: 0.8s; }
      #intro_pc .navigation ul li a.active_slide, #intro_pc .navigation ul li a:hover, #intro_pc .navigation ul li span.active_slide, #intro_pc .navigation ul li span:hover {
        color: #ab2828;
        font-weight: 700; }
      #intro_pc .navigation ul li a:before, #intro_pc .navigation ul li span:before {
        vertical-align: middle; }
      #intro_pc .navigation ul li a.icon-left:before, #intro_pc .navigation ul li span.icon-left:before {
        content: "\58";
        transition: 0.3s; }
      #intro_pc .navigation ul li a.icon-left.min_left_slide:before, #intro_pc .navigation ul li span.icon-left.min_left_slide:before {
        content: "\73"; }
      #intro_pc .navigation ul li a.icon-filtre, #intro_pc .navigation ul li span.icon-filtre {
        color: rgba(171, 40, 40, 0.7); }

.wrapper_carrousel {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden; }
  .wrapper_carrousel #carrousel {
    position: absolute;
    top: 0;
    left: 0; }
    .wrapper_carrousel #carrousel.low_result {
      left: 50% !important;
      transform: translateX(-50%);
      transition: 0.3s; }

.module_portolio_home {
  /* padding-left: $mb; */
  /* padding-right: $mb; */ }
  .module_portolio_home .portfolio_home.item {
    float: left;
    opacity: 1;
    transform: scale(0.8);
    transition: 0.6s 0.3s; }
    .module_portolio_home .portfolio_home.item .lien_intro {
      text-decoration: none; }
      .module_portolio_home .portfolio_home.item .lien_intro h3 {
        margin: 0px auto 0px;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 0;
        padding-bottom: 0;
        font-family: lato;
        text-decoration: none;
        font-size: 1.6rem;
        letter-spacing: 0.5px;
        color: white;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        line-height: 1;
        opacity: 0.6;
        transition: 0.6s 0.3s;
        font-weight: 400;
        text-transform: uppercase; }
    .module_portolio_home .portfolio_home.item.active_slide {
      transform: scale(1);
      opacity: 1; }
      .module_portolio_home .portfolio_home.item.active_slide h3 {
        opacity: 0.9; }
    .module_portolio_home .portfolio_home.item.low_result_item {
      transform: scale(0.9);
      opacity: 1; }
  .module_portolio_home .img_affiche {
    max-width: 100%;
    height: auto; }

.resultats_slide {
  font-style: italic;
  font-weight: 400;
  font-size: 2rem; }

.valider {
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  right: 1rem; }
  .valider a {
    color: #fff;
    font-size: 3rem;
    text-decoration: none; }

#prix_pc {
  position: relative;
  background-image: url("../../../images/bg/bg65.jpg");
  background-size: cover;
  text-align: center;
  color: #fff; }
  #prix_pc:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7); }

.tableau_prix {
  font-size: 0;
  margin-top: 40px; }
  .tableau_prix > div {
    position: relative;
    float: none;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    font-size: 1.5rem;
    line-height: 1; }
    .tableau_prix > div > div {
      background: rgba(255, 255, 255, 0.9);
      color: #6E6E6E;
      text-align: center; }
      .tableau_prix > div > div .head {
        border-bottom: 1px solid #c8c8c8; }
        .tableau_prix > div > div .head .titre_tab {
          font-size: 2rem;
          line-height: 2rem;
          font-weight: 700;
          text-transform: uppercase;
          display: block;
          background: #333;
          color: #fff;
          padding: 2rem 1rem; }
          .tableau_prix > div > div .head .titre_tab.recommender {
            background: #ab2828;
            padding: 3rem 1rem; }
        .tableau_prix > div > div .head .prix .euros {
          font-size: 2rem;
          line-height: 2rem;
          position: relative;
          top: -2rem; }
        .tableau_prix > div > div .head .prix .mensualite {
          font-size: 5rem;
          font-weight: 700;
          line-height: 5rem;
          display: inline-block;
          margin: 2rem -0px 0px; }
        .tableau_prix > div > div .head .prix .par_mois {
          font-size: 1.5rem;
          line-height: 1.5rem; }
        .tableau_prix > div > div .head .prix .pendant {
          display: block;
          margin-bottom: 2rem;
          font-size: 1.3rem;
          color: #ab2828;
          font-style: italic;
          font-weight: 700;
          text-decoration: none; }
      .tableau_prix > div > div.tab2 {
        top: -2rem;
        margin-bottom: 6rem;
        padding-bottom: 1rem;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        z-index: 1;
        position: relative; }
      .tableau_prix > div > div .tab_detail .tab {
        border-top: 1px solid #c8c8c8;
        padding-top: 3rem;
        margin-top: 3rem;
        margin-bottom: 3rem; }
        .tableau_prix > div > div .tab_detail .tab:first-of-type {
          border-top: none;
          padding-top: 0; }
        .tableau_prix > div > div .tab_detail .tab span {
          display: block;
          text-align: center;
          font-size: 1.3rem;
          line-height: 1.2;
          margin-bottom: 1.5rem;
          text-align: left;
          padding-left: 8%;
          padding-right: 1%; }
          .tableau_prix > div > div .tab_detail .tab span:before {
            vertical-align: middle;
            margin-right: 10px; }
          .tableau_prix > div > div .tab_detail .tab span strong {
            font-weight: 400; }
          .tableau_prix > div > div .tab_detail .tab span.shide {
            opacity: 0; }
          .tableau_prix > div > div .tab_detail .tab span.tab_detail_theme {
            font-weight: 700;
            font-style: italic;
            font-size: 1.7rem; }
            .tableau_prix > div > div .tab_detail .tab span.tab_detail_theme:before {
              font-size: 4rem;
              color: rgba(171, 40, 40, 0.7); }
          .tableau_prix > div > div .tab_detail .tab span a {
            text-decoration: none;
            display: block;
            text-transform: uppercase;
            color: rgba(171, 40, 40, 0.7);
            font-size: 1rem;
            font-weight: 700;
            margin-top: 0.5rem; }
            .tableau_prix > div > div .tab_detail .tab span a:before {
              vertical-align: middle;
              margin-right: 1rem;
              margin-left: 3rem; }
      .tableau_prix > div > div .purchase {
        border-top: 1px solid #c8c8c8;
        margin-top: 4rem;
        display: block;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 700;
        padding: 2rem 1rem;
        color: #fff; }
        .tableau_prix > div > div .purchase a {
          display: inline-block;
          color: #ab2828;
          padding: 1.5rem 2.5rem;
          text-decoration: none; }
          .tableau_prix > div > div .purchase a:before {
            margin-right: 1rem; }

#block_haut {
  height: 100vh;
  color: #fff;
  position: relative; }
  #block_haut .bg_triangle {
    position: absolute;
    z-index: -10;
    right: -105vw;
    top: -105vh;
    width: 200vw;
    height: 200vh;
    transform: rotate(25deg);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
    background-image: linear-gradient(25deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%); }
  #block_haut #text_rotate .text_rotate {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    text-align: left;
    color: #fff; }
    #block_haut #text_rotate .text_rotate p {
      display: inline-block;
      vertical-align: top;
      font-size: 4rem;
      line-height: 1;
      margin: 0;
      padding: 0;
      text-indent: 0; }
      #block_haut #text_rotate .text_rotate p.pre_text_rotate span {
        font-weight: 700;
        font-size: 4.1rem; }
      #block_haut #text_rotate .text_rotate p.text_autre {
        margin-left: 1rem; }
        #block_haut #text_rotate .text_rotate p.text_autre span {
          font-style: italic; }
    #block_haut #text_rotate .text_rotate .word {
      position: absolute;
      font-weight: 700;
      font-size: 6rem;
      opacity: 0; }
      #block_haut #text_rotate .text_rotate .word .letter {
        display: inline-block;
        position: relative;
        float: left;
        white-space: pre;
        transform: translateZ(25px);
        transform-origin: 50% 50% 25px; }
        #block_haut #text_rotate .text_rotate .word .letter.out {
          transform: rotateX(90deg);
          transition: transform 0.42s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
        #block_haut #text_rotate .text_rotate .word .letter.behind {
          transform: rotateX(-90deg); }
        #block_haut #text_rotate .text_rotate .word .letter.in {
          transform: rotateX(0deg);
          transition: transform 0.42s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    #block_haut #text_rotate .text_rotate .performante {
      color: #581515; }
    #block_haut #text_rotate .text_rotate .concurrentielle {
      color: #581515; }
    #block_haut #text_rotate .text_rotate .attirante {
      color: #581515; }
    #block_haut #text_rotate .text_rotate .marquante {
      color: #581515; }
    #block_haut #text_rotate .text_rotate .connue {
      color: #581515; }
  #block_haut #anim_top_home {
    display: block;
    font-size: 4rem;
    line-height: 1; }
    #block_haut #anim_top_home .avion {
      position: relative;
      z-index: -12;
      right: -100%;
      margin-top: 40px;
      animation: avion_top 5s linear 1.5s forwards; }
    #block_haut #anim_top_home .bon_message {
      position: relative;
      z-index: 10;
      right: 15%;
      text-align: right;
      text-transform: uppercase;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      font-style: italic;
      font-size: 3.4rem;
      margin-top: 40px;
      opacity: 0;
      animation: bon_message 2s ease-out 1.9s forwards; }
      #block_haut #anim_top_home .bon_message:before {
        vertical-align: middle;
        margin-right: 15px;
        font-size: 6rem;
        opacity: 0.8; }
      #block_haut #anim_top_home .bon_message span {
        font-weight: 700; }
    #block_haut #anim_top_home .bon_message_detail {
      position: relative;
      right: 0%;
      left: 60%;
      width: 40%;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1); }
      #block_haut #anim_top_home .bon_message_detail p {
        font-weight: 400;
        font-style: italic;
        font-size: 1.9rem;
        line-height: 1.2;
        letter-spacing: 2px;
        margin: 60px 0px;
        padding: 60px 68px;
        text-align: left;
        color: #000; }
        #block_haut #anim_top_home .bon_message_detail p span {
          font-weight: 700; }
          #block_haut #anim_top_home .bon_message_detail p span.centrer {
            display: block;
            text-align: center;
            font-size: 110%; }
    #block_haut #anim_top_home .immeuble {
      position: absolute;
      bottom: 15%;
      left: 0;
      transform: scale(0.95);
      transform-origin: left 50%;
      animation: zoomchill 5s ease-in-out forwards; }
    #block_haut #anim_top_home .pointeur_immeuble {
      position: absolute;
      bottom: 14%;
      left: 10%;
      z-index: 0; }
      #block_haut #anim_top_home .pointeur_immeuble i {
        display: inline-block;
        animation: bounce 2s ease-in-out infinite; }
        #block_haut #anim_top_home .pointeur_immeuble i:before {
          color: #ab2828;
          font-size: 20rem;
          line-height: 1; }
      #block_haut #anim_top_home .pointeur_immeuble:after {
        bottom: -5px;
        left: 24%;
        right: 24%;
        height: 30px;
        transform: scale(1);
        animation: ombre_bounce 2s ease-in-out infinite; }
    #block_haut #anim_top_home .groupe_personne {
      /* & > div > div > div  */ }
      #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes {
        position: absolute; }
        #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne {
          position: relative;
          display: inline-block; }
          #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span:after, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span:after {
            bottom: 4px;
            left: 34%;
            right: 34%;
            height: 20px;
            opacity: 0.7; }
          #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i {
            position: relative; }
            #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i:before, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i:before {
              line-height: 1;
              text-align: bottom;
              display: block; }
            #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-personne, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-personne {
              font-size: 20rem; }
            #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-droite, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-droite {
              font-size: 14rem;
              position: absolute;
              top: -60%;
              opacity: 0.8; }
              #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-gauche .iconlvl2, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-droite .iconlvl2, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-gauche .iconlvl2, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-droite .iconlvl2 {
                position: absolute;
                top: 4rem;
                color: #fff;
                font-size: 5rem; }
            #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-gauche {
              right: -10%; }
              #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-gauche .iconlvl2, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-gauche .iconlvl2 {
                right: 30%; }
            #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-droite, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-droite {
              left: -10%; }
              #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne span i.icon-bulle-droite .iconlvl2, #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne span i.icon-bulle-droite .iconlvl2 {
                left: 30%; }
      #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes {
        bottom: 15%;
        left: 80px;
        z-index: 0; }
        #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne {
          margin-left: -5rem;
          margin-right: -5rem;
          transform: scale(1); }
          #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne:nth-of-type(2) {
            transform: scale(1.1);
            z-index: 2; }
            #block_haut #anim_top_home .groupe_personne .premiere_ranger .personnes .personne:nth-of-type(2) span i.icon-bulle-gauche {
              top: -68%;
              right: 1%; }
      #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes {
        bottom: 17%;
        left: 40px;
        z-index: -1; }
        #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne {
          margin-left: -5rem;
          margin-right: -5rem;
          transform: scale(0.8); }
          #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(2) span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(2) span i.icon-bulle-droite, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(3) span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(3) span i.icon-bulle-droite {
            font-size: 8rem;
            position: absolute;
            top: -40%;
            opacity: 0.8; }
          #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(2) span i.icon-bulle-gauche, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(3) span i.icon-bulle-gauche {
            right: 15%; }
          #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(2) span i.icon-bulle-droite, #block_haut #anim_top_home .groupe_personne .deuxieme_ranger .personnes .personne:nth-of-type(3) span i.icon-bulle-droite {
            left: 15%; }

.titre_home {
  text-transform: uppercase; }
  .titre_home h3 {
    position: relative;
    line-height: 1;
    display: block;
    text-transform: uppercase;
    font-family: lato;
    font-size: 4rem;
    padding-top: 40px;
    padding-bottom: 20px; }
  .titre_home .ico_titre_home {
    position: relative;
    display: inline-block;
    height: 4rem;
    margin-bottom: 20px;
    display: block;
    width: 100%; }
    .titre_home .ico_titre_home:before, .titre_home .ico_titre_home:after {
      content: '';
      position: absolute;
      width: 35%;
      text-align: center;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      height: 2px;
      top: 60%; }
    .titre_home .ico_titre_home.reverse:before, .titre_home .ico_titre_home.reverse:after {
      background: rgba(0, 0, 0, 0.2); }
    .titre_home .ico_titre_home:before {
      left: 3%; }
    .titre_home .ico_titre_home:after {
      right: 3%; }
    .titre_home .ico_titre_home i {
      font-size: 4rem;
      display: inline; }
  .titre_home p {
    margin-bottom: 60px; }
  .titre_home .ico_sociaux a {
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
    font-size: 3rem;
    line-height: 1;
    text-decoration: none;
    margin-bottom: 40px; }
    .titre_home .ico_sociaux a span {
      transition: 0.5s;
      height: 5rem;
      width: 5rem;
      display: block;
      padding: 1rem;
      border-radius: 50%;
      color: #fff; }
      .titre_home .ico_sociaux a span.icon-facebook {
        background-color: #3b5998; }
      .titre_home .ico_sociaux a span.icon-google-plus {
        background-color: #dc4e41; }
    .titre_home .ico_sociaux a:hover span {
      transform: rotateY(360deg);
      background: #fff; }
      .titre_home .ico_sociaux a:hover span.icon-facebook {
        color: #3b5998; }
      .titre_home .ico_sociaux a:hover span.icon-google-plus {
        color: #dc4e41; }

#footer {
  position: relative;
  background-image: url("../../../images/bg/bg18.jpg");
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-bottom: 50px; }
  #footer:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7); }
  #footer .coord_footer {
    position: relative;
    z-index: 10;
    color: rgba(29, 29, 27, 0.7);
    background-color: rgba(255, 255, 255, 0.95);
    padding-top: 80px;
    padding-bottom: 80px; }
    #footer .coord_footer > .gauche {
      position: relative;
      margin-bottom: 2rem; }
      #footer .coord_footer > .gauche:after {
        content: '';
        position: absolute;
        top: 20%;
        bottom: 20%;
        right: 0;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.3); }
    #footer .coord_footer .mail_foot .plain {
      display: block;
      margin-top: -10%; }
      #footer .coord_footer .mail_foot .plain a.icon-news {
        color: #dadada;
        /* color:$tool; */
        text-decoration: none;
        font-size: 20rem;
        display: inline-block;
        vertical-align: bottom;
        display: inline-block;
        transform-origin: top;
        text-shadow: 1px 8px 14px rgba(0, 0, 0, 0.3);
        animation: avion 1s ease-in-out infinite; }
        #footer .coord_footer .mail_foot .plain a.icon-news.aviongo {
          animation: aviongo 10s ease-in-out; }
      #footer .coord_footer .mail_foot .plain #fleche-mail {
        display: inline-block;
        vertical-align: middle;
        width: 300px;
        max-width: 40%; }
        #footer .coord_footer .mail_foot .plain #fleche-mail .st0 {
          opacity: 0.9; }
        #footer .coord_footer .mail_foot .plain #fleche-mail .st1 {
          fill: none;
          stroke: rgba(29, 29, 27, 0.7);
          stroke-miterlimit: 6; }
    #footer .coord_footer .mail_foot span {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 20px;
      display: block; }
    #footer .coord_footer .coord {
      display: inline-block; }
      #footer .coord_footer .coord a {
        display: block;
        text-align: left;
        color: rgba(29, 29, 27, 0.7);
        font-weight: 700;
        font-style: italic;
        font-size: 2rem;
        text-decoration: none;
        padding-bottom: 10px; }
        #footer .coord_footer .coord a i {
          color: #ab2828;
          opacity: 0.7;
          margin-right: 15px;
          font-size: 3rem;
          vertical-align: middle; }
  #footer .menu {
    display: inline-block;
    width: 75%;
    max-width: 400px; }
    #footer .menu ul {
      display: block;
      text-align: left; }
      #footer .menu ul li a {
        color: rgba(29, 29, 27, 0.7);
        display: block;
        font-size: 2rem;
        text-decoration: none;
        padding: 4px;
        transition: 0.2s; }
        #footer .menu ul li a:before {
          margin-right: 10px;
          color: #ab2828;
          opacity: 0.7; }
        #footer .menu ul li a.level2 {
          position: relative;
          margin-left: 2.5rem; }
          #footer .menu ul li a.level2:after {
            content: '';
            position: absolute;
            top: 1rem;
            left: -1.5rem;
            width: 1rem;
            height: 1rem;
            border-bottom: 2px solid #ab2828;
            border-left: 2px solid #ab2828;
            background-color: transparent;
            opacity: 0.7; }
        #footer .menu ul li a:hover {
          opacity: 1;
          font-weight: 700; }
  #footer .remerciement h3, #footer .menu_foot h3 {
    font-family: lato;
    color: rgba(29, 29, 27, 0.7);
    font-weight: 400;
    font-size: 3rem;
    line-height: 1; }
    #footer .remerciement h3:before, #footer .menu_foot h3:before {
      color: #ab2828;
      opacity: 0.7;
      margin-right: 10px; }
    #footer .remerciement h3.icon-liste:before, #footer .menu_foot h3.icon-liste:before {
      font-size: 2rem; }
    #footer .remerciement h3.icon-logo-clq:before, #footer .menu_foot h3.icon-logo-clq:before {
      vertical-align: middle;
      font-size: 4rem; }
  #footer .remerciement {
    margin-bottom: 2rem; }
    #footer .remerciement br {
      line-height: 0.5rem;
      height: 0.5rem;
      display: block;
      content: ''; }
    #footer .remerciement .remerciement_texte {
      display: inline-block;
      width: 75%;
      max-width: 400px; }
      #footer .remerciement .remerciement_texte p {
        font-size: 2rem;
        text-align: left; }
  #footer .plusdinfo {
    position: relative; }
    #footer .plusdinfo a {
      color: #fff;
      display: block;
      font-size: 4rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 60px;
      margin-bottom: 60px;
      text-decoration: none; }
      #footer .plusdinfo a > div {
        display: inline-block;
        vertical-align: middle;
        text-align: left; }
        #footer .plusdinfo a > div span {
          display: block;
          line-height: 1; }
          #footer .plusdinfo a > div span.soustexte {
            font-size: 1.8rem;
            text-transform: initial;
            opacity: 0.6; }
      #footer .plusdinfo a i {
        font-size: 8rem;
        vertical-align: -3rem;
        margin-right: 25px; }
  #footer .copyright {
    position: relative;
    display: block;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
    letter-spacing: 1px; }
    #footer .copyright span i {
      vertical-align: middle; }

.blog_block {
  margin-bottom: 20px; }
  .blog_block .inner {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%; }
    .blog_block .inner .cover {
      position: relative;
      overflow: hidden;
      display: block;
      transition: all 0.3s ease-out; }
      .blog_block .inner .cover.turquoise {
        background-color: #16a085; }
      .blog_block .inner .cover.vert {
        background-color: #27ae60; }
      .blog_block .inner .cover.bleu {
        background-color: #3498DB; }
      .blog_block .inner .cover.violet {
        background-color: #8e44ad; }
      .blog_block .inner .cover.nuit {
        background-color: #2c3e50; }
      .blog_block .inner .cover.jaune {
        background-color: #F39B12; }
      .blog_block .inner .cover.orange {
        background-color: #d35400; }
      .blog_block .inner .cover.rouge {
        background-color: #c0392b; }
      .blog_block .inner .cover.gris1 {
        background-color: #7f8c8d; }
      .blog_block .inner .cover.gris2 {
        background-color: #7f8c8d; }
      .blog_block .inner .cover a {
        display: block; }
        .blog_block .inner .cover a .img_cover {
          width: 100%;
          opacity: 1;
          transition: all 0.3s ease-out;
          transform: scale(1); }
    .blog_block .inner .detail {
      position: relative;
      padding: 40px 30px 30px;
      background-color: #fff;
      border-bottom: 10px solid;
      border-top: 10px solid; }
      .blog_block .inner .detail.turquoise {
        border-color: #16a085; }
      .blog_block .inner .detail.vert {
        border-color: #27ae60; }
      .blog_block .inner .detail.bleu {
        border-color: #3498DB; }
      .blog_block .inner .detail.violet {
        border-color: #8e44ad; }
      .blog_block .inner .detail.nuit {
        border-color: #2c3e50; }
      .blog_block .inner .detail.jaune {
        border-color: #F39B12; }
      .blog_block .inner .detail.orange {
        border-color: #d35400; }
      .blog_block .inner .detail.rouge {
        border-color: #c0392b; }
      .blog_block .inner .detail.gris1 {
        border-color: #7f8c8d; }
      .blog_block .inner .detail.gris2 {
        border-color: #7f8c8d; }
      .blog_block .inner .detail .titre {
        display: block;
        text-decoration: none; }
        .blog_block .inner .detail .titre h3 {
          height: auto;
          overflow: hidden;
          font-size: 2rem;
          line-height: 1.2;
          text-transform: uppercase;
          text-align: center;
          color: #797979;
          padding: 0; }
          @media (min-width: 544px) {
            .blog_block .inner .detail .titre h3 {
              height: 7.4rem; } }
          @media (min-width: 768px) {
            .blog_block .inner .detail .titre h3 {
              height: 9.4rem; } }
      .blog_block .inner .detail .picto {
        position: absolute;
        height: 60px;
        width: 60px;
        margin: 0 auto;
        top: -35px;
        left: 50%;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%); }
        .blog_block .inner .detail .picto.picto_content {
          animation: bulleback .5s; }
        .blog_block .inner .detail .picto.picto_mod {
          animation: bullebackdeux .5s; }
        .blog_block .inner .detail .picto .picto_block {
          position: relative;
          text-align: left; }
          .blog_block .inner .detail .picto .picto_block i {
            display: inline-block;
            padding: 15px;
            font-size: 30px;
            color: #fff;
            line-height: 1; }
          .blog_block .inner .detail .picto .picto_block span {
            font-size: 1.8rem;
            position: absolute;
            width: 100px;
            right: 15px;
            text-align: center;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            line-height: 60px;
            color: #fff;
            text-transform: uppercase;
            opacity: 0;
            font-weight: 700; }
      .blog_block .inner .detail.turquoise .picto {
        background-color: #16a085; }
      .blog_block .inner .detail.vert .picto {
        background-color: #27ae60; }
      .blog_block .inner .detail.bleu .picto {
        background-color: #3498DB; }
      .blog_block .inner .detail.violet .picto {
        background-color: #8e44ad; }
      .blog_block .inner .detail.nuit .picto {
        background-color: #2c3e50; }
      .blog_block .inner .detail.jaune .picto {
        background-color: #F39B12; }
      .blog_block .inner .detail.orange .picto {
        background-color: #d35400; }
      .blog_block .inner .detail.rouge .picto {
        background-color: #c0392b; }
      .blog_block .inner .detail.gris1 .picto {
        background-color: #7f8c8d; }
      .blog_block .inner .detail.gris2 .picto {
        background-color: #7f8c8d; }
      .blog_block .inner .detail .intro {
        height: 0;
        position: relative;
        display: block;
        overflow: hidden;
        opacity: 0;
        line-height: 1.2;
        padding: 0;
        transition: all 0.3s ease-out; }
    .blog_block .inner:hover .cover {
      /*             height: 103px !important; */ }
      .blog_block .inner:hover .cover .img_cover {
        transform: scale(1.2);
        opacity: 0.1;
        transition-delay: .1s;
        transition-duration: .7s; }
    .blog_block .inner:hover .detail .picto.picto_content {
      animation: bulle .6s .25s forwards; }
      .blog_block .inner:hover .detail .picto.picto_content span {
        opacity: 1;
        transition: 0.3s .9s; }
    .blog_block .inner:hover .detail .picto.picto_mod {
      animation: bulledeux .6s forwards; }
      .blog_block .inner:hover .detail .picto.picto_mod span {
        opacity: 1;
        transition: 0.3s .4s; }
    .blog_block .inner:hover .detail .intro {
      /* 
    height:80px; */
      opacity: 1;
      padding-top: 15px;
      transition: all 0.3s ease-out; }

.autre_blog {
  padding-left: 1rem;
  padding-right: 1rem; }

/***********************
************************
****** DEBUT CONTENT */
.content_titre {
  background-color: transparent; }

.content .block_titre {
  padding-top: 9vh;
  padding-bottom: 9vh;
  margin-top: 101px;
  display: flex;
  flex-direction: column; }

@media (min-width: 768px) {
  .content .block_titre {
    margin-top: 151px; } }

.content .block_titre_clients {
  padding-top: 9vh;
  padding-bottom: 2vh;
  margin-top: 101px; }
  .content .block_titre_clients.clients {
    padding-top: 0;
    padding-bottom: 0; }

@media (min-width: 768px) {
  .content .block_titre {
    margin-top: 151px; } }

.content .block_titre {
  position: relative;
  z-index: 10; }
  .content .block_titre .bar-h {
    display: none; }
  .content .block_titre.affiche {
    padding-bottom: 120px; }
    .content .block_titre.affiche .bar-h {
      display: block;
      width: 100%;
      max-width: 450px;
      height: 10px;
      margin-top: 15px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); }

.content.blog .block_titre {
  padding-top: 6vh;
  padding-bottom: 3vh; }

/***********************
************************
****** DEBUT CONTENT */
/***********************
************************
****** DEBUT */
.avant_contexte {
  text-align: center;
  margin-top: 80px; }
  .avant_contexte .type_client {
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: 2px;
    display: inline-block;
    padding: 10px 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25); }
    @media (min-width: 768px) {
      .avant_contexte .type_client {
        font-size: 2.3rem; } }
    .avant_contexte .type_client a {
      color: #fff; }
      .avant_contexte .type_client a:hover {
        text-decoration: none; }

.portfolio_contexte, .blog_contexte {
  padding-right: 2rem;
  padding-left: 2rem; }
  .portfolio_contexte p, .blog_contexte p {
    margin: 30px auto 60px; }

.pix_album {
  position: relative; }
  @media (min-width: 1280px) {
    .pix_album .galerie_photo:nth-of-type(3n+1) .affiche > a, .pix_album .galerie_photo:nth-of-type(3n+1) .affiche.titre_pix {
      transform: rotate(-4deg);
      transform-origin: 100% 100%; }
    .pix_album .galerie_photo:nth-of-type(3n+3) .affiche > a, .pix_album .galerie_photo:nth-of-type(3n+3) .affiche.titre_pix {
      transform: rotate(4deg);
      transform-origin: 0% 100%; } }
  .pix_album .titre_pix {
    margin-bottom: 20px;
    position: relative;
    transition: 0.5s; }
    @media (min-width: 768px) {
      .pix_album .titre_pix {
        margin-bottom: 40px; } }
    .pix_album .titre_pix h2 {
      font-size: 2rem;
      letter-spacing: 1px;
      line-height: 1;
      text-transform: none;
      color: #fff;
      text-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3); }
  .pix_album .wrapper_album {
    position: relative;
    z-index: 0;
    display: inline-block;
    text-align: center;
    margin-bottom: 13.33333px;
    /* &.affiche:nth-of-type(3n+1) > a {
            transform: rotate(-4deg);
            transform-origin: 100% 100%;
        }
        
        &.affiche:nth-of-type(3n+3) > a {
            transform: rotate(4deg);
            transform-origin: 0% 100%;
        }
        
        &:nth-of-type(n+4) {
            margin: 20px 10px;
        }
        
        &:nth-of-type(4) {
            margin-top: 80px;
        } */ }
    .pix_album .wrapper_album.affiche {
      margin: 0; }
      .pix_album .wrapper_album.affiche > a {
        display: block;
        box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.25); }
    .pix_album .wrapper_album.siteweb {
      border: 15px solid #dedede;
      border-radius: 10px 10px 1px 1px;
      margin-left: 40px;
      margin-right: 40px;
      box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.2), 0 -2rem 7rem rgba(0, 0, 0, 0.1); }
      .pix_album .wrapper_album.siteweb:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -8px;
        margin: -2px 0 0 -2px;
        width: 4px;
        height: 4px;
        border-radius: 6px;
        background: #a5adbd; }
      .pix_album .wrapper_album.siteweb:after {
        content: '';
        position: absolute;
        left: -40px;
        right: -40px;
        bottom: -30px;
        background: #ccc;
        height: 15px;
        z-index: 10;
        border-radius: 3px 3px 15px 15px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 -2px 3px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.6); }
      .pix_album .wrapper_album.siteweb a {
        position: relative;
        display: block; }
        .pix_album .wrapper_album.siteweb a:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5); }
      .pix_album .wrapper_album.siteweb .titre_pix {
        font-size: 2.4rem; }

.pix_album .galerie_lien {
  color: #fff; }
  .pix_album .galerie_lien:hover {
    color: #fff;
    text-decoration: none; }

.pix_album .shadow {
  position: absolute;
  width: 16vw;
  height: 40px;
  left: 50%;
  margin-left: -8vw;
  z-index: -1;
  bottom: -20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  background: #000;
  border-radius: 50%;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 ); }

.pix_album .mega_tritre {
  position: relative; }

.pix_album .pix_album_titre p {
  padding-left: 20px;
  padding-right: 20px; }

.pix_album .fontion_anim {
  margin-top: 30px; }
  .pix_album .fontion_anim .restart_anim, .pix_album .fontion_anim .start_anim, .pix_album .fontion_anim .pause_anim {
    color: #fff;
    font-size: 4rem;
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin: 10px 10px 0; }
  .pix_album .fontion_anim .pause_anim {
    opacity: 0.5;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }
    .pix_album .fontion_anim .pause_anim.paused {
      opacity: 1;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }

.pix_album .phone .shadow {
  bottom: -6.025vw;
  left: -50%;
  margin-left: 8.5vw;
  height: 10px;
  width: 17vw; }

.pix_album .tablet .shadow {
  width: 25vw;
  left: -50%;
  height: 60px;
  margin-left: 12.5vw;
  bottom: -3.14136vw; }

.pix_album .phone, .pix_album .tablet, .pix_album .monitor {
  display: block;
  box-shadow: 0px -5px 25px rgba(0, 0, 0, 0.15); }
  .pix_album .phone .content:after, .pix_album .tablet .content:after, .pix_album .monitor .content:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3); }

.pix_album .insetshadow {
  position: absolute;
  box-shadow: inset 0 0px 6px rgba(0, 0, 0, 0.3); }

.pix_album .container_tablet .animm {
  transform: scale(2); }

.pix_album .container_phone .animm {
  transform: scale(2); }

.pix_album .container_pc .animm {
  transform: scale(1.5); }

.pix_album .container_tablet, .pix_album .container_phone, .pix_album .container_pc {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .pix_album .container_tablet, .pix_album .container_phone, .pix_album .container_pc {
      transform: scale(1);
      margin-top: 0;
      margin-bottom: 0; }
      .pix_album .container_tablet .animm, .pix_album .container_phone .animm, .pix_album .container_pc .animm {
        transform: scale(1); } }
  .pix_album .container_tablet span, .pix_album .container_phone span, .pix_album .container_pc span {
    position: absolute;
    bottom: -3rem;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #fff;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    line-height: 1;
    padding: 1rem 1rem 0;
    text-align: center; }
  .pix_album .container_tablet.container_mobile span, .pix_album .container_phone.container_mobile span, .pix_album .container_pc.container_mobile span {
    bottom: -6rem; }

.pix_album .phone {
  box-sizing: content-box;
  z-index: 2;
  margin: 12.75vw auto 12.75vw;
  width: 17vw;
  height: 25.5vw;
  position: relative;
  border-radius: 0.85vw;
  background: #000;
  border-top: 4.25vw solid #e2e2e2;
  border-left: 0.425vw solid #e2e2e2;
  border-right: 0.425vw solid #e2e2e2;
  border-bottom: 5.525vw solid #e2e2e2; }
  @media (min-width: 768px) {
    .pix_album .phone {
      margin-top: 0; } }
  .pix_album .phone:before, .pix_album .phone:after {
    content: "";
    position: absolute;
    left: 50%;
    background: #d6d6d6;
    box-shadow: inset 0 0.085vw 0.17vw rgba(0, 0, 0, 0.3); }
  .pix_album .phone:before {
    width: 5.1vw;
    height: 0.17vw;
    margin-left: -2.55vw;
    top: -1.7vw;
    border-radius: 0.17vw; }
  .pix_album .phone:after {
    width: 2.975vw;
    height: 2.975vw;
    border-radius: 2.975vw;
    bottom: -4.25vw;
    margin-left: -1.7vw; }
  .pix_album .phone .insetshadow {
    margin-top: -4.25vw;
    margin-left: -0.425vw;
    width: 17.85vw;
    height: 35.275vw;
    border-radius: 0.85vw; }
  .pix_album .phone .screen {
    width: 17vw;
    height: 25.5vw;
    position: relative;
    overflow: hidden; }
  .pix_album .phone .content {
    background: #fff;
    width: 17vw;
    height: 25.5vw;
    margin-left: 0;
    background-size: 17vw auto;
    background-repeat: none;
    animation: bgmoveecran linear infinite alternate-reverse;
    animation-duration: 40s;
    animation-play-state: paused; }
    .pix_album .phone .content:hover, .pix_album .phone .content.show_anim {
      animation-play-state: running; }
    .pix_album .phone .content.restart {
      animation: bgtop 0.3s ease-out; }
    .pix_album .phone .content.go_anim {
      animation-play-state: running; }
      .pix_album .phone .content.go_anim:hover {
        animation-play-state: paused; }
    .pix_album .phone .content.pause_anim {
      animation-play-state: paused; }

.pix_album .tablet {
  box-sizing: content-box;
  z-index: 2;
  position: relative;
  margin: 16.32853vw auto;
  width: 25vw;
  height: 32.65707vw;
  background: #fc9252;
  border: 1.57068vw solid #e8ebf0;
  border-radius: 0.65445vw; }
  @media (min-width: 768px) {
    .pix_album .tablet {
      margin-top: 0; } }
  .pix_album .tablet:after {
    content: "";
    position: absolute;
    top: -1.57068vw;
    bottom: -1.57068vw;
    right: -1.57068vw;
    left: -1.57068vw;
    border-radius: 0.65445vw;
    z-index: -1;
    box-shadow: inset 0 0px 0.39267vw rgba(0, 0, 0, 0.3); }
  .pix_album .tablet:before {
    content: "";
    position: absolute;
    left: 50%;
    margin: -0.19634vw 0 -0.19634vw;
    width: 0.39267vw;
    height: 0.39267vw;
    border-radius: 0.39267vw;
    background: #a5adbd;
    top: -0.78534vw;
    box-shadow: inset 0 0.06545vw 0.13089vw rgba(0, 0, 0, 0.3); }
  .pix_album .tablet > div {
    position: absolute; }
  .pix_album .tablet > .content {
    width: 25vw;
    height: 32.65707vw;
    left: 0;
    margin-left: 0;
    background-size: 25vw auto;
    background-repeat: none;
    animation: bgmoveecran linear infinite alternate-reverse;
    animation-duration: 30s;
    animation-play-state: paused; }
    .pix_album .tablet > .content:hover, .pix_album .tablet > .content.show_anim {
      /* animation: bgtop 0.3s ease-out; */
      animation-play-state: running; }
    .pix_album .tablet > .content.restart {
      animation: bgtop 0.3s ease-out; }
    .pix_album .tablet > .content.go_anim {
      animation-play-state: running; }
      .pix_album .tablet > .content.go_anim:hover {
        animation-play-state: paused; }
    .pix_album .tablet > .content.pause_anim {
      animation-play-state: paused; }

.pix_album .monitor {
  box-sizing: content-box;
  z-index: 1;
  margin: 0 auto 19.02vw;
  width: 48vw;
  height: 32.04vw;
  position: relative;
  border: 1.2vw solid #474e5d;
  border-radius: 4px; }
  @media (min-width: 768px) {
    .pix_album .monitor {
      margin-top: 0;
      border-radius: 10px; } }
  .pix_album .monitor > div {
    position: absolute; }
  .pix_album .monitor:before, .pix_album .monitor:after {
    content: "";
    position: absolute;
    left: 50%;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); }
  .pix_album .monitor:before {
    top: -0.6vw;
    margin: -0.18vw 0 -0.18vw;
    width: 0.36vw;
    height: 0.36vw;
    border-radius: 0.36vw;
    background: #a5adbd; }
  .pix_album .monitor:after {
    width: 0.36vw;
    height: 0.36vw;
    border-radius: 0.36vw;
    bottom: -0.6vw;
    margin: 0 -0.36vw;
    background: #e8ebf0; }
  .pix_album .monitor .content {
    width: 48vw;
    height: 32.04vw;
    left: 50%;
    margin-left: -24vw;
    overflow: hidden;
    background-size: 48vw auto;
    background-repeat: no-repeat;
    animation: bgmoveecran linear infinite alternate-reverse;
    animation-duration: 20s;
    animation-play-state: paused; }
    .pix_album .monitor .content:hover, .pix_album .monitor .content.show_anim {
      /* animation: bgtop 0.3s ease-out; */
      animation-play-state: running; }
    .pix_album .monitor .content.restart {
      animation: bgtop 0.3s ease-out; }
    .pix_album .monitor .content.go_anim {
      animation-play-state: running; }
      .pix_album .monitor .content.go_anim:hover {
        animation-play-state: paused; }
    .pix_album .monitor .content.pause_anim {
      animation-play-state: paused; }
    .pix_album .monitor .content:before {
      content: "";
      position: absolute;
      right: -90px;
      width: 4vw;
      height: 48.06vw;
      transform: rotate(45deg);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 5; }
    .pix_album .monitor .content.restart {
      animation: bgtop 0.3s ease-out; }
  .pix_album .monitor .base {
    background-image: url("../../../images/stand-pc.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -1;
    opacity: 1;
    bottom: -10.5272vw;
    height: 9.4272vw;
    width: 15.6vw;
    left: 50%;
    margin-left: -7.8vw;
    z-index: -10; }
    .pix_album .monitor .base:after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 10px;
      width: 48vw;
      left: -16.2vw;
      height: 40px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      background: #000;
      border-radius: 50%;
      background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 ); }
  .pix_album .monitor .foot {
    background: #e0e0e0;
    z-index: 1; }
  .pix_album .monitor .foot.top {
    width: 116px;
    height: 5px;
    bottom: -5px;
    left: 50%;
    margin-left: -58px; }
    .pix_album .monitor .foot.top:before {
      border-left: 16px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 5px solid #e8ebf0;
      left: -16px; }
    .pix_album .monitor .foot.top:after {
      border-right: 16px solid transparent;
      border-left: 0px solid transparent;
      border-bottom: 5px solid #e8ebf0;
      right: -16px; }
  .pix_album .monitor .foot.top:before,
  .pix_album .monitor .foot.top:after,
  .pix_album .monitor .foot.bottom:before {
    content: "";
    position: absolute;
    top: 0px; }
  .pix_album .monitor .foot.bottom {
    width: 150px;
    height: 5px;
    bottom: -10px;
    left: 50%;
    margin-left: -75px;
    z-index: 999; }

.container_flyer {
  position: relative;
  z-index: 10;
  font-size: 0;
  margin-top: 60px;
  margin-bottom: 60px; }
  .container_flyer .shadow {
    position: absolute;
    width: 120%;
    height: 20px;
    left: 0%;
    margin-left: -10%;
    border-radius: 45%;
    z-index: -1;
    bottom: -60px;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=1 );
    opacity: 0.6;
    transition: 0.6s ease-in-out; }
  .container_flyer.deux_pages .inner {
    transform: scale(0.8) translateX(-10%) translateY(30%) rotateX(30deg) rotateY(-30deg) rotateZ(-50deg);
    perspective: 800px;
    perspective-origin: bottom;
    margin-left: auto;
    margin-right: auto; }
    .container_flyer.deux_pages .inner .page {
      max-width: 45%;
      transition: 0.6s 0.2s ease-in-out;
      position: relative;
      transform-style: preserve-3d; }
      .container_flyer.deux_pages .inner .page .recto {
        position: relative; }
      .container_flyer.deux_pages .inner .page .verso {
        position: absolute;
        margin: 0;
        display: block;
        backface-visibility: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scaleX(-1) translateZ(-0.1px); }
  .container_flyer.trois_pages .inner {
    transform: scale(0.8) translateX(0%) translateY(0%) rotateX(50deg) rotateY(-30deg) rotateZ(0deg);
    perspective: 800px;
    perspective-origin: bottom;
    margin-left: auto;
    margin-right: auto; }
    .container_flyer.trois_pages .inner .page {
      max-width: 30%;
      transition: 0.6s ease-in-out;
      position: relative;
      transform-style: preserve-3d; }
      .container_flyer.trois_pages .inner .page .recto {
        position: relative; }
      .container_flyer.trois_pages .inner .page .verso {
        position: absolute;
        margin: 0;
        display: block;
        backface-visibility: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scaleX(-1) translateZ(-0.1px); }
  .container_flyer.une_page .inner {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
    position: relative; }
    .container_flyer.une_page .inner .page {
      transform-style: preserve-3d;
      transform: scale(0.8) translateX(0%) translateY(20%) rotateX(70deg) rotateY(0deg) rotateZ(-20deg);
      perspective: 800px;
      perspective-origin: bottom;
      transition: 1s cubic-bezier(0.64, 0.55, 0.4, 1.47);
      z-index: 1; }
      .container_flyer.une_page .inner .page:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        box-shadow: inset 80px 0px 160px black;
        opacity: 0.3;
        transition: 1s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
      .container_flyer.une_page .inner .page:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        box-shadow: inset -80px 0px 160px white;
        opacity: 0.5;
        transition: 1s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
    .container_flyer.une_page .inner:hover .page, .container_flyer.une_page .inner.opened .page {
      transform: scale(1) translateX(0%) translateY(-6%) rotateX(-6deg) rotateY(0deg) rotateZ(0deg); }
    .container_flyer.une_page .inner:hover > .page, .container_flyer.une_page .inner.opened > .page {
      transition: 1.4s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
      .container_flyer.une_page .inner:hover > .page:before, .container_flyer.une_page .inner.opened > .page:before {
        opacity: 0.1;
        transition: 1.6s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
      .container_flyer.une_page .inner:hover > .page:after, .container_flyer.une_page .inner.opened > .page:after {
        opacity: 0.1;
        transition: 1.6s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
    .container_flyer.une_page .inner:hover .shadow, .container_flyer.une_page .inner.opened .shadow {
      width: 360px;
      height: 30px;
      left: 50%;
      margin-left: -180px;
      bottom: -70px;
      opacity: 0.7;
      transition: 1s cubic-bezier(0.64, 0.55, 0.4, 1.47); }
  .container_flyer.affiche {
    margin-top: 30px;
    margin-bottom: 60px;
    perspective-origin: top;
    transform-style: preserve-3d;
    perspective: 1000px; }
    .container_flyer.affiche .inner {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
      position: relative;
      transform-style: preserve-3d;
      transform: rotateY(0deg);
      transform-origin: 50% -113px;
      transition: 0.6s ease-in-out; }
      .container_flyer.affiche .inner .shadow {
        width: 120%;
        left: 0;
        margin-left: -10%;
        bottom: -60px;
        opacity: 0.5; }
      .container_flyer.affiche .inner .page {
        z-index: 1; }
        .container_flyer.affiche .inner .page:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: inset 80px 0px 160px black;
          opacity: 0.1; }
        .container_flyer.affiche .inner .page:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: inset -80px 0px 160px white;
          opacity: 0.2; }
        .container_flyer.affiche .inner .page .clip {
          display: block; }
          .container_flyer.affiche .inner .page .clip:before, .container_flyer.affiche .inner .page .clip:after {
            content: url("../../../images/clip.png");
            position: absolute;
            top: -113px;
            width: 31px;
            height: 123px;
            z-index: 1; }
          .container_flyer.affiche .inner .page .clip:before {
            left: 50px; }
          .container_flyer.affiche .inner .page .clip:after {
            right: 50px; }
      .container_flyer.affiche .inner:hover, .container_flyer.affiche .inner.opened {
        animation: affichemove 2s ease-out forwards; }
        .container_flyer.affiche .inner:hover .page:before, .container_flyer.affiche .inner.opened .page:before {
          animation: affichemoveshadow 0.4s alternate ease-in-out forwards;
          animation-iteration-count: 4; }
        .container_flyer.affiche .inner:hover .page:after, .container_flyer.affiche .inner.opened .page:after {
          animation: affichemoveshadow 0.4s alternate-reverse ease-in-out forwards;
          animation-iteration-count: 4; }
        .container_flyer.affiche .inner:hover + .shadow, .container_flyer.affiche .inner.opened + .shadow {
          width: 300px;
          height: 30px;
          left: 50%;
          margin-left: -150px;
          bottom: -40px;
          opacity: 0.4;
          transition: .6s ease-out; }
  .container_flyer.trois_pages .inner, .container_flyer.deux_pages .inner {
    text-align: center;
    transform-style: preserve-3d;
    transition: 0.6s ease-in-out; }
    .container_flyer.trois_pages .inner div.page, .container_flyer.deux_pages .inner div.page {
      display: inline-block;
      position: relative;
      z-index: 1; }
      .container_flyer.trois_pages .inner div.page:nth-of-type(1), .container_flyer.deux_pages .inner div.page:nth-of-type(1) {
        transform: rotateY(164deg);
        transform-origin: right 50%; }
      .container_flyer.trois_pages .inner div.page:nth-of-type(1):after, .container_flyer.trois_pages .inner div.page:nth-of-type(2):after, .container_flyer.trois_pages .inner div.page:nth-of-type(3):after, .container_flyer.deux_pages .inner div.page:nth-of-type(1):after, .container_flyer.deux_pages .inner div.page:nth-of-type(2):after, .container_flyer.deux_pages .inner div.page:nth-of-type(3):after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        box-shadow: inset 80px 0px 160px white;
        opacity: 0.7;
        transition: 0.6s ease-in-out; }
      .container_flyer.trois_pages .inner div.page:nth-of-type(1):after, .container_flyer.deux_pages .inner div.page:nth-of-type(1):after {
        box-shadow: inset -80px 0px 160px black;
        opacity: 0.7;
        transition: 0.6s ease-in-out; }
      .container_flyer.trois_pages .inner div.page:nth-of-type(2):after, .container_flyer.trois_pages .inner div.page:nth-of-type(3):after, .container_flyer.deux_pages .inner div.page:nth-of-type(2):after, .container_flyer.deux_pages .inner div.page:nth-of-type(3):after {
        opacity: 1; }
      .container_flyer.trois_pages .inner div.page:nth-of-type(3), .container_flyer.deux_pages .inner div.page:nth-of-type(3) {
        transform: rotateY(164deg);
        transform-origin: left 50%; }
    .container_flyer.trois_pages .inner:hover, .container_flyer.trois_pages .inner.opened, .container_flyer.deux_pages .inner:hover, .container_flyer.deux_pages .inner.opened {
      transform: scale(1) translateX(0%) translateY(0%) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      transition: 1s ease-out; }
      .container_flyer.trois_pages .inner:hover > .page, .container_flyer.trois_pages .inner.opened > .page, .container_flyer.deux_pages .inner:hover > .page, .container_flyer.deux_pages .inner.opened > .page {
        transition: 1.2s 0.5s ease-in-out; }
        .container_flyer.trois_pages .inner:hover > .page:nth-of-type(1), .container_flyer.trois_pages .inner.opened > .page:nth-of-type(1), .container_flyer.deux_pages .inner:hover > .page:nth-of-type(1), .container_flyer.deux_pages .inner.opened > .page:nth-of-type(1) {
          transform: scaleX(1) rotateY(0deg); }
          .container_flyer.trois_pages .inner:hover > .page:nth-of-type(1):after, .container_flyer.trois_pages .inner.opened > .page:nth-of-type(1):after, .container_flyer.deux_pages .inner:hover > .page:nth-of-type(1):after, .container_flyer.deux_pages .inner.opened > .page:nth-of-type(1):after {
            opacity: 0.1;
            transition: 1.2s ease-in-out; }
        .container_flyer.trois_pages .inner:hover > .page:nth-of-type(2):after, .container_flyer.trois_pages .inner:hover > .page:nth-of-type(3):after, .container_flyer.trois_pages .inner.opened > .page:nth-of-type(2):after, .container_flyer.trois_pages .inner.opened > .page:nth-of-type(3):after, .container_flyer.deux_pages .inner:hover > .page:nth-of-type(2):after, .container_flyer.deux_pages .inner:hover > .page:nth-of-type(3):after, .container_flyer.deux_pages .inner.opened > .page:nth-of-type(2):after, .container_flyer.deux_pages .inner.opened > .page:nth-of-type(3):after {
          opacity: 0.2;
          transition: 1.2s 0.5s ease-in-out; }
        .container_flyer.trois_pages .inner:hover > .page:nth-of-type(3), .container_flyer.trois_pages .inner.opened > .page:nth-of-type(3), .container_flyer.deux_pages .inner:hover > .page:nth-of-type(3), .container_flyer.deux_pages .inner.opened > .page:nth-of-type(3) {
          transform: scaleX(1) rotateY(0deg); }
      .container_flyer.trois_pages .inner:hover + .shadow, .container_flyer.trois_pages .inner.opened + .shadow, .container_flyer.deux_pages .inner:hover + .shadow, .container_flyer.deux_pages .inner.opened + .shadow {
        width: 300px;
        height: 30px;
        left: 50%;
        margin-left: -150px;
        bottom: -40px;
        opacity: 0.4;
        transition: .6s ease-out; }
  .container_flyer.une_page_rv, .container_flyer.cartevisite_1_page_rv {
    perspective: 1000px;
    /* &:hover, */ }
    .container_flyer.une_page_rv .control a, .container_flyer.cartevisite_1_page_rv .control a {
      font-size: 4rem;
      color: #fff;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      display: inline-block;
      margin: 10px 10px 0;
      background: #95a5a6;
      padding: 1rem 2rem;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3); }
      .container_flyer.une_page_rv .control a:before, .container_flyer.cartevisite_1_page_rv .control a:before {
        vertical-align: middle;
        margin-right: 1rem; }
      .container_flyer.une_page_rv .control a span, .container_flyer.cartevisite_1_page_rv .control a span {
        display: block;
        font-size: 46%;
        line-height: 1; }
    .container_flyer.une_page_rv .inner, .container_flyer.cartevisite_1_page_rv .inner {
      text-align: center;
      margin: 30px auto 30px;
      transition: 0.6s;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      position: relative;
      transform-origin: 50% 100%;
      max-width: 90%;
      transform: scale(0.85) rotateY(360deg) rotateX(-60deg);
      transition: .6s ease-out;
      /* &:before {
                content: '';
                position: absolute;
                top: 108%;
                bottom: -10%;
                right: 20%;
                left: 20%;
                box-shadow: inset 0px 0px 40px rgba(0,0,0,0.1);
                transform: rotateX(90deg) translateY(50%) rotateY(0deg);
                transform-origin: 50% 100%;
                border-radius: 50%;
            } */ }
      .container_flyer.une_page_rv .inner .shadow, .container_flyer.cartevisite_1_page_rv .inner .shadow {
        width: 40%;
        margin-left: 35%;
        bottom: -50px;
        height: 10px;
        opacity: 0.3; }
      .container_flyer.une_page_rv .inner .front, .container_flyer.une_page_rv .inner .back, .container_flyer.cartevisite_1_page_rv .inner .front, .container_flyer.cartevisite_1_page_rv .inner .back {
        backface-visibility: hidden;
        top: 0;
        left: 0; }
        .container_flyer.une_page_rv .inner .front:before, .container_flyer.une_page_rv .inner .back:before, .container_flyer.cartevisite_1_page_rv .inner .front:before, .container_flyer.cartevisite_1_page_rv .inner .back:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: inset -80px 0px 160px black;
          opacity: 0.1; }
        .container_flyer.une_page_rv .inner .front:after, .container_flyer.une_page_rv .inner .back:after, .container_flyer.cartevisite_1_page_rv .inner .front:after, .container_flyer.cartevisite_1_page_rv .inner .back:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: inset 80px 0px 160px white;
          opacity: 0.1; }
      .container_flyer.une_page_rv .inner .front, .container_flyer.cartevisite_1_page_rv .inner .front {
        z-index: 2;
        transform: rotateY(0deg); }
      .container_flyer.une_page_rv .inner .back, .container_flyer.cartevisite_1_page_rv .inner .back {
        position: absolute;
        transform: rotateY(180deg); }
    .container_flyer.une_page_rv.inview .inner, .container_flyer.cartevisite_1_page_rv.inview .inner {
      transform: scale(1) rotateY(0deg) rotateX(0deg); }
    .container_flyer.une_page_rv.opened .inner, .container_flyer.cartevisite_1_page_rv.opened .inner {
      transform: scale(1) rotateY(180deg) rotateX(0deg); }

#sommaire {
  max-width: 300px;
  position: relative;
  height: 50px;
  overflow-y: hidden; }
  #sommaire ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    /* bottom: 0; */
    left: 30px;
    transition: 0.3s; }
    #sommaire ul li a {
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-family: lato;
      text-decoration: none;
      font-size: 1.5rem;
      color: #6E6E6E;
      text-transform: none;
      transition: 0.3s; }
      #sommaire ul li a i {
        vertical-align: middle;
        margin-right: 10px; }
    #sommaire ul li.cible a {
      color: #ab2828;
      background-color: transparent; }
      #sommaire ul li.cible a i:before {
        content: '\48'; }
    #sommaire ul li:not(.cible) a {
      font-weight: 900;
      background-color: #fff;
      box-shadow: inset 0 0px 0.5px rgba(0, 0, 0, 0.8); }
    #sommaire ul li:not(.cible):hover a {
      font-weight: 900;
      color: #ab2828; }
    #sommaire ul.deployed li.cible a {
      background-color: #ab2828;
      color: #fff; }

.clients .inner {
  padding: 5px; }
  .clients .inner a {
    display: block;
    text-align: center;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59); }
    .clients .inner a img {
      width: 100%; }
    .clients .inner a:before {
      content: '';
      width: 120%;
      height: 210%;
      position: absolute;
      top: 90%;
      left: -110%;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.9s;
      transition: all 0.9s; }
    .clients .inner a:hover:before, .clients .inner a:focus:before {
      top: -54%;
      left: -10%; }
    .clients .inner a h3 {
      display: block;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: -100%;
      font-size: 2rem;
      padding: 20px;
      background-color: #fff;
      transform: translateY(-50%);
      -webkit-transition: all 1s;
      transition: all 1s; }
    .clients .inner a:hover h3, .clients .inner a:focus h3 {
      left: 0; }
  .clients .inner.turquoise a:before {
    background-color: #1abc9c; }
  .clients .inner.vert a:before {
    background-color: #2ecc71; }
  .clients .inner.bleu a:before {
    background-color: #75C0D7; }
  .clients .inner.violet a:before {
    background-color: #9b59b6; }
  .clients .inner.nuit a:before {
    background-color: #34495e; }
  .clients .inner.jaune a:before {
    background-color: #F1B20F; }
  .clients .inner.orange a:before {
    background-color: #e67e22; }
  .clients .inner.rouge a:before {
    background-color: #e74c3c; }
  .clients .inner.gris1 a:before {
    background-color: #95a5a6; }
  .clients .inner.gris2 a:before {
    background-color: #95a5a6; }
  .clients .inner.turquoise a {
    color: #1abc9c; }
  .clients .inner.vert a {
    color: #2ecc71; }
  .clients .inner.bleu a {
    color: #75C0D7; }
  .clients .inner.violet a {
    color: #9b59b6; }
  .clients .inner.nuit a {
    color: #34495e; }
  .clients .inner.jaune a {
    color: #F1B20F; }
  .clients .inner.orange a {
    color: #e67e22; }
  .clients .inner.rouge a {
    color: #e74c3c; }
  .clients .inner.gris1 a {
    color: #95a5a6; }
  .clients .inner.gris2 a {
    color: #95a5a6; }

* {
  box-sizing: border-box; }

html {
  margin: 0;
  height: 100%; }

body {
  position: relative;
  height: 100%;
  font-family: 'lato', sans-serif;
  font-weight: 400;
  overflow-x: hidden; }

a, a:hover, a:focus, a:link, a:visited {
  outline: 0;
  color: inherit;
  text-decoration: none; }

.container-fluid.no_padding {
  padding-left: 0;
  padding-right: 0; }

.row.no_margin, .no_margin.item_list_wrapper, .no_margin#system.intro.blog, .no_margin#system.intro.portfolio, .no_margin#system.intro.clients {
  margin-left: 0;
  margin-right: 0; }

.row > div.no_padding, .item_list_wrapper > div.no_padding, #system.intro.blog > div.no_padding, #system.intro.portfolio > div.no_padding, #system.intro.clients > div.no_padding {
  padding-left: 0;
  padding-right: 0; }

.pt_menu {
  padding-top: 104px; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; }

.max-width {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }

.relative {
  position: relative; }

.shadow_container {
  margin-bottom: 3rem; }

.shadow {
  opacity: 0.8;
  position: absolute;
  width: 60%;
  height: 40px;
  left: 40%;
  margin-left: -20%;
  z-index: -1;
  bottom: -3rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  background: #000;
  border-radius: 50%;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 ); }

a.back-to-top {
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #323534;
  font-size: 2rem;
  z-index: 6000; }

.scroll-line {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3003;
  height: 6px;
  margin-bottom: 0px;
  width: 0%;
  border-radius: 30px;
  background: #ab2828; }

#system-message-container {
  font-size: 2rem;
  letter-spacing: 1px;
  margin-top: 104px;
  margin-bottom: 104px; }
  #system-message-container dt {
    margin-bottom: 1rem;
    font-size: 160%; }

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

.filtres {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  overflow-y: hidden;
  text-align: left;
  text-align: center; }
  @media (min-width: 768px) {
    .filtres {
      position: relative;
      z-index: 0;
      bottom: initial;
      left: initial;
      right: initial; } }
  .filtres .buttonn-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative; }
    .filtres .buttonn-group::-webkit-scrollbar {
      width: 8px;
      padding-left: 10px;
      padding-right: 10px; }
    .filtres .buttonn-group::-webkit-scrollbar-thumb {
      border: 7px solid transparent;
      background: #ab2828;
      background-clip: content-box;
      min-height: 100px;
      border-radius: 10px; }
    .filtres .buttonn-group::-webkit-scrollbar-track {
      background: transparent; }
    @media (min-width: 544px) {
      .filtres .buttonn-group {
        justify-content: center; } }
    .filtres .buttonn-group .buttonn {
      white-space: nowrap;
      text-transform: capitalize;
      font-size: 1.6rem;
      border-radius: 2rem;
      padding: 0.5rem 0.5rem;
      margin: 1rem 0.5rem;
      color: #424242;
      transition: all 0.3s ease-out; }
      @media (min-width: 768px) {
        .filtres .buttonn-group .buttonn {
          font-size: 1.6rem; } }
      @media (min-width: 1280px) {
        .filtres .buttonn-group .buttonn {
          font-size: 2rem; } }
      .filtres .buttonn-group .buttonn.is-checked, .filtres .buttonn-group .buttonn.active {
        color: #fff;
        background-color: #323534;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }
      .filtres .buttonn-group .buttonn.disabled {
        color: #fff;
        background-color: #cacaca; }
      .filtres .buttonn-group .buttonn span {
        font-size: 80%; }
