/***********************
************************
****** DEBUT ANIMATIONS */


////////////SVG CLIQUEZ LA
@keyframes lettre_com {
    from {transform: translateX(-40px) rotate(-90deg)}
    to {transform: translateX(0px) rotate(0deg);}
}
@keyframes lettre_un {
    from {transform: rotateX(-360deg);}
    to {transform: rotateX(0deg)}
}
@keyframes lettre_com_opacity {
    from {fill-opacity: 0;}
    50% {fill-opacity: 0;}
    to {fill-opacity: 1;}
}

@keyframes dash {
    from {
        stroke-dashoffset: 400;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes gradopacity {
    from {
        fill-opacity: 0;
    }

    70% {
        fill-opacity: 0;
    }

    to {
        fill-opacity: 1;
    }
}


@keyframes avion {
    from {transform: rotateZ(0deg) rotateY(0deg) translateY(0px);  }
    50% {transform: rotateZ(1deg) rotateY(1deg) translateY(1px);text-shadow:1px 9px 13px rgba(0,0,0,0.3);  }
    to   {transform: rotateZ(0deg) rotateY(0deg) translateY(0px); }
}
@keyframes aviongo {
    from {transform: rotateZ(0deg) rotateY(0deg) translateY(0%) translateX(0%); opacity:1;text-shadow:1px 8px 14px rgba(0,0,0,0.3);  } 
    7% {    transform: rotateZ(35deg) rotateY(0deg) translateY(0px); opacity:1;  }    
    40%   {transform: rotateZ(35deg) rotateY(0deg) translateY(0px) translateX(2000px) translateY(-1600px); opacity:1; }
    55%   {opacity:0;}
    80%   {transform: rotateZ(0deg) rotateY(0deg) translateY(0%) translateX(0%);opacity:0;}
    to {opacity:1;}
}


/////////////ACCUEIL
@keyframes rotateshow {
    from { transform: rotateY(-10deg) rotateX(15deg); }
    40% { transform: rotateY(10deg) rotateX(15deg); }
    80% { transform: rotateY(0deg) rotateX(0deg); }
    to { transform: rotateY(0deg) rotateX(0deg); }
}

@keyframes piedpc {
    from { opacity:0;bottom: 0; }
    98% { opacity:0;}
    to { opacity:1;bottom: - $himgpiedpecran; }
}

@keyframes avion_top {
    from { right: -50%; }
    to { right: 100%; }
}
@keyframes bon_message {
    from { right: -50%;opacity: 0; }
    to { right: 15%;opacity: 1; }
}
@keyframes zoomchill {
    from { transform: scale(0.95); }
    to { transform: scale(1); }
}
@keyframes ombre_bounce {
    0%, 20%, 53%, 80%, 100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: scale(1);
        opacity: 1;
    }
    40%, 43% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    40%, 43%, 70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    70% {
        transform: scale(0.8);
        opacity: 0.7;
    }
    90% {
        transform: scale(1);
        opacity: 1;
    }
}

//Portfolio

@keyframes affiche {
    from {transform: perspective(300px) rotateX(0deg);}
    30% {transform: perspective(300px) rotateX(-4deg); }
    50% {transform: perspective(300px) rotateX(3deg); }
    70% {transform: perspective(300px) rotateX(-2deg); }
    90% {transform: perspective(300px) rotateX(1deg); }
    to {transform: perspective(300px) rotateX(0deg); }
}

/* .intro-portfolio.item.portfolio, */.intro-clients.item,.blog_block.intro-blog,.blog_block.autre_blog{
     animation: fadeInUp 1s reverse forwards; 
    &.inview{
        &:nth-of-type(3n+3){
            animation: fadeInRight 1s forwards;
        }
        &:nth-of-type(3n+2){
            animation: fadeInDown 1s forwards;
        }
        &:nth-of-type(3n+1){
            animation: fadeInLeft 1s forwards;
        }
    }
}
.pix_album .galerie_photo{
     animation: fadeInUp 1s reverse forwards; 
    &.inview{
        &:nth-of-type(3n+3){
            animation: fadeInRight 1s forwards;
        }
        &:nth-of-type(3n+2){
            animation: fadeInDown 1s forwards;
        }
        &:nth-of-type(3n+1){
            animation: fadeInLeft 1s forwards;
        }
    }
}

/* .intro-portfolio.intro {
animation: fadeInUp 1s reverse forwards;
    &.inview{
        &:nth-of-type(3n+3){
            animation: fadeInRight 1s forwards;
        }
        &:nth-of-type(3n+2){
            animation: fadeInDown 1s forwards;
        }
        &:nth-of-type(3n+1){
            animation: fadeInLeft 1s forwards;
        }
    }
    
}*/
/* .pix_album > div.container_pc,.pix_album > div.container_phone,.pix_album > div.container_tablet{
     animation: fadeInUp 1s reverse forwards; 
    &.inview{
        &.container_tablet{
            animation: fadeInRight 1s forwards;
        }
        &.container_pc{
            animation: fadeInDown 1s forwards;
        }
        &.container_phone{
            animation: fadeInLeft 1s forwards;
        }
    }
} */

.cover_client,.video-container{
     animation: bounceInLeft 1s reverse forwards; 
    &.inview{
        animation: bounceInRight 1s forwards;
    }
}


/***********************
************************
****** FIN ANIMATIONS */

@keyframes bgmove {
    from {
        background-position: 50% 110%;
    }

    to {
        background-position: 50% 0%;
    }
}

@keyframes bgmoveecran {
    from {
        background-position: 50% 100%;
    }

    to {
        background-position: 50% 0%;
    }
}

@keyframes bgmoveafter {
    from {
        background-color: rgba(0, 0, 0, 1);
    }

    15% {
        background-color: rgba(0, 0, 0, 0.3);
    }

    85% {
        background-color: rgba(0, 0, 0, 0.3);
    }

    to {
        background-color: rgba(0, 0, 0, 1);
    }
}

@keyframes bgtop {
    from {
        background-position: 50% 10%;
    }

    to {
        background-position: 50% 0%;
    }
}

@keyframes bgimgportolio {
    from {
        background-image: url("../../../images/bg/bg22.jpg");
    }

    24.9% {
        background-image: url("../../../images/bg/bg22.jpg");
    }

    25% {
        background-image: url("../../../images/bg/bg59.jpg");
    }

    49.9% {
        background-image: url("../../../images/bg/bg59.jpg");
    }

    50% {
        background-image: url("../../../images/bg/bg55.jpg");
    }

    74.9% {
        background-image: url("../../../images/bg/bg55.jpg");
    }

    75% {
        background-image: url("../../../images/bg/bg69.jpg");
    }

    to {
        background-image: url("../../../images/bg/bg69.jpg");
    }
}

@keyframes bgimgblog {
    from {
        background-image: url("../../../images/bg/bg26.jpg");
    }

    24.9% {
        background-image: url("../../../images/bg/bg26.jpg");
    }

    25% {
        background-image: url("../../../images/bg/bg35.jpg");
    }

    49.9% {
        background-image: url("../../../images/bg/bg35.jpg");
    }

    50% {
        background-image: url("../../../images/bg/bg60.jpg");
    }

    74.9% {
        background-image: url("../../../images/bg/bg60.jpg");
    }

    75% {
        background-image: url("../../../images/bg/bg63.jpg");
    }

    to {
        background-image: url("../../../images/bg/bg63.jpg");
    }
}




    @keyframes affichemove {
        from {transform: rotateY(0deg);}
        20% {transform: rotateY(-4deg);}
        40% {transform: rotateY(3deg);}
        60% {transform: rotateY(-2deg);}
        80% {transform: rotateY(1deg);}
        to {transform: rotateY(0deg);}
    }
    @keyframes affichemoveshadow {
        from{opacity:0.3}
        to  {opacity:0.1}
    }

    @keyframes anim_carte{
    from{transform: scale(1) rotateY(0deg);}
    50%{transform: scale(0.95) rotateY(180deg);}
    to{transform: scale(1) rotateY(360deg);}
}

@keyframes anim_carte_shadow_light{
    from{opacity: 0.1;}
    50%{opacity: 0.3;}
    to{opacity: 0.1;}
}
@keyframes anim_carte_shadow_dark{
    from{opacity: 0.1;}
    50%{opacity: 0.3;}
    to{opacity: 0.1;}
}

@keyframes anim_carte_back{
    from{transform: scale(1) rotateY(90deg);}
    to{transform: scale(1) rotateY(0deg);}
}


@keyframes gogo {
    0% {
        opacity: 0;
        transform: translate3d(-50%, -100%, 0);
    }

    100% {
        opacity: 1;
       transform:translate3d(-50%, 0%, 0);
    }
}


@keyframes bulle {
    from {
        top: -35px;
        width: 60px;
        box-shadow: $ombre_bulle_avant;
    }

    70% {
        top: -100px;
        width: 60px;
        border-radius: 50%;
        box-shadow: $ombre_bulle_apres;
    }

    to {
        top: -100px;
        width: 160px;
        border-radius: 40px;
        box-shadow: $ombre_bulle_apres;
    }
}

@keyframes bulleback {
    from {
        top: -100px;
        width: 160px;
        border-radius: 40px;
        box-shadow: $ombre_bulle_apres;
    }

    to {
        top: -35px;
        width: 60px;
        box-shadow: $ombre_bulle_avant;
    }
}

@keyframes bulledeux {
    from {
        width: 60px;
        box-shadow: $ombre_bulle_avant;
    }

    to {
        width: 160px;
        border-radius: 40px;
        box-shadow: $ombre_bulle_apres;
    }
}

@keyframes bullebackdeux {
    from {
        width: 160px;
        border-radius: 40px;
        box-shadow: $ombre_bulle_apres;
    }

    to {
        width: 60px;
        box-shadow: $ombre_bulle_avant;
    }
}
