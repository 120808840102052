.titre_home{
    text-transform: uppercase;
    h3{ 
        position: relative;
        line-height: 1;
        display: block;
        text-transform: uppercase;
        font-family: lato;
        font-size: 4rem;
        padding-top: $mb;
        padding-bottom: $mb/2;
    }
    .ico_titre_home{
        position: relative;
        display: inline-block;
        height: 4rem;
        margin-bottom: $mb/2;
        display: block;
        width: 100%;
        &:before,&:after{
            content: '';
            position: absolute;
            width: 35%;
            text-align: center;
            background: rgba(255,255,255,0.7);
            position: absolute;
            height: 2px;
            top: 60%;
        }
        &.reverse{
            &:before,&:after{
                background: rgba(0,0,0,0.2);
            }
        }
        &:before{
            left: 3%;
        }
        &:after{
            right: 3%;
        }
        i{
            font-size: 4rem;
            display: inline;
        }
    }
    p{
        @extend .bold;
        margin-bottom: $mb * 1.5;
    }
    .ico_sociaux{
        a{
            display: inline-block;
            vertical-align: middle;
            margin: 10px;
            font-size: 3rem;
            line-height: 1;
            text-decoration: none;
            margin-bottom: $mb;
            span {
                transition: 0.5s;
                height: 5rem;
                width: 5rem;
                display: block;
                padding: 1rem;
                border-radius: 50%;
                color: #fff;
                &.icon-facebook{
                    background-color: #3b5998;
                }
                &.icon-google-plus{
                    background-color: #dc4e41;
                }
            }
            &:hover{
                span{
                    transform:rotateY(360deg);
                    background: #fff;                    
                    &.icon-facebook{
                        color: #3b5998;
                    }
                    &.icon-google-plus{
                        color: #dc4e41;
                    }
                }
            }
        }
    }
}
#footer{
    //margin-top: $mb;
    position: relative;
    background-image: url('../../../images/bg/bg18.jpg');
    background-size: cover;
    text-align: center;
    color: #fff;
    padding-bottom: $menu2;
    &:before{
        content:'';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.7);
    }

    
    .coord_footer{
        position: relative;
        z-index: 10;
        color: rgba(29,29,27,0.7);
        background-color: rgba(255,255,255,0.95);
        padding-top: $mb*2;
        padding-bottom: $mb*2;
        > div {}
        > .gauche{
            position: relative;
            margin-bottom: $gutter;
            &:after{
                content:'';
                position: absolute;
                top: 20%;
                bottom: 20%;
                right: 0;
                width: 1px;
                background-color: rgba(0,0,0,0.3);
            }
        }
        .mail_foot{
            .plain{
                display: block;    
                margin-top: -10%;
                a.icon-news{
                    color:#dadada;
                    /* color:$tool; */
                    text-decoration: none;
                    font-size: 20rem;                    
                    display: inline-block;
                    vertical-align: bottom;                    
                    display: inline-block;    
                    transform-origin: top;
                    text-shadow:1px 8px 14px rgba(0,0,0,0.3);
                    animation: avion 1s ease-in-out infinite;
                    &.aviongo{
                        animation: aviongo 10s ease-in-out;
                    }
                }
                #fleche-mail{
                    display: inline-block;
                    vertical-align: middle;
                    width: 300px;
                    max-width: 40%;
                    .st0{
                        opacity:0.9;
                    }        
                    .st1{                
                        fill:none;
                        stroke: rgba(29,29,27,0.7);;
                        stroke-miterlimit:6;
                    }
                }
            }
            span{
                font-weight: 700;
                font-size: 2rem;
                margin-bottom: $mb/2;
                display: block;
            }
        }
        .coord{
            display: inline-block;
            a{
                display: block;
                text-align: left;
                color: rgba(29,29,27,0.7);
                font-weight: 700;
                font-style: italic;
                font-size: 2rem;
                text-decoration: none;
                padding-bottom: 10px;
                i{
                    color:$tool;
                    opacity: 0.7;
                    margin-right: 15px;
                    font-size: 3rem;
                    vertical-align: middle;

                }
            }
        }
    }
    .menu{
        display: inline-block;
        width: 75%;
        max-width: 400px;
        ul{
            display: block;
            text-align: left;
            li{
                a{   
                    color: rgba(29,29,27,0.7);             
                    display: block;
                    font-size: 2rem;
                    @extend .bold;
                    text-decoration: none;
                    padding: 4px;
                    transition:0.2s;
                    &:before{
                        margin-right: 10px; 
                        color:$tool;
                        opacity: 0.7;
                    }   
                    &.level2{
                        position: relative;
                        margin-left: 2.5rem;
                        &:after{
                            content: '';
                            position: absolute;
                            top: 1rem;
                            left: -1.5rem;
                            width: 1rem;
                            height: 1rem;
                            border-bottom: 2px solid #ab2828;
                            border-left: 2px solid #ab2828;
                            background-color: transparent;
                            opacity: 0.7;
                        }
                    }
                    &:hover{
                        opacity: 1;
                        font-weight: 700;
                    }
                }
            }
        }

    }
    .remerciement,.menu_foot{
        h3{           
            font-family: lato; 
            color: rgba(29, 29, 27, 0.7);
            font-weight: 400;
            font-size: 3rem;
            line-height: 1;
            &:before{
                color: #ab2828;
                opacity: 0.7;
                margin-right: 10px;
            }
            &.icon-liste:before{
                font-size: 2rem;
            }
            &.icon-logo-clq:before{
                vertical-align: middle;
                font-size: 4rem;
            }
        }
    }
    .remerciement{
        margin-bottom: 2rem;
        br{ 
            line-height: 0.5rem;
            height: 0.5rem;
            display: block;
            content: '';
        }
        .remerciement_texte{
            display: inline-block;
            width: 75%;
            max-width: 400px;
            p{
                font-size: 2rem;
                text-align: left;

            }
        }
    }
    .plusdinfo{
        position: relative;
        a {
            color: #fff;
            display: block;
            font-size: 4rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: 60px;
            margin-bottom: 60px;
            text-decoration: none;
            > div{
                display: inline-block;
                vertical-align: middle;
                text-align: left;                
                span{
                    display: block;
                    line-height: 1;
                    &.soustexte{
                        font-size: 1.8rem;
                        text-transform: initial;
                        opacity: 0.6;
                    }
                }
            }
            i{
                font-size: 8rem;
                vertical-align: -3rem;
                margin-right: 25px;
            }
        }
    }
    .copyright{
        position: relative;
        display: block;
        margin-bottom: 30px;
        color: rgba(255,255,255,0.7);
        font-size: 1.2rem;
        letter-spacing: 1px;
        span{

            i{
                vertical-align: middle;
            }
        }
    }
}
