/***********************
************************
****** DEBUT COMMON */

a, a:hover, a:focus {
    outline: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

*::selection {
    background: #cc0000;
    color: #fff;
}

*::-moz-selection {
    background: #cc0000;
    color: #fff;
}

.no-gutters {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}

.no-gutters-span {
    [class^="col-"],
    [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

html {
    height: 100%;
    font-size: 8px;
    //@media #{$sm} {font-size: 8px;}
    @media #{$md} {font-size: 8.5px;}
    @media #{$lg} {font-size: 9px;}
    @media #{$xl} {font-size: 9.5px;}
    @media #{$xxl}{font-size: 10px;}
}

body {
    height: 100%;
    color: $text;
    background-color: #ececec;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 12px;
    padding-left: 2px;
    padding-right: 2px;
}
::-webkit-scrollbar-thumb { 
    border: 2px solid transparent;
    background: $tool;
    background-clip: content-box;
    min-height: 100px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */ 
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
    background: #fff;
}
.component {
    padding-bottom: $mb;
    img {
        max-width: 100%;
        height: auto;
    }
}
.flex-xs-middle,.flex-items-xs-center{
    display: flex;
    align-items: center;
    justify-content: center;    
    margin-left: auto;
    margin-right: auto;
}
.ma{
    margin-right: auto;
    margin-left: auto;
}

.hidden-sm-down{
    display: none;
    @media #{$sm}{
        display: block;
    }
}
.hidden-md-down {
    display: none;
    @media #{$md}{
        display: block;
    }
}
.hidden-md-up {
    display: block;
    @media #{$md}{
        display: none;
    }
}

.hidden-menu-switch-down {
    display: none;
    @media #{$menu_switch}{
        display: block;
    }
}
.hidden-menu-switch-up {
    display: block;
    @media #{$menu_switch}{
        display: none;
    }
}

/* .item_list_wrapper {
    @extend .row, .flex-items-xs-center;
    padding-left:  2%;
    padding-right: 2%;
    @media #{$sm} {
        padding-left:  3%;
        padding-right: 3%;
        > div{
            display: flex;
        }
    }
    @media #{$md} {
        padding-left:  4%;
        padding-right: 4%;
    }
    @media #{$lg} {
        padding-left:  5%;
        padding-right: 5%;
    }
    @media #{$xl} {
        padding-left:  6%;
        padding-right: 6%;
    }
    @media #{$xxl} {
        padding-left:  7%;
        padding-right: 7%;
    }
} */

.item_list_wrapper {
    @extend .row, .flex-items-xs-center;

    margin-left: auto;
    margin-right: auto;
    width: 98%;

    >div {
        display: flex;
        padding-right: 2rem;
        padding-left: 2rem;
        //margin-bottom: 2rem * 2;
    }

    @media #{$sm} {
        width: 94%;
    }
    /* @media #{$md} {
        width: 92%;
    } */
    /* @media #{$lg} {
        width: 90%;
    } */
    /* @media #{$xl} {
        width: 88%;
    } */
    @media #{$xxl} {
        width: 90%;
    }
}

#system{
    &.intro.blog,&.intro.portfolio,&.intro.clients{
        @extend .row, .flex-items-xs-center;
        padding-left:  2rem;
        padding-right: 2rem;
        @media #{$sm} {
            padding-left:  3rem;
            padding-right: 3rem;
            > div{
                display: flex;
            }
        }
        @media #{$md} {
          padding-left:  4rem;
          padding-right: 4rem;
        }
        @media #{$lg} {
          padding-left:  5rem;
          padding-right: 5rem;
        }
        @media #{$xl} {
          padding-left:  6rem;
          padding-right: 6rem;
        }
        @media #{$xxl} {
          padding-left:  7rem;
          padding-right: 7rem;
        }
    }
}

#autres_realisations_portfolio,#autres_tutos,.pix_album,.item.clients{
    padding-left:  2rem;
    padding-right: 2rem;
    @media #{$sm} {
      padding-left:  3rem;
      padding-right: 3rem;
    }
    @media #{$md} {
      padding-left:  4rem;
      padding-right: 4rem;
    }
    @media #{$lg} {
      padding-left:  5rem;
      padding-right: 5rem;
    }
    @media #{$xl} {
      padding-left:  6rem;
      padding-right: 6rem;
    }
    @media #{$xxl} {
      padding-left:  7rem;
      padding-right: 7rem;
    }
}
.block_titre_showcase,.item.portfolio{
    padding-left:  2rem;
    padding-right: 2rem;
    @media #{$sm} {
      padding-left:  1rem;
      padding-right: 1rem;
    }
    @media #{$md} {
      padding-left:  2rem;
      padding-right: 2rem;
    }
}

h1, h2, h3, h4, h5, a {
    text-decoration: none;
}

ul {
  list-style: none;
}

.actions{
    display: none;
}

.btn.jmodedit{
    display: none;
}
.cck_button_save_view,.cck_button_cancel{
    position: fixed !important;
    z-index: 10000;
    bottom: -33px;
    right: 120px;    
}
.cck_button_save_view{
    right: 30px;
}
/* 
.shadow{
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -5px;
        left: 15%;
        right: 15%;
        height: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
        background: #000;
        border-radius: 50%;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
    }
} */

/***********************
************************
****** FIN COMMON */


/***********************
************************
****** DEBUT TAGS */

.tags {
    padding-bottom: 50px;    
    &.intro.portfolio{
        padding-bottom: 0;
    }
    .tags-links {
        .tag-link {    
            text-transform: uppercase;
            font-weight: 900;
            font-size: 1.3rem;
            line-height: 1;
            letter-spacing: 1px;
            display: inline-block;
            padding: 1rem 1rem;
            margin-right: 0.6rem;
            margin-bottom: 0.6rem;
            background: #fff;
            box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.25);
            transition: .5s;
            @media #{$lg}{
                font-size: 1.4rem;
                letter-spacing: 2px;
                padding: 1rem 1.6rem;
                margin-right: 1rem;
                margin-bottom: 1rem;
                box-shadow: 0px 1px 0.5rem rgba(0, 0, 0, 0.25);
                transition: .5s;
            }

            &.hover {
                text-decoration: none;
            }
        }
    }
}

/***********************
************************
****** FIN TAGS */


.cck_module_breadcrumbs {
    a, span {
        position: relative;
        padding: 10px 15px;
        line-height: 20px;
        display: inline-block;
    }

    a {
        color: #777;
    }

    span {
        &.pathway-last {
            color: $rougelink;
            overflow-x: hidden;
            display: inline;
        }

        &.separator {
            padding: 10px 0px;
        }
    }
}


.arrow-left {
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-right: 100px solid blue;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
}

.svg-triangle {
    margin: 0 auto;
}

.svg-triangle polygon {
    fill: #98d02e;

    filer {
        opacity: 0.1;
    }
}











.clear {
    display: block;
    clear: both;
    text-align: center;
}

.cck_page{
    .megatitre.admin_titre{
        margin: 200px 0 60px;
        display: block;
    }
}


.video-container {
    position: relative;
    overflow: hidden;

    & iframe, & object, & embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.iframe {
        padding-top: 56.25%;
    }
}

.video-js {
    padding-top: 56.25% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border-radius: 0 27px 0px 0;
    overflow: hidden;
}

.vjs-fullscreen {
    padding-top: 0px !important;
}

.share_twitter {
    background-color: #0087BA;
}

.share_facebook {
    background-color: #2D4373;
}

.share_gplus {
    background-color: #C23321;
}

#cboxPrevious, #cboxTitle, #cboxNext, #cboxClose {
    font-size: 2.5rem;
    color: #3a3a3a;
}

#cboxPrevious, #cboxTitle, #cboxNext {
    padding: 20px;
}

#cboxPrevious {
    left: 30px;
}

#cboxNext {
    right: 30px;
    left: initial;
}

#cboxTitle {
    font-family: lato;
    @extend .bold;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.4);
}

#cboxCurrent {
    left: 47px;
}


/***********************
************************
****** DEBUT dégradé */

.noir {
    color: #333;
}

.turquoise {
    color: $turquoise2;
}

.vert {
    color: $vert2;
}

.bleu {
    color: $bleu2;
}

.violet {
    color: $violet2;
}

.nuit {
    color: $nuit2;
}

.jaune {
    color: $jaune2;
}

.orange {
    color: $orange2;
}

.rouge {
    color: $rouge2;
}

.gris {
    color: $gris2;
}

.ctext {
    color: $text !important;
}

.blanc {
    color: $blanc !important;
}

.bnoir {
    background-color: #333;
}
.bturquoise {
    background-color: $turquoise2;
}

.bvert {
    background-color: $vert2;
}

.bbleu {
    background-color: $bleu2;
}

.bviolet {
    background-color: $violet2;
}

.bnuit {
    background-color: $nuit2;
}

.bjaune {
    background-color: $jaune2;
}

.borange {
    background-color: $orange2;
}

.brouge {
    background-color: $rouge2;
}

.bgris {
    background-color: $gris2;
}

.bctext {
    background-color: $text !important;
}

.bblanc {
    background-color: $blanc !important;
}
.btool{
    background-color: $tool;
}
.bbleuciel{
    background-color: $bleuciel;
}

.gnoir {
    background-image: linear-gradient(#333, #222);
}

.gturquoise {
    background-image: linear-gradient($turquoise2, $turquoise1);
}

.gvert {
    background-image: linear-gradient($vert2, $vert1);
}

.gbleu {
    background-image: linear-gradient($bleu2, $bleu1);
}

.gviolet {
    background-image: linear-gradient($violet2, $violet1);
}

.gnuit {
    background-image: linear-gradient($nuit2, $nuit1);
}

.gjaune {
    background-image: linear-gradient($jaune2, $jaune1);
}

.gorange {
    background-image: linear-gradient($orange2, $orange1);
}

.grouge {
    background-image: linear-gradient($rouge2, $rouge1);
}

.ggris {
    background-image: linear-gradient($gris2, $gris1);
}
.gtool {
    background-image: linear-gradient(darken($tool, 10%), $tool);
}

.rturquoise {
    background-image: radial-gradient(closest-side at 45px 45px, $turquoise2 10px, $turquoise1 500px);
}

.rvert {
    background-image: radial-gradient(closest-side at 45px 45px, $vert2 10px, $vert1 500px);
}

.rbleu {
    background-image: radial-gradient(closest-side at 45px 45px, $bleu2 10px, $bleu1 500px);
}

.rviolet {
    background-image: radial-gradient(closest-side at 45px 45px, $violet2 10px, $violet1 500px);
}

.rnuit {
    background-image: radial-gradient(closest-side at 45px 45px, $nuit2 10px, $nuit1 500px);
}

.rjaune {
    background-image: radial-gradient(closest-side at 45px 45px, $jaune2 10px, $jaune1 500px);
}

.rorange {
    background-image: radial-gradient(closest-side at 45px 45px, $orange2 10px, $orange1 500px);
}

.rrouge {
    background-image: radial-gradient(closest-side at 45px 45px, $rouge2 10px, $rouge1 500px);
}

.rgris {
    background-image: radial-gradient(closest-side at 45px 45px, $gris2 10px, $gris1 500px);
}
.rtool {
    background-image: radial-gradient(closest-side at 45px 45px, lighten($tool, 10%) 10px, $tool 500px);
}
.rtool {
    background-image: radial-gradient(closest-side at 45px 45px, lighten($tool, 10%) 10px, $tool 500px);
}
.rtrick{
background-image: radial-gradient(ellipse at center,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dffffff', endColorstr='#00ffffff',GradientType=1 );
}
.gtrick{
    background-image: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#4dffffff',GradientType=0 );
}
.diagtrick{
background-image: linear-gradient(45deg,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=1 );
} 

/***********************
************************
****** FIN dégradé */