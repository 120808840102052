
.blog_block {
    margin-bottom: $mb / 2;

    .inner {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        width: 100%;

        .cover {
            position: relative;
            overflow: hidden;
            display: block;
            transition: $temps_anime;

            &.turquoise {
                background-color: $turquoise1;
            }

            &.vert {
                background-color: $vert1;
            }

            &.bleu {
                background-color: $bleu1;
            }

            &.violet {
                background-color: $violet1;
            }

            &.nuit {
                background-color: $nuit1;
            }

            &.jaune {
                background-color: $jaune1;
            }

            &.orange {
                background-color: $orange1;
            }

            &.rouge {
                background-color: $rouge1;
            }

            &.gris1 {
                background-color: $gris1;
            }

            &.gris2 {
                background-color: $gris1;
            }

            a {
                display: block;

                .img_cover {
                    width: 100%;
                    opacity: 1;
                    transition: $temps_anime;
                    transform: scale(1);
                }
            }
        }

        .detail {
            position: relative;
            padding: 40px 30px 30px;
            background-color: #fff;
            border-bottom: 10px solid;
            border-top: 10px solid;

            &.turquoise {
                border-color: $turquoise1;
            }

            &.vert {
                border-color: $vert1;
            }

            &.bleu {
                border-color: $bleu1;
            }

            &.violet {
                border-color: $violet1;
            }

            &.nuit {
                border-color: $nuit1;
            }

            &.jaune {
                border-color: $jaune1;
            }

            &.orange {
                border-color: $orange1;
            }

            &.rouge {
                border-color: $rouge1;
            }

            &.gris1 {
                border-color: $gris1;
            }

            &.gris2 {
                border-color: $gris1;
            }

            .titre {
                display: block;
                text-decoration: none;

                h3 {
                    height: auto;
                    overflow: hidden;
                    font-size: 2rem;
                    line-height: 1.2;
                    text-transform: uppercase;
                    text-align: center;
                    color: #797979;
                    padding: 0;
                    @media #{$sm} {
                        height: 7.4rem;                        
                    }
                    @media #{$md} {
                        height: 9.4rem;
                    }
                    @media #{$lg} {}
                    @media #{$xl} {}
                    @media #{$xxl} {}
                }
            }

            .picto {
                position: absolute;
                height: 60px;
                width: 60px;
                margin: 0 auto;
                top: -35px;
                left: 50%;
                border-radius: 50%;
                box-shadow: $ombre_bulle_avant;
                transform: translateX(-50%);

                &.picto_content {
                    animation: bulleback .5s;
                }

                &.picto_mod {
                    animation: bullebackdeux .5s;
                }

                .picto_block {
                    position: relative;
                    text-align: left;

                    i {
                        display: inline-block;
                        padding: 15px;
                        font-size: 30px;
                        color: #fff;
                        line-height: 1;
                    }

                    span {
                        font-size: 1.8rem;
                        position: absolute;
                        width: 100px;
                        right: 15px;
                        text-align: center;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);
                        line-height: 60px;
                        color: #fff;
                        text-transform: uppercase;
                        opacity: 0;
                        font-weight: 700;
                    }
                }
            }

            &.turquoise .picto {
                background-color: $turquoise1;
            }

            &.vert .picto {
                background-color: $vert1;
            }

            &.bleu .picto {
                background-color: $bleu1;
            }

            &.violet .picto {
                background-color: $violet1;
            }

            &.nuit .picto {
                background-color: $nuit1;
            }

            &.jaune .picto {
                background-color: $jaune1;
            }

            &.orange .picto {
                background-color: $orange1;
            }

            &.rouge .picto {
                background-color: $rouge1;
            }

            &.gris1 .picto {
                background-color: $gris1;
            }

            &.gris2 .picto {
                background-color: $gris1;
            }

            .intro {
                height: 0;
                position: relative;
                display: block;
                overflow: hidden;
                opacity: 0;
                line-height: 1.2;
                padding: 0;
                transition: $temps_anime;
            }
        }

        &:hover .cover {
            /*             height: 103px !important; */
            .img_cover {
                transform: scale(1.2);
                opacity: 0.1;
                transition-delay: .1s;
                transition-duration: .7s;
            }
        }

        &:hover .detail {
            .picto {
                &.picto_content {
                    animation: bulle .6s .25s forwards;

                    span {
                        opacity: 1;
                        transition: 0.3s .9s;
                    }
                }

                &.picto_mod {
                    animation: bulledeux .6s forwards;

                    span {
                        opacity: 1;
                        transition: 0.3s .4s;
                    }
                }
            }

            .intro {
       /* 
    height:80px; */
                opacity: 1;
                padding-top: 15px;
                transition: $temps_anime;
            }
        }
    }
}


.autre_blog{
    padding-left: 1rem;
    padding-right: 1rem;
}

