
.clients {
    .inner {
        padding: 5px;

        a {
            display: block;
            text-align: center;
            position: relative;
            overflow: hidden;
            -webkit-transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
            transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);

            img {
                width: 100%;
            }

            &:before {
                content: '';
                width: 120%;
                height: 210%;
                position: absolute;
                top: 90%;
                left: -110%;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transition: all 0.9s;
                transition: all 0.9s;
            }

            &:hover:before, &:focus:before {
                top: -54%;
                left: -10%;
            }

            h3 {
                display: block;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 50%;
                left: -100%;
                font-size: 2rem;
                padding: 20px;
                background-color: #fff;
                transform: translateY(-50%);
                -webkit-transition: all 1s;
                transition: all 1s;
            }

            &:hover h3, &:focus h3 {
                left: 0;
            }
        }

        &.turquoise a:before {
            background-color: $turquoise2;
        }

        &.vert a:before {
            background-color: $vert2;
        }

        &.bleu a:before {
            background-color: $bleu2;
        }

        &.violet a:before {
            background-color: $violet2;
        }

        &.nuit a:before {
            background-color: $nuit2;
        }

        &.jaune a:before {
            background-color: $jaune2;
        }

        &.orange a:before {
            background-color: $orange2;
        }

        &.rouge a:before {
            background-color: $rouge2;
        }

        &.gris1 a:before {
            background-color: $gris2;
        }

        &.gris2 a:before {
            background-color: $gris2;
        }

        &.turquoise a {
            color: $turquoise2;
        }

        &.vert a {
            color: $vert2;
        }

        &.bleu a {
            color: $bleu2;
        }

        &.violet a {
            color: $violet2;
        }

        &.nuit a {
            color: $nuit2;
        }

        &.jaune a {
            color: $jaune2;
        }

        &.orange a {
            color: $orange2;
        }

        &.rouge a {
            color: $rouge2;
        }

        &.gris1 a {
            color: $gris2;
        }

        &.gris2 a {
            color: $gris2;
        }
    }
}


.item.intro-clients{
    //padding-left:  2rem;
    //padding-right: 2rem;
}