@font-face {
    font-family: placard;
    src: url(../fonts/placard-condensed-regular-webfont.eot);
    src: url(../fonts/placard-condensed-regular-webfont.eot?#iefix) format('embedded-opentype'), url(../fonts/placard-condensed-regular-webfont.woff) format('woff'), url(../fonts/placard-condensed-regular-webfont.ttf) format('truetype'), url(../fonts/placard-condensed-regular-webfont.svg#placard_condensedregular) format('svg');
    font-weight: 400;
    font-style: normal;
} 

@mixin placard {
  font-family: 'placard', sans-serif;
  font-weight: 400;
}
@mixin lato {
  font-family: 'lato', sans-serif;
  font-weight: 400;
}
@mixin lato_italic {
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin lato_bold {
  font-family: 'lato', sans-serif;
  font-weight: 900;
}

.placard{@include placard;}
.lato{@include lato;}
.italic{@include lato_italic;}
.bold{@include lato_bold;}

.caps{text-transform: uppercase;}
.tal{text-align: left;}
.tac{text-align: center;}
.tar{text-align: right;}

.txt_bg{padding: 6px;}

.message_system{
  position: fixed;
  z-index: 20000;
  bottom: 30px;
  left: 0;
  background: $toolw;
  .message_system_wrapper{
    padding: 20px 30px;
    font-size: 1.2rem;
    text-transform: uppercase;
    span{
      &:before{
        margin-right: 1.2rem;
      }
    }
  }
}


p {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 0;
  text-align: center;
  a{
    font-weight: 700;
    transition: color 0.3s;
  }
  @media #{$sm}{
    font-size: 1.8rem;
  }
  @media #{$md}{
    text-align: center;
    font-size: 2rem;
  }
}
p + p {
  text-indent: 2rem;
  margin-top: 0;
}
h1 {
  @include heading-font;
  text-transform: uppercase;
  font-size: 3.5rem;
  line-height: 3.5rem + 0.2rem;
  margin: 0;
  padding: 1.45833rem 0;
} 
h2 {
  @include heading-font;
  text-transform: uppercase;
  font-size:2.33333rem;
  line-height: 2.33333rem + 0.2rem;
  margin: 0;
  padding: 1.25rem 0; 
}
h3 {
  @include heading-font;
  font-size: 1.16667rem;
  line-height: 1.16667rem + 0.2rem;
  margin: 0;
  padding: 1.51786rem 0;
}
h4 {
  @include heading-font;
  font-size: 1.1rem;
  line-height: 1.1rem + 0.2rem;
  margin: 0;
  padding: 1.21786rem 0;
}

//@charset "UTF-8";

@font-face {
  font-family: "myfont";
  src:url("../fonts/myfont.eot");
  src:url("../fonts/myfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/myfont.woff") format("woff"),
    url("../fonts/myfont.ttf") format("truetype"),
    url("../fonts/myfont.svg#myfont") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "myfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "myfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-astuces:before {
  content: "\62";
}
.icon-comm:before {
  content: "\63";
}
.icon-info:before {
  content: "\64";
}
.icon-news:before {
  content: "\65";
}
.icon-site-web:before {
  content: "\66";
}
.icon-voir:before {
  content: "\67";
}
.icon-webmarketing:before {
  content: "\68";
}
.icon-facebook:before {
  content: "\6b";
}
.icon-google:before {
  content: "\6c";
}
.icon-linkedin:before {
  content: "\6d";
}
.icon-quote-right:before {
  content: "\61";
}
.icon-quote-left:before {
  content: "\6e";
}
.icon-hashtag:before {
  content: "\6f";
}
.icon-viadeo:before {
  content: "\70";
}
.icon-attache-case:before {
  content: "\71";
}
.icon-arrow-up:before {
  content: "\72";
}
.icon-close:before {
  content: "\73";
}
.icon-arrow-left:before {
  content: "\74";
}
.icon-arrow-right:before {
  content: "\75";
}
.icon-refresh:before {
  content: "\76";
}
.icon-play:before {
  content: "\77";
}
.icon-pause:before {
  content: "\78";
}
.icon-envelope-o:before {
  content: "\6a";
}
.icon-search:before {
  content: "\79";
}
.icon-delete:before {
  content: "\41";
}
.icon-edit:before {
  content: "\42";
}
.icon-calendar:before {
  content: "\7a";
}
.icon-minus:before {
  content: "\43";
}
.icon-plus:before {
  content: "\44";
}
.icon-circle:before {
  content: "\46";
}
.icon-liste:before {
  content: "\48";
}
.icon-coffee:before {
  content: "\47";
}
.icon-portail:before {
  content: "\69";
}
.icon-clients:before {
  content: "\49";
}
.icon-home:before {
  content: "\4a";
}
.icon-logo-clq:before {
  content: "\45";
}
.icon-home-1:before {
  content: "\4b";
}
.icon-google-plus:before {
  content: "\4c";
}
.icon-infos:before {
  content: "\4d";
}
.icon-phone:before {
  content: "\4f";
}
.icon-like:before {
  content: "\50";
}
.icon-copyright:before {
  content: "\4e";
}
.icon-ecommerce:before {
  content: "\51";
}
.icon-application:before {
  content: "\52";
}
.icon-vitrine:before {
  content: "\53";
}
.icon-check:before {
  content: "\54";
}
.icon-img-thumb:before {
  content: "\55";
}
.icon-map:before {
  content: "\56";
}
.icon-burger:before {
  content: "\57";
}
.icon-left:before {
  content: "\58";
}
.icon-right:before {
  content: "\59";
}
.icon-user:before {
  content: "\5a";
}
.icon-likew:before {
  content: "\30";
}
.icon-shop:before {
  content: "\31";
}
.icon-link:before {
  content: "\32";
}
.icon-share:before {
  content: "\33";
}
.icon-wifi:before {
  content: "\34";
}
.icon-graph:before {
  content: "\35";
}
.icon-eur:before {
  content: "\36";
}
.icon-filtre:before {
  content: "\37";
}
.icon-filtre-1:before {
  content: "\38";
}
.icon-circle-1:before {
  content: "\39";
}
.icon-diagram:before {
  content: "\21";
}
.icon-network:before {
  content: "\22";
}
.icon-package:before {
  content: "\23";
}
.icon-targeting:before {
  content: "\24";
}
.icon-down-right:before {
  content: "\25";
}
.icon-bulle-droite:before {
  content: "\26";
}
.icon-bulle-gauche:before {
  content: "\27";
}
.icon-map-pin:before {
  content: "\28";
}
.icon-personne:before {
  content: "\29";
}
.icon-flag:before {
  content: "\2a";
}
.icon-left-arrow:before {
  content: "\2b";
}
.icon-right-arrow:before {
  content: "\2c";
}
