.center_titre {
    display: block;
    text-align: center;
}

/***********************
************************
****** DEBUT MEGATIRE */


.megatitre {
    position: relative;
    text-align: center;
    display: inline-block;
    padding: 0 !important;
    margin: 0;
    font-size: 4rem;
    line-height: 1.2;
    color: $blanc;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
	@media #{$md} {
		font-size: 5rem;
	}
}

/***********************
************************
****** FIN search_titre */
.search_titre {
    height: 349px;
    background-size: auto 110%;   
	.block_titre{
        margin-top: $menu1;
    }
	@media #{$sm} {
		height: 400px;
	}
	@media #{$md} {
		height: 600px;
	}
	@media #{$lg} {
		height: 750px;
		.block_titre{				
    		margin-top: $menu1 + $menu2;
		}
	}
	@media #{$xl} {  
	}
	@media #{$xxl} {
		background-size: 100% 110%;  
	}

}

.search_titre {
    position: relative;
    //height: 70vh; 
    background-repeat: no-repeat;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: $mb;
    .block_titre {
        //padding: 220px 0 120px;
        //padding-top: 20vh;
        //padding-bottom: 20vh;
        @extend .flex-xs-middle;
        position: relative;
        z-index: 10;
        &.rechercher{
        }
        &.accueil{
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.42);
        animation: bgmoveafter 5s linear infinite alternate-reverse;
    }

    &.blog {
        //@extend .rorange;
    }

    .megatitre {
        svg {
            width: 100%;

            text {
                font-size: 0.65rem;
            }
        }

        > span:not(:last-of-type) {
            position: relative;
            font-size: 3rem;
            font-weight: 700;
            font-family: lato;
            letter-spacing: 4px;
            padding-bottom: 7px;
            display: block;
            background: rgba(255, 255, 255, 0.05);
            @media #{$sm} {
                font-size: 7rem;                
            }
            @media #{$md} {
                font-size: 9rem;                
            }
            @media #{$lg} {  
                font-weight: 900;              
            }
            @media #{$xl} {                
            }
            @media #{$xxl} {               
            }

            &:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                left: -30px;
                top: -30px;
                background: transparent;
                border-left: 4px solid #fff;
                border-top: 4px solid #fff;
            }

            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                right: -30px;
                top: -30px;
                background: transparent;
                border-right: 4px solid #fff;
                border-top: 4px solid #fff;
            }
        }

        > span:last-of-type {
            font-size: 4rem;
            box-shadow: initial;

            i, .mot {
                display: inline-block;
                font-family: lato;
                @extend .bold;
                font-size: 1.5rem;
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            left: -30px;
            bottom: -30px;
            background: transparent;
            border-left: 4px solid #fff;
            border-bottom: 4px solid #fff;
        }

        &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            right: -30px;
            bottom: -30px;
            background: transparent;
            border-right: 4px solid #fff;
            border-bottom: 4px solid #fff;
        }
    }    
    &.portfolio {
        margin-bottom: 0;
        animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite;
    }
    &.rechercher {
        margin-bottom: 0;
        animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite;
    }

    &.accueil {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite;
    }
    &.clients {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgportolio 20s linear infinite;
    }

    &.blog {
        animation: bgmove 5s linear infinite alternate-reverse, bgimgblogxs 20s linear infinite;
        @media #{$sm} {
        	animation: bgmove 5s linear infinite alternate-reverse, bgimgblogsm 20s linear infinite;
        }
        @media #{$md} {
        	animation: bgmove 5s linear infinite alternate-reverse, bgimgblogmd 20s linear infinite;
        }
        @media #{$lg} {
        	animation: bgmove 5s linear infinite alternate-reverse, bgimgbloglg 20s linear infinite;
        }
        @media #{$xl} {
        	animation: bgmove 5s linear infinite alternate-reverse, bgimgblogxl 20s linear infinite;
        }
    }
}


@keyframes bgimgblogxs {
    from { 	background-image: url("../../../images/bg/cliquez-la.com-1-xs.jpg");}
    24.9% { background-image: url("../../../images/bg/cliquez-la.com-1-xs.jpg");}
    25% { 	background-image: url("../../../images/bg/cliquez-la.com-2-xs.jpg");}
    49.9% { background-image: url("../../../images/bg/cliquez-la.com-2-xs.jpg");}
    50% { 	background-image: url("../../../images/bg/cliquez-la.com-3-xs.jpg");}
    74.9% { background-image: url("../../../images/bg/cliquez-la.com-3-xs.jpg");}
    75% { 	background-image: url("../../../images/bg/cliquez-la.com-4-xs.jpg");}
    to { 	background-image: url("../../../images/bg/cliquez-la.com-4-xs.jpg");}
}
@keyframes bgimgblogsm {
    from { 	background-image: url("../../../images/bg/cliquez-la.com-1-sm.jpg");}
    24.9% { background-image: url("../../../images/bg/cliquez-la.com-1-sm.jpg");}
    25% { 	background-image: url("../../../images/bg/cliquez-la.com-2-sm.jpg");}
    49.9% { background-image: url("../../../images/bg/cliquez-la.com-2-sm.jpg");}
    50% { 	background-image: url("../../../images/bg/cliquez-la.com-3-sm.jpg");}
    74.9% { background-image: url("../../../images/bg/cliquez-la.com-3-sm.jpg");}
    75% { 	background-image: url("../../../images/bg/cliquez-la.com-4-sm.jpg");}
    to { 	background-image: url("../../../images/bg/cliquez-la.com-4-sm.jpg");}
}
@keyframes bgimgblogmd {
    from { 	background-image: url("../../../images/bg/cliquez-la.com-1-md.jpg");}
    24.9% { background-image: url("../../../images/bg/cliquez-la.com-1-md.jpg");}
    25% { 	background-image: url("../../../images/bg/cliquez-la.com-2-md.jpg");}
    49.9% { background-image: url("../../../images/bg/cliquez-la.com-2-md.jpg");}
    50% { 	background-image: url("../../../images/bg/cliquez-la.com-3-md.jpg");}
    74.9% { background-image: url("../../../images/bg/cliquez-la.com-3-md.jpg");}
    75% { 	background-image: url("../../../images/bg/cliquez-la.com-4-md.jpg");}
    to { 	background-image: url("../../../images/bg/cliquez-la.com-4-md.jpg");}
}
@keyframes bgimgbloglg {
    from { 	background-image: url("../../../images/bg/cliquez-la.com-1-lg.jpg");}
    24.9% { background-image: url("../../../images/bg/cliquez-la.com-1-lg.jpg");}
    25% { 	background-image: url("../../../images/bg/cliquez-la.com-2-lg.jpg");}
    49.9% { background-image: url("../../../images/bg/cliquez-la.com-2-lg.jpg");}
    50% { 	background-image: url("../../../images/bg/cliquez-la.com-3-lg.jpg");}
    74.9% { background-image: url("../../../images/bg/cliquez-la.com-3-lg.jpg");}
    75% { 	background-image: url("../../../images/bg/cliquez-la.com-4-lg.jpg");}
    to { 	background-image: url("../../../images/bg/cliquez-la.com-4-lg.jpg");}
}
@keyframes bgimgblogxl {
    from { 	background-image: url("../../../images/bg/cliquez-la.com-1-xl.jpg");}
    24.9% { background-image: url("../../../images/bg/cliquez-la.com-1-xl.jpg");}
    25% { 	background-image: url("../../../images/bg/cliquez-la.com-2-xl.jpg");}
    49.9% { background-image: url("../../../images/bg/cliquez-la.com-2-xl.jpg");}
    50% { 	background-image: url("../../../images/bg/cliquez-la.com-3-xl.jpg");}
    74.9% { background-image: url("../../../images/bg/cliquez-la.com-3-xl.jpg");}
    75% { 	background-image: url("../../../images/bg/cliquez-la.com-4-xl.jpg");}
    to { 	background-image: url("../../../images/bg/cliquez-la.com-4-xl.jpg");}
}

/***********************
************************
****** FIN MEGATITRE */

/***********************
************************
****** DEBUT BANNER */
.banner {
    position: relative;
    margin-top: 0;
    padding-bottom: 30px;
    text-align: center;
    width: 100%;

    > *, > * > * {
        position: relative;
    }

    .bg {
        position: absolute;
        left: 0;
        right: 0;
        height: 85%;
        bottom: 0;
        //z-index: -1;
        box-shadow: inset 0 2px 5px rgba(128, 128, 128, 0.2), inset 0 -2px 5px rgba(80, 80, 80, 0.3), 0 1px 1px rgba(0, 0, 0, 0.3), 0 -1px 1px rgba(0, 0, 0, 0.3);       
    }

    &.home_pc_morph{
        margin: 0;
        .bg{
            height: 100%;
            transition: background-color 1.5s ease-in-out;
            @media #{$lg}{
                height: 80%;
            }
        }
    }

    &.showcase {
        .bg {
            height: 80%;
        }

        > img {
            max-width: 90%;
            @media #{$lg} {
            	max-width: 800px;
            }
        }
    }

    &.blog {
        .bg {
            height: 80%;
        }

        padding-bottom: 80px;

        .img_affiche {
            margin-bottom: 10px;
        }

        .descr {
            p {
                font-size: 2.8rem;
                font-weight: 300;
                line-height: 1.3;
                padding: 10px 10px 10px;
                color: #fff;
            }
        }
    }
    &.client{
        .bg{
            height: 100%;
        }
    }
    &.clients {
        .bg {
            height: 90%;
            top: 5%;
            //width: 96%;
            //bottom: 50%;
            //left: 50%;
            //transform: translate(-50%, 50%);
            //opacity: 1;
            //transition: .5s;
        }

        .cover {
            display: inline-block;
            @media #{$sm}{
                     
            }
            > img {
                //max-height: 300px;
                transform: scale(0.9);
                transition: transform 6s ease-in 0.2s;
                max-width: 100%;
                height: auto;
            }
            a{
                display: block;/* 
                width: 80%;
                margin-left: 10%; */
            }
        }

        > img {
            max-height: 300px;
            width: 80%;
        }

        .intro-titre {
            bottom: 0% !important;
            opacity: 0;
            transition: .5s;
        }

        &:hover {
            .bg {
                //opacity: 1;
            }

            .intro-titre {
                bottom: 10% !important;
                opacity: 1;
            }
            .cover
            > img{
                transform: scale(1);
            }
        }
    }

    &.affiche {
        margin-top: -120px;

        img {
            //padding-bottom: 20px;
        }
        @media #{$lg} {        	
            .cover{
                transform-style: preserve-3d;
                .img_affiche{
                    transform-origin: top;
                    perspective-origin: top;    
                    max-height: 90%;
                    transform: perspective(300px) rotateX(0deg);
                    animation-duration:.5s;
                    transition:.5s;

                    /* &:hover{
                        animation: affiche 1s ease-out forwards;
                    } */
                }
            }    
        }
    }

    &.album {
        padding-bottom: 50px;
        margin-bottom: $mb*2;
        .bg {
            height: 92%;
        }
        &:last-of-type{
            margin-bottom: $mb;
        }
    }

    &.meme_client {
        padding-bottom: 0px;
        margin-bottom: $mb / 2;

        .bg {
            height: 90%;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
        }

        a {
            display: block;

            &:hover {
                animation: bounce 0.7s;
            }
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }

    &.autre_rea_client {
        padding-bottom: 0;
    }

    &.portfolio_client {
        padding-bottom: 0;
    }

    &.content {
        &.flyer {
            .img_affiche {
                margin-top: -110px;
            }
        }

        &.flocage {
            .img_affiche {
                margin-top: -150px;
            }
        }

        &.client {
            padding-bottom: 0;
        }
        &.rechercher.content{
            .img_affiche{
                max-width: 80%;
                height: auto;
            }
        }
    }

    &.intro {
        //height: 400px;
        &.clients{            
            width: 100%;
            margin-left: 3%;
            margin-right: 3%;
            padding-top: 0;
            padding-bottom: 0;
            .intro-titre{
                display: none;
            }
            @media #{$sm} {
                .intro-titre{
                    display: block;
                }
                min-height: 320px;
            }
            @media #{$md} {
                min-height: 380px;
            }
            @media #{$lg} {
                min-height: 400px;
            }
        }
        &.affiche {
            margin-top: 0px;
            padding-top: 0px;
            .cover{
                position: absolute;
                top: 0;
            }
        }

        &:not(.affiche) {
            margin-top: $mb/2;
            @media #{$sm} {
                margin-top: 0;
            }

            .cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }

        &.cartevisite .cover img {
            display: block;
            padding-top: 30px;
        }

        .cover {
            position: relative;
            z-index: 2;
        }

        .bg {
            box-shadow: inset 0 -1px 0px rgba(80, 80, 80, 0.3), 0 1px 1px rgba(0, 0, 0, 0.3), 0 -1px 1px rgba(0, 0, 0, 0.1);
        }

        &.portfolio {  
            min-height: 320px;  
            padding-bottom: 0;
            .wrapper_intro{
                width: 100%;
                padding-bottom: 30px;
                padding-right: 30px;
                padding-left: 30px; 
                position: relative;
            }       
            .bg {
                height: 60%;
                left: 5%;
                right: 5%;
            }
            @media #{$sm} {
                min-height: 320px;  
                .wrapper_intro{
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
            @media #{$md} {
                min-height: 380px;
            }
            @media #{$lg} {
                min-height: 400px;
            }
        }

        .intro-titre {
            position: relative;
            display: block;
            padding-right: 2rem;
            padding-left: 2rem;
            //@media #{$sm} {
            //    position: absolute;
            //    bottom: 20px;
            //    left: 15px;
            //    right: 15px;
            //}
            z-index: 1;
            text-align: center;
            text-decoration: none;
            color: #fff;

            span {
                display: inline;
                text-transform: uppercase;
                @extend .bold;
                font-size: 1.3rem;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 8px;
                    right: 8px;
                    height: 1px;
                    background: #fff;
                }
            }

            h3 {
                font-size: 2rem;
                line-height: 1.2;
                font-weight: normal;
                margin: 10px 0 0 0;
                padding: 0;
                overflow: hidden;                
                @media #{$sm} {
                    max-height: 4.4rem;
                }
            }
        }

        @media #{$md}{
            &.siteweb .cover img, &.cartevisite .cover img {
                display: inline;
                padding-top: 0;
            }
        }
    }
    &.rechercher{        
        padding-top: 30px;
        .bg{
            height: 100%;
            padding-top: 30px;
        }
        .megatitre{ 
            display: block;
            font-size: 2.5rem;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .tags{
            margin-bottom: 10px;
            padding-bottom: 0;
        }
        .tags-links{
            .tag-link{
                margin: 20px 5px 10px;
            }
        }
    }    

    p {
        margin: 10px auto 20px;
        font-size: 2.8rem;
        font-weight: 300;
    }
    .quote {
        font-weight: 900;
        font-size: 2rem;
        line-height: 3.7rem;
        padding: 30px 30px 50px;
        color: #fff;

        i {
            font-size: 4rem;
            line-height: 4.7rem;
        }
    }
}

.blog {
    .blog_contexte {
        margin-bottom: $mb / 2;
        text-align: center;

        h2, h3 {
            position: relative;
            text-align: center;
            display: block;
            padding: 0 !important;
            margin: 0;
            margin-top: 60px;
        }

        h2 {
            font-size: 4rem;
            line-height: 1.3;
            color: #fff;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(0, 0, 0, 0.1);
            letter-spacing: 0.25rem;
        }

        h3 {
            font-family: lato;
            font-weight: 700;
            font-size: 3rem;
            line-height: 1.2;
            color: #969696;
        }

        p {
            text-align: center;
            position: relative;
            margin-bottom: $mb;

            &.hr {
                margin-bottom: $mb;

                &:after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.1);
                    bottom: -$mb / 3;
                    left: 30%;
                    right: 30%;
                    z-index: 10;
                }
            }
        }

        img {
            display: inline;
            margin-top: $mb / 2;
            margin-bottom: $mb / 2;
        }
    }
}

/***********************
************************
****** FIN BANNER */
