/***********************
************************
****** DEBUT CONTENT */

.content_titre {
    background-color: transparent;
}

.content {
    .block_titre {
        padding-top: 9vh;
        padding-bottom: 9vh;   
        margin-top: $menu1;
        @extend .flex-xs-middle;
        display: flex;
        flex-direction: column;
    }    
    @media #{$md} {
        .block_titre{               
            margin-top: $menu1 + $menu2;
        }
    }
}
.content {
    .block_titre_clients {
        padding-top: 9vh;
        padding-bottom: 2vh;  
        margin-top: $menu1;
        @extend .flex-xs-middle;
        &.clients{
            padding-top: 0;
            padding-bottom: 0;
        }
    }    
    @media #{$md} {
        .block_titre{               
            margin-top: $menu1 + $menu2;
        }
    }
}


.content {
    .block_titre {
        position: relative;
        z-index: 10;

        .bar-h {
            display: none;
        }

        &.siteweb {
            //padding: 150px 0 100px;
        }

        &.flyer {
            //padding: 160px 0 10px;
        }

        &.flocage {
            //padding: 140px 0 10px;
        }

        &.cartevisite, &.logo {
            //padding: 180px 0 30px;
        }

        &.affiche {
            padding-bottom: 120px; 
            .bar-h {
                display: block;
                width: 100%;
                max-width: 450px;
                height: 10px;
                margin-top: 15px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            }
        }
    }

    &.blog {
        .block_titre {
            padding-top: 6vh;
            padding-bottom: 3vh; 
        }
    }

    &.clients {
        .block_titre {
            //padding: 220px 0 60px;
        }
    }
}


/***********************
************************
****** DEBUT CONTENT */


/***********************
************************
****** DEBUT */

.avant_contexte {
    text-align: center;
    margin-top: 80px;

    .type_client {
        color: #fff;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: 2px;
        display: inline-block;
        padding: 10px 16px;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
        @media #{$md}{
            font-size: 2.3rem;
        }

        a {
            color: #fff;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.portfolio_contexte, .blog_contexte {
    padding-right: 2rem;
    padding-left: 2rem;
    p {   
        margin: 30px auto 60px;
    }
}
       
.galerie_titre{
    .block_titre_showcase{
        .megatitre{
            
        }
    }
}      

.pix_album {
    position: relative;
    @media #{$xl}{
        .galerie_photo{
            &:nth-of-type(3n+1) .affiche > a,&:nth-of-type(3n+1) .affiche.titre_pix {
                transform: rotate(-4deg);
                transform-origin: 100% 100%;
            }

            &:nth-of-type(3n+3) .affiche > a,&:nth-of-type(3n+3) .affiche.titre_pix {
                transform: rotate(4deg);
                transform-origin: 0% 100%;
            }
        }
    }

    .titre_pix {
            margin-bottom: $mb/2;
            @media #{$md}{
                margin-bottom: $mb;
            }
            position: relative;
            transition: 0.5s;
            h2{
                font-size: 2rem;
                letter-spacing: 1px;
                line-height: 1;
                @extend .lato;
                text-transform: none;
                color: #fff;
                text-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
            }
    }
    .wrapper_album {
        position: relative;
        z-index: 0;
        display: inline-block;
        text-align: center;
        //margin-right: 50px;
        margin-bottom: $mb/3;
        //margin-left: 20px;
        &:hover {
            .titre_pix {
                h2{

                }
            }
        }
        &.affiche {
            margin: 0;
            > a{
                display: block;
                box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.25);
            }
        }

        /* &.affiche:nth-of-type(3n+1) > a {
            transform: rotate(-4deg);
            transform-origin: 100% 100%;
        }
        
        &.affiche:nth-of-type(3n+3) > a {
            transform: rotate(4deg);
            transform-origin: 0% 100%;
        }
        
        &:nth-of-type(n+4) {
            margin: 20px 10px;
        }
        
        &:nth-of-type(4) {
            margin-top: 80px;
        } */

        &.siteweb {
            border: 15px solid #dedede;
            border-radius: 10px 10px 1px 1px;
            //margin: 0 70px 50px 0;
            //margin-bottom: $mb;
            margin-left: $mb;
            margin-right: $mb;
            box-shadow: 0 0rem 1rem rgba(0,0,0,0.2),0 -2rem 7rem rgba(0,0,0,0.1);

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: -8px;
                margin: -2px 0 0 -2px;
                width: 4px;
                height: 4px;
                border-radius: 6px;
                background: #a5adbd;
            }

            &:after {
                content: '';
                position: absolute;
                left: -40px;
                right: -40px;
                bottom: -30px;
                background: #ccc;
                height: 15px;
                z-index: 10;
                border-radius: 3px 3px 15px 15px;
                box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 -2px 3px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.6);
            }

            a {
                position: relative;
                display: block;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
                }
            }

            .titre_pix {
                font-size: 2.4rem;
                //bottom: -3rem;
            }

            &:hover {
                .titre_pix {
                }
            }
        }
    }
    @media #{$lg} {
        $pc_width: 800px;
        $pc_height: 534px;

        $tel_width:200px;
        $tel_height:300px;

        $tablet_width:382px;
        $tablet_height:499px;

        &.pix_album {    
              .container_phone,.container_tablet,.pix_album_titre{
                
            }       

            .tablet{      
            }
            .pix_album_titre{
            }    
            .container_tablet{
                //margin-top:-($tablet_height)/2 - 50;
                .tablet{
                    //margin: 60px auto 90px 30px;
                }
            }    
            .container_phone{
                //margin-top:-($tel_height)/2 - 50;
                .phone{
                    //margin: 60px 30px 90px auto;
                }
            }    
            .monitor{
                
            }
        }
    }
}


.pix_album {
    .galerie_lien{
        color:#fff;
        &:hover{
            color:#fff;
            text-decoration: none;
        }
    }
    .shadow {
        position: absolute;
        width: $pc_width / 3;
        height: 40px;
        left: 50%;
        margin-left: -$pc_width / 6;
        //border-radius: 45%;
        z-index: -1;
        bottom: -20px;
        //background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.15) 0%, rgba(0,0,0,0) 100%);
        //background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.15) 0%,rgba(0,0,0,0) 100%);
        //background: radial-gradient(ellipse at center,  rgba(0,0,0,0.15) 0%,rgba(0,0,0,0) 100%);
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=1 );
        //opacity:0.7;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
        background: #000;
        border-radius: 50%;
        background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
    }
    .mega_tritre{
        position: relative;
    }
    .pix_album_titre{
        p{
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .fontion_anim{
        margin-top: 30px ;
        .restart_anim,.start_anim,.pause_anim{
            color: #fff;
            font-size: 4rem;
            text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            display: inline-block;
            margin: 10px 10px 0;
        }
        .pause_anim{
            opacity: 0.5;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            &.paused{
            opacity: 1;
            text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

            }
        }
    }
    .phone .shadow {
        bottom: -($tel_bb + 0.5vw);
        left: -50%;
        margin-left: $tel_width/2;
        height: 10px;
        width: $tel_width;
    }
    .tablet .shadow {
        width: $tablet_width;
        left: -50%;
        height: 60px;
        margin-left: $tablet_width/2; 
        bottom: -($tablet_bord * 2);  
    }
    .phone,.tablet,.monitor{
        display:block;
        box-shadow: 0px -5px 25px rgba(0, 0, 0, 0.15);
        .content:after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
        }
    }
    .insetshadow{
        position: absolute;
        box-shadow: inset 0 0px 6px rgba(0, 0, 0, 0.3);
    }
    .container_tablet .animm{transform:scale(2);}
    .container_phone .animm{transform:scale(2);}
    .container_pc .animm{transform:scale(1.5);}
    .container_tablet,.container_phone,.container_pc{                
        margin-top: $mb;
        margin-bottom: $mb;
        @media #{$md} {
            transform:scale(1);
            margin-top: 0;
            margin-bottom: 0;
            .animm{
                transform: scale(1);
            }

        }
        span{
            position: absolute;
            bottom: -3rem;
            font-size: 1.2rem;
            letter-spacing: 1px;
            @extend .lato;
            color: #fff;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            line-height: 1;
            padding: 1rem 1rem 0;
            text-align: center;
        }
        &.container{
            &_mobile{
                span{
                    bottom: -6rem;
                }
            }
        }
    }

    .phone {
        box-sizing: content-box; 
        z-index: 2;
        margin: $tel_height/2 auto ($tel_height/2);
        width: $tel_width;
        height: $tel_height;
        position: relative;
        border-radius: $tel_br;
        background: #000;
        border-top: $tel_bt solid #e2e2e2;
        border-left: $tel_side solid #e2e2e2;
        border-right: $tel_side solid #e2e2e2;
        border-bottom: $tel_bb solid #e2e2e2;
        @media #{$md}{
            margin-top: 0;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            background: #d6d6d6;
            box-shadow: inset 0 ($tel_before_height/2) $tel_before_height rgba(0,0,0,0.3);
        }

        &:before {
            width: $tel_before_width;
            height: $tel_before_height;
            margin-left: -($tel_before_width/2);
            top: -($tel_before_width / 3);
            border-radius: $tel_before_height;
        }

        &:after {
            width: $tel_after_width;
            height: $tel_after_width;
            border-radius: $tel_after_width;
            bottom: -$tel_after_bottom;
            margin-left: -$tel_after_ml;    
        }

        .insetshadow{
            margin-top:-$tel_bt;
            margin-left: -$tel_side;
            width: $tel_width + ($tel_side*2);
            height: $tel_height + ($tel_bt + $tel_bb);
            border-radius: ($tel_after_ml/2);
        }
        .screen {
            width: $tel_width;
            height: $tel_height;
            position: relative;
            overflow: hidden;
        }

        .content {
            background: #fff;
            width: $tel_width;
            height: $tel_height;
            margin-left: 0;
            background-size: $tel_width auto;
            background-repeat: none;
            animation: bgmoveecran linear infinite alternate-reverse;
            animation-duration: 40s;
            animation-play-state: paused;

            &:hover,&.show_anim {
                animation-play-state: running;
            }
            &.restart{
                animation: bgtop 0.3s ease-out;
            }
            &.go_anim{
                animation-play-state: running;
                &:hover{
                    animation-play-state: paused;
                }
            }
            &.pause_anim{
                animation-play-state: paused;
            }
        }
    }

    .tablet {
        box-sizing: content-box; 
        z-index: 2;
        position: relative;
        margin: $tablet_height/2 auto;
        width: $tablet_width;
        height: $tablet_height;
        background: #fc9252;
        border: $tablet_bord solid #e8ebf0;
        border-radius: $tablet_brad;
        @media #{$md}{
            margin-top: 0;
        }
        &:after{
            content: "";
            position: absolute;
            top: -$tablet_bord;
            bottom: -$tablet_bord;
            right: -$tablet_bord;
            left: -$tablet_bord;
            border-radius: $tablet_brad;
            z-index: -1;
            box-shadow: inset 0 0px $tablet_bouton rgba(0, 0, 0, 0.3);
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            margin: -($tablet_bouton/2) 0 0 -($tablet_bouton/2);
            width: $tablet_bouton;
            height: $tablet_bouton;
            border-radius: $tablet_bouton;
            background: #a5adbd;
            top: -($tablet_bouton*2);            
            box-shadow: inset 0 ($tablet_bouton/6) ($tablet_bouton/3) rgba(0,0,0,0.3);
        }

        > div {
        position: absolute; 
        }

        > .content {
            width: $tablet_width;
            height: $tablet_height;
            left: 0;
            margin-left: 0;
            background-size: $tablet_width auto;
            background-repeat: none;
            animation: bgmoveecran linear infinite alternate-reverse;
            animation-duration: 30s;
            animation-play-state: paused;

            &:hover,&.show_anim {
                /* animation: bgtop 0.3s ease-out; */
                animation-play-state: running;
            }
            &.restart{
                animation: bgtop 0.3s ease-out;
            }
            &.go_anim{
                animation-play-state: running;
                &:hover{
                    animation-play-state: paused;
                }
            }
            &.pause_anim{
                animation-play-state: paused;
            }
        }

    }
    

    .monitor {
        box-sizing: content-box; 
        z-index: 1;           
        margin: 0 auto ($pc_height/2 + 3vw);
        width: $pc_width;
        height: $pc_height;
        position: relative;
        //background: #ff6860;
        border: $pc_bord solid #474e5d;
        border-radius: 4px;
        @media #{$md}{
            margin-top: 0;
            border-radius: 10px;
        }

        > div {
            position: absolute;
        }
        

        &:before, &:after {
            content: "";
            position: absolute;
            left: 50%;
            box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
        }

        &:before{
            top: -$pc_bord/2;
            margin: -($pc_bouton_top/2) 0 0 -($pc_bouton_top/2);
            width: $pc_bouton_top;
            height: $pc_bouton_top;
            border-radius: $pc_bouton_top;
            background: #a5adbd;
        }
        &:after {
            width: $pc_bouton_top;
            height: $pc_bouton_top;
            border-radius: $pc_bouton_top;
            bottom: -$pc_bord/2;
            margin: 0 0 -($pc_bouton_top/2) -($pc_bouton_top/2);
            background: #e8ebf0;
        }

        .content {
            width: $pc_width;
            height: $pc_height;
            left: 50%;
            margin-left: -$pc_width / 2;
            overflow: hidden;
            background-size: $pc_width auto;
            background-repeat: no-repeat;
            animation: bgmoveecran linear infinite alternate-reverse;
            animation-duration: 20s;
            animation-play-state: paused;

            &:hover,&.show_anim {
                /* animation: bgtop 0.3s ease-out; */
                animation-play-state: running;
            }
            &.restart{
                animation: bgtop 0.3s ease-out;
            }
            &.go_anim{
                animation-play-state: running;
                &:hover{
                    animation-play-state: paused;
                }
            }
            &.pause_anim{
                animation-play-state: paused;
            }

            &:before {
                content: "";
                position: absolute;
                right: -90px;
                width: $pc_width / 2 - 20;
                height: $pc_height * 1.5;
                transform: rotate(45deg);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 5;
            }
            &.restart{
                animation: bgtop 0.3s ease-out;
            }
        }

        .base {
                background-image:url('../../../images/stand-pc.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;    
                z-index: -1;
                opacity:1;
                bottom: - (($pc_base_height + $pc_bord) - 0.1vw);
                height: $pc_base_height;
                width: $pc_base_width;
                left: 50%;
                margin-left: - ($pc_base_width / 2);
                z-index: -10;
            &:after{
                content: '';
                position: absolute;
                z-index: -1;
                bottom: 10px;
                width: $pc_width;
                left: (($pc_base_width/2) - ($pc_width/2));
                height: 40px;
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
                background: #000;
                border-radius: 50%;
                background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
                background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.25) 0%, transparent 100%);
                background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
            }
                
         }   
            

        

        .foot {
            background: #e0e0e0;
            z-index: 1;
        }

        .foot.top {
            width: 116px;
            height: 5px;
            bottom: -5px;
            left: 50%;
            margin-left: -58px;

            &:before {
                border-left: 16px solid transparent;
                border-right: 0px solid transparent;
                border-bottom: 5px solid #e8ebf0;
                left: -16px;
            }

            &:after {
                border-right: 16px solid transparent;
                border-left: 0px solid transparent;
                border-bottom: 5px solid #e8ebf0;
                right: -16px;
            }
        }

        .foot.top:before,
        .foot.top:after,
        .foot.bottom:before {
            content: "";
            position: absolute;
            top: 0px;
        }

        .foot.bottom {
            width: 150px;
            height: 5px;
            bottom: -10px;
            left: 50%;
            margin-left: -75px;
            z-index: 999;
        }
    }
}

.container_flyer{
    position: relative;
    z-index: 10;
    font-size: 0;  
    margin-top:60px;
    margin-bottom: 60px;  
    .shadow {
        position: absolute;
        width: 120%;
        height: 20px;
        left: 0%;
        margin-left: -10%;
        border-radius: 45%;
        z-index: -1;
        bottom: -60px;
        background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
        background: radial-gradient(ellipse at center,  rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=1 );
        opacity:0.6;
        transition:0.6s ease-in-out;
    }
    &:hover{
    }
    &.deux_pages{
        .inner{
            transform:scale(0.8) translateX(-10%) translateY(30%) rotateX(30deg) rotateY(-30deg) rotateZ(-50deg);
            perspective: 800px;
            perspective-origin: bottom;
            margin-left: auto;
            margin-right: auto;
            .page{
                max-width: 45%;
                transition:0.6s 0.2s ease-in-out;
                position: relative;
                transform-style: preserve-3d;
                .recto{
                    position: relative;
                }
                .verso{
                    position: absolute;
                    margin: 0;
                    display: block;
                    backface-visibility: hidden;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scaleX(-1) translateZ(-0.1px);
                }
            }  
        }
    }
    &.trois_pages{
        .inner{
            transform:scale(0.8) translateX(0%) translateY(0%) rotateX(50deg) rotateY(-30deg) rotateZ(0deg);
            perspective: 800px;
            perspective-origin: bottom;
            margin-left: auto;
            margin-right: auto;
            .page{
                max-width: 30%;
                transition:0.6s ease-in-out;
                position: relative;
                transform-style: preserve-3d;
                .recto{
                    position: relative;
                }
                .verso{
                    position: absolute;
                    margin: 0;
                    display: block;
                    backface-visibility: hidden;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transform: scaleX(-1) translateZ(-0.1px);
                }
            }    
        }
    }
    &.une_page{
        .inner{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            max-width: 70%;
            position: relative;
            .page{                   
                transform-style: preserve-3d;
                transform:scale(0.8) translateX(0%) translateY(20%) rotateX(70deg) rotateY(0deg) rotateZ(-20deg);
                perspective: 800px;
                perspective-origin: bottom;
                transition:1s cubic-bezier(.64,.55,.4,1.47);
                z-index: 1;
                &:before{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;                      
                    box-shadow: inset 80px 0px 160px rgba(0,0,0,1);
                    opacity: 0.3;
                    transition:1s cubic-bezier(.64,.55,.4,1.47);
                }
                &:after{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;                        
                    box-shadow: inset -80px 0px 160px rgba(255,255,255,1);
                    opacity: 0.5;
                    transition:1s cubic-bezier(.64,.55,.4,1.47);
                }
            }            
            &:hover,&.opened{
                .page{
                    transform: scale(1) translateX(0%) translateY(-6%) rotateX(-6deg) rotateY(0deg) rotateZ(0deg);
                }
                > .page{
                    transition:1.4s cubic-bezier(.64,.55,.4,1.47);       
                    &:before{
                        opacity: 0.1;
                        transition:1.6s cubic-bezier(.64,.55,.4,1.47);
                    }      
                    &:after{
                        opacity: 0.1;
                        transition:1.6s cubic-bezier(.64,.55,.4,1.47);
                    }
                }
                .shadow{
                    width: 360px;
                    height: 30px;
                    left: 50%;
                    margin-left: -180px;
                    bottom: -70px;
                    opacity: 0.7;
                    transition:1s cubic-bezier(.64,.55,.4,1.47);
                }
            }    
        }
    }    
    &.affiche{
        margin-top: 30px;
        margin-bottom: $mb*1.5;
        perspective-origin: top;
        transform-style: preserve-3d;
        perspective: 1000px;
        .inner{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            max-width: 90%;
            position: relative;
            transform-style: preserve-3d;
            transform: rotateY(0deg);
            transform-origin: 50% -113px;
            transition: 0.6s ease-in-out;
            .shadow{
                width: 120%;
                left: 0;
                margin-left: -10%;
                bottom: -60px;
                opacity: 0.5;
            }
            .page{
                z-index: 1;
                &:before{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;                      
                    box-shadow: inset 80px 0px 160px rgba(0,0,0,1);
                    opacity: 0.1;
                }
                &:after{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;                        
                    box-shadow: inset -80px 0px 160px rgba(255,255,255,1);
                    opacity: 0.2;
                }
                .clip{
                    display: block;
                    &:before,&:after{
                        content: url('../../../images/clip.png');
                        position: absolute;
                        top: -113px;
                        width: 31px;
                        height: 123px;
                        z-index: 1;
                    }
                    &:before{
                        left: 50px;
                    }
                    &:after{
                        right: 50px;
                    } 
                }
            }            
            &:hover,&.opened{
                animation:affichemove 2s ease-out forwards; 
                .page{      
                    &:before{
                        animation:affichemoveshadow 0.4s alternate ease-in-out forwards;
                        animation-iteration-count: 4;
                    }      
                    &:after{
                        animation:affichemoveshadow 0.4s alternate-reverse ease-in-out forwards;
                        animation-iteration-count: 4;
                    }      
                }
                & + .shadow{
                    width: 300px;
                    height: 30px;
                    left: 50%;
                    margin-left: -150px;
                    bottom: -40px;
                    opacity: 0.4;
                    transition:.6s ease-out;
                }
            }    
        }
    }
    &.trois_pages,&.deux_pages {
        .inner{
            text-align: center;   
            transform-style: preserve-3d;
            transition: 0.6s ease-in-out;

            div.page{
                display: inline-block;
                position: relative;
                z-index: 1;  
                &:nth-of-type(1){
                    transform: rotateY(164deg);
                    transform-origin: right 50%;
                }
                &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3){
                    &:after{
                        content:'';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;                        
                        box-shadow: inset 80px 0px 160px rgba(255,255,255,1);
                        opacity: 0.7;
                        transition:0.6s ease-in-out;
                    }
                }
                &:nth-of-type(1){
                    &:after{                        
                    box-shadow: inset -80px 0px 160px rgba(0,0,0,1);
                    opacity: 0.7;
                        transition:0.6s ease-in-out;
                    }
                }                
                &:nth-of-type(2),&:nth-of-type(3) {
                    &:after{
                        opacity: 1; 
                    }
                }
                &:nth-of-type(3){
                    transform:rotateY(164deg);
                    transform-origin: left 50%;
                }
                img{

                }
            }
            &:hover,&.opened{
                transform: scale(1) translateX(0%) translateY(0%) rotateX(0deg) rotateY(0deg) rotateZ(0deg) ;
                transition:1s ease-out;
                > .page{  
                    transition:1.2s 0.5s ease-in-out;     
                    &:nth-of-type(1){
                        transform:scaleX(1)  rotateY(0deg);
                        &:after{
                            opacity: 0.1;
                            transition:1.2s ease-in-out;                            
                        }                              
                    }
                    &:nth-of-type(2),&:nth-of-type(3){
                        &:after{
                            opacity: 0.2;
                            transition:1.2s 0.5s ease-in-out;                            
                        }
                    }
                    &:nth-of-type(3){
                        transform:scaleX(1) rotateY(-0deg);          
                    }
                }
                & + .shadow{
                    width: 300px;
                    height: 30px;
                    left: 50%;
                    margin-left: -150px;
                    bottom: -40px;
                    opacity: 0.4;
                    transition:.6s ease-out;
                }
            }
        }

    }
    &.une_page_rv,&.cartevisite_1_page_rv{    
        perspective: 1000px;    
        .control{
            a{
                font-size: 4rem;
                color: #fff;
                text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                display: inline-block;
                margin: 10px 10px 0;
                @extend .lato;
                background: $gris2;
                padding: 1rem 2rem;
                box-shadow: 0 3px 7px rgba(0,0,0,0.3);
                &:before{
                    vertical-align: middle;
                    margin-right: 1rem;
                }
                span{
                    display: block;
                    font-size: 46%;
                    line-height: 1;
                }
            }           
        }
        .inner{
            text-align: center;
            margin: 30px auto 30px;
            transition: 0.6s;
            transform-style: preserve-3d;
                -webkit-transform-style: preserve-3d;
            position: relative;
            transform-origin: 50% 100%;            
            max-width: 90%;
            transform: scale(0.85) rotateY(360deg) rotateX(-60deg);
            transition:.6s ease-out;
            //animation: anim_carte_back 1s linear forwards;
            /* &:before {
                content: '';
                position: absolute;
                top: 108%;
                bottom: -10%;
                right: 20%;
                left: 20%;
                box-shadow: inset 0px 0px 40px rgba(0,0,0,0.1);
                transform: rotateX(90deg) translateY(50%) rotateY(0deg);
                transform-origin: 50% 100%;
                border-radius: 50%;
            } */
            .shadow{
                width: 40%;
                margin-left: 35%;
                bottom: -50px;
                height: 10px;
                opacity: 0.3;
            }
            .front,.back{
                backface-visibility: hidden;
                //position: absolute;
                top: 0;
                left: 0;
                &:before{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    box-shadow: inset -80px 0px 160px rgba(0,0,0,1);
                    opacity: 0.1;
                }
                &:after{
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    box-shadow: inset 80px 0px 160px rgba(255,255,255,1);
                    opacity: 0.1;
                }
            }
            .front {
                z-index: 2;
                transform: rotateY(0deg);
            }
            .back {
                position: absolute;
                transform: rotateY(180deg);
            }
        }  
        &.inview {
            .inner {            
                //animation: anim_carte 6s linear infinite forwards;
                transform: scale(1) rotateY(0deg) rotateX(0deg);
                .front,.back{
                    &:before{
                        //animation: anim_carte_shadow_light 3s infinite alternate forwards;
                    }
                    &:after{
                        //animation: anim_carte_shadow_dark 3s infinite alternate forwards;
                    }
                }
            }
        }  
        /* &:hover, */
        &.opened {
            .inner {            
                //animation: anim_carte 6s linear infinite forwards;
                transform: scale(1) rotateY(180deg) rotateX(0deg);
                .front,.back{
                    &:before{
                        //animation: anim_carte_shadow_light 3s infinite alternate forwards;
                    }
                    &:after{
                        //animation: anim_carte_shadow_dark 3s infinite alternate forwards;
                    }
                }
            }
        }
    }
}

//+ SOMMAIRE bas de page
#sommaire{
    max-width: 300px;
    position: relative;
    height: $menu2;
    overflow-y: hidden;
    &.overflowed{
        
    }
    ul{    
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        /* bottom: 0; */
        left: 30px;
        transition:0.3s;
        li{
            a{
                display: block;
                padding-left: 10px;
                padding-right: 10px;
                width: 100%;
                height: $menu2;
                line-height: $menu2; 
                font-family: lato;
                text-decoration: none;
                font-size: 1.5rem;
                color: $text;
                text-transform: none;
                transition:0.3s;
                i{
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            &.cible{
                a{
                    color: $tool;
                    background-color: transparent;
                    i:before{
                        content:'\48';
                    }
                }
            }
            &:not(.cible){
                a{
                    font-weight: 900;
                    background-color: #fff;                    
                    box-shadow: inset 0 0px 0.5px rgba(0,0,0,0.8);
                }
                &:hover{
                    a{
                        font-weight: 900;
                        color: $tool;
                    }                    
                }
            }
        }

        &.deployed{
            li.cible{
                a{
                    background-color: $tool;
                    color: #fff;
                }                    
            }
        }
    }
}
//+ FIN SOMMAIRE bas de page
