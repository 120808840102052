


#home{
    text-align: center;
    //padding-top:($menu1 + $mb);
    .morph_block{
        height: $pc_height + $pc_base_height + ($pc_bord_bas + $pc_bord);        
    }
    .pc{
        display: inline-block;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: 10px;
            left: 5%;
            right: 5%;
            height: 40px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
            background: #000;
            border-radius: 50%;
            background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
            background: radial-gradient(ellipse at center,  rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=1 );
        }
        .inner{
            position: relative;
            transform-style: preserve-3d;
            perspective: 800px; 
            &:before{
                content:'';
                background-image:url('../../../images/stand-pc.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;    
                z-index: -1;
                opacity:0;
                bottom: 0px;
                height: $pc_base_height;
                width: $pc_base_width;
                left: 50%;
                margin-left: - $pc_base_width / 2;
                transition: 1.4s ease-in-out;
            }             
            .blockin{
                box-sizing: content-box;
                position: relative;
                margin-bottom: $pc_base_height;
                background-color: rgba(255, 255, 255, 0.7);
                border: solid #0f0f0f;    
                transform-origin:50% 50%;
                transform-origin:50% 50%;
                text-align: center;
                box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.65);
                overflow-y: hidden;  
                overflow-x: hidden;              
                transition: 1.4s ease-in-out;
            }
            #pcanim{
                $transition_morph: 0.3s;  
                $mbp: 2em;
                $mbw: 0.2em;
                $boxshadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                $bgb: rgba(255,255,255,0.9);
                .anim_header_pc{
                    display: none;
                    display: block;
                    /* padding: 0.2em 1%; */                    
                    transition:$transition_morph; 
                    &.ecran{
                        font-size: 3px;    
                        @media #{$sm}{
                            font-size: 5px;
                        }                  
                        @media #{$md}{
                            font-size: 7px;
                        }                  
                        @media #{$lg}{
                            font-size: 10px;
                        }                  
                        @media #{$xl}{
                            font-size: 12px;
                        }                  
                        @media #{$xxl}{
                            font-size: 14px;
                        }                    
                        .conteneur{
                            position: relative;                        
                            display: inline-block;
                            padding-left: 1em;
                            padding-right: 1em;
                            width: 80%;
                        }
                        .block{              
                            margin-bottom: $mbp;
                            box-shadow:$boxshadow;
                            background-color: $bgb;
                            color: $toolw;        
                            transition:$transition_morph;
                            &:hover{
                                background-color: $toolw;
                                color: $bgb; 

                            } 
                        }
                        .block_reverse{
                            background-color: $toolw;
                            color: $bgb;  
                            transition:$transition_morph;
                            &:hover{
                                background-color: $bgb;
                                color: $toolw; 
                            } 
                        }
                        .w1,.w2,.w3{            
                        }                    
                        .w1{
                            width: (100%) - 4;
                            margin-left: 2%;
                            margin-right: 2%;
                        }                   
                        .w2{
                            width: (100% / 2) - 4;
                            margin-left: 2%;
                            margin-right: 2%;
                        }                 
                        .w3{
                            width: (100% / 3) - 4 ;
                            margin-left: 2%;
                            margin-right: 2%;
                        }             
                        .w4{
                            width: (100% / 4) - 4 ;
                            margin-left: 2%;
                            margin-right: 2%;
                        }
                    }                  
                    .header_pc, .liste .block,.ico_left .block, .conteneur .block,.conteneur_left .titrep{
                        opacity: 0;
                        margin-top: 2em;
                        transition: $transition_morph;
                    }
                    .conteneur{
                        position: relative;                        
                        display: inline-block;
                        padding-left: 1em;
                        padding-right: 1em;
                        width: 80%;
                    }
                    .conteneur_left{
                        position: relative;
                        display: inline-block;
                        padding-left: 1em;    
                        padding-right: 0;
                        width: 20%;
                        vertical-align: top;
                        .titrep{
                            margin-bottom: 0.4em;
                        }

                    }
                    .blocktext{
                        font-family: blokk;
                        font-size: 0.7em;
                        line-height: 1.5;
                        text-align: right;
                        display: inline-block;
                        overflow: hidden;
                    }
                    .titrep{
                        display: block;
                        padding: 1em 1em;    
                        font-size: 0.9em;
                    }
                    .tal{
                        text-align: left;
                    }
                    .tar{
                        text-align: right;
                    }
                    .inner_block{
                        display: block;
                        position: relative;
                        width: 100%;
                        .blocktext{
                            width: 70%;
                        }
                    }
                    .header_pc{
                        background-color: $bgb;  
                        box-shadow:$boxshadow;
                        .votre_logo{
                            font-family: placard;
                            display: inline-block;
                            font-size: 4em;
                            margin-top: 0.2em;
                            text-align: left;
                            margin-left: 2%;
                            &:before{
                                vertical-align: middle;
                            }
                        }
                        .icon-burger{
                            text-align: right;
                            display: inline-block;    
                            vertical-align: middle;
                            font-size: 3em;
                            margin-right: 0.2em;
                        }
                    }
                    .liste{
                        text-align: left;
                        margin-bottom: 0;
                        li.block{
                            padding: 1em 2em;
                            margin-bottom: 1em;
                        }

                    }
                    .ico_left{
                        > div{
                            display:inline-block;
                            vertical-align: middle;
                            width: 23%;
                            margin: 1%;
                            font-size: 1.5em;
                        }
                    }
                    .slider{
                        .icon-left, .icon-right, .icon-img-thumb,.icon-webmarketing{
                            display: inline-block;
                            vertical-align: middle;
                            position: relative;
                        }
                        .icon-left, .icon-right{
                            width: 25%;
                            font-size: 2em;
                        }
                        .icon-img-thumb,.icon-webmarketing{
                            width: 50%;
                        }
                        .icon-left{
                            text-align: left;
                            padding-left: 0.4em;
                        }
                        .icon-right{
                            text-align: right;
                            padding-right: 0.4em;
                        }
                        .icon-webmarketing{
                            &:before{
                            font-size: 2em;
                            margin-right: 0em;
                            vertical-align: top;
                            }
                        }
                        .icon-img-thumb{
                            font-size: 4em;
                        }
                    }
                    .image{           
                        display: inline-block;
                        vertical-align: middle;
                        text-align: left;
                        .inner_block{
                            > div{            
                                display: inline-block;
                                vertical-align: middle;
                                width: 30%;
                                position: relative;
                                font-size: 4em;
                                text-align: center;
                            }
                            .blocktext{
                                display: inline-block;
                                width: 60%;
                                margin-left: 10%;
                                position: relative;
                                text-align: right;
                                padding-right: 2em;
                                vertical-align: top;
                            }
                        }
                    }
                    .carre{
                        &.w2{
                            width: 48%;
                            margin-left: 1%;
                            margin-right: 1%; 
                            padding-top: 0.4em;
                            padding-bottom: 0.7em;                           
                        }
                        &.w3{
                            width: 31%;
                            margin-left: 1%;
                            margin-right: 1%; 
                            padding-top: 0.4em;
                            padding-bottom: 0.7em;                           
                        }
                        text-align: center;
                        > div{
                            display: block;
                            width: 100%;
                            font-size: 3.8em;
                            padding-bottom: 0.1em;
                        }
                        .blocktext{
                            display: block;
                            width: 100%;
                            text-align: center;
                        }
                    }                     
                    &.tablet{
                        font-size: 8px;
                        .block{                                    
                            margin-bottom: $mbp / 2;              
                            box-shadow:$boxshadow;
                            background-color: $bgb;
                            color: $bleucielw;        
                            transition:$transition_morph;
                            &:hover{
                                background-color: $bleucielw;
                                color: $bgb; 

                            } 
                        }
                        .block_reverse{
                            background-color: $bleucielw;
                            color: $bgb;  
                            transition:$transition_morph;
                            &:hover{
                                background-color: $bgb;
                                color: $bleucielw; 

                            } 
                        }
                        .header_pc{
                            margin-bottom: 0em;
                            padding-top: 0.4em;
                            padding-bottom: 0.6em;
                            .votre_logo{
                                font-size: 3em;
                                margin-left: 0;
                            }  
                            .icon-burger{
                                font-size: 2.3em;
                                margin-right: 0;
                                margin-left: 0.7em;
                            }     
                        }               
                        .conteneur{
                            position: relative;                        
                            display: block;
                            padding-left: 0;
                            padding-right: 0;
                            width: 100%;
                        }
                        .slider{
                            .inner_block{
                                font-size: 1.6em;
                                padding-top: 0.6em;
                                padding-bottom: 0.4em;
                                line-height: 1;
                            }
                        }
                    }  
                                           
                    &.tel{
                        font-size: 6px;
                        .block{                                    
                            margin-bottom: $mbp / 2;              
                            box-shadow:$boxshadow;
                            background-color: $bgb;
                            color: $orange2;        
                            transition:$transition_morph;
                            &:hover{
                                background-color: $orange2;
                                color: $bgb; 

                            } 
                        }
                        .block_reverse{
                            background-color: $orange2;
                            color: $bgb;  
                            transition:$transition_morph;
                            &:hover{
                                background-color: $bgb;
                                color: $orange2; 

                            } 
                        }
                        .header_pc{
                            margin-bottom: 0em;
                            padding-top: 0.4em;
                            padding-bottom: 0.6em;
                            .votre_logo{
                                font-size: 3em;
                                margin-left: 0;
                            }  
                            .icon-burger{
                                font-size: 2.3em;
                                margin-right: 0;
                                margin-left: 0.7em;
                            }     
                        }               
                        .conteneur{
                            position: relative;                        
                            display: block;
                            padding-left: 0;
                            padding-right: 0;
                            width: 100%;
                        }
                        .image{
                            .inner_block{
                                > div{
                                    display: block;
                                    width: 100%;
                                    font-size: 3em;
                                    padding-top: 0.4em;
                                    padding-bottom: 0.4em;
                                    line-height: 1;
                                }
                                .icon-shop{
                                    width: 30%;
                                    padding-left: 1.4em;
                                    display: inline-block;
                                }
                                .blocktext{
                                    vertical-align: middle;
                                }
                            }
                        }
                    }  
                }                
                &.start{
                    .header_pc, .liste .block,.ico_left .block, .conteneur .block,.conteneur_left .titrep{                        
                        margin-top: 0em;opacity: 1;
                        transition: opacity $transition_morph * 4 linear, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68);
                    }
                    .ico_left .block:nth-of-type(1){
                        transition: opacity $transition_morph * 4 linear 0.3s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.3s;
                    }
                    .ico_left .block:nth-of-type(2){
                        transition: opacity $transition_morph * 4 linear 0.6s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.6s;
                    }
                    .ico_left .block:nth-of-type(3){
                        transition: opacity $transition_morph * 4 linear 0.9s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.9s;
                    }
                    .ico_left .block:nth-of-type(4){
                        transition: opacity $transition_morph * 4 linear 1.2s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 1.2s;
                    }
                    .conteneur .block:nth-of-type(1){
                        transition: opacity $transition_morph * 4 linear 0.3s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.3s;
                    }
                    .conteneur .block:nth-of-type(2){
                        transition: opacity $transition_morph * 4 linear 0.4s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.4s;
                    }
                    .conteneur .block:nth-of-type(3){
                        transition: opacity $transition_morph * 4 linear 0.5s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.5s;
                    }
                    .conteneur .block:nth-of-type(4){
                        transition: opacity $transition_morph * 4 linear 0.6s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.6s;
                    }
                    .conteneur .block:nth-of-type(5){
                        transition: opacity $transition_morph * 4 linear 0.7s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.7s;
                    }
                    .conteneur .block:nth-of-type(6){
                        transition: opacity $transition_morph * 4 linear 0.8s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.8s;
                    }
                    .conteneur .block:nth-of-type(7){
                        transition: opacity $transition_morph * 4 linear 0.9s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 0.9s;
                    }
                    .conteneur .block:nth-of-type(8){
                        transition: opacity $transition_morph * 4 linear 1.0s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 1.0s;
                    }
                    .conteneur .block:nth-of-type(9){
                        transition: opacity $transition_morph * 4 linear 1.1s, margin-top $transition_morph * 4 cubic-bezier(.57,.59,0,1.68) 1.1s;
                    }
                }

            }
            &#pcmorph{
                .blockin{
                    width: $pc_width;
                    height: $pc_height;
                    border-width: $pc_bord;
                    border-bottom-width: $pc_bord_bas;
                    border-radius: $radiusecran;                    
                    background-color: rgba(250,250,250, 0.95);
                    transform: rotateY(0deg) rotateX(0deg);
                    > div, > div > div {
                        height: $pc_height;
                    }
                }                
                &:before{                        
                    opacity:1;
                    bottom: - $pc_base_height;
                }/* 
                &:after{
                    content: "\53";
                    opacity: 1;                    
                    font-size: 26rem;
                    z-index: -1;
                    transition: 1s ease-in-out 1.2s;
                } */
            }
            &#telmorph{
                .blockin{
                    width: $tel_width;
                    height: $tel_height;
                    border-width: $tel_bt;
                    border-bottom-width: $tel_bb;
                    border-left-width: $tel_side;
                    border-right-width: $tel_side;
                    border-radius: $tel_br; 
                    transform: translateY($pc_base_height/ 3) rotateY(-10deg) rotateX(15deg);
                    > div, > div > div {
                        height: $tel_height;
                    }
                }
            }
            &#tabletmorph{
                .blockin{
                    width: $tablet_width;
                    height: $tablet_height;
                    border-width: $tablet_bord;
                    border-radius: $tablet_brad;
                    transform: translateY($tablet_brad / 3) rotateY(10deg) rotateX(15deg);
                    > div, > div > div {
                        height: $tablet_height;
                    }
                }
            }
        }
    }
}

.h5{height: 5%;}
.h15{height: 15%;}
.h25{height: 25%;}
.h35{height: 35%;}
.h45{height: 45%;}
.h55{height: 55%;}
.h65{height: 65%;}
.h75{height: 75%;}
.h85{height: 85%;}
.h95{height: 95%;}

.h10{height: 10%;}
.h20{height: 20%;}
.h30{height: 30%;}
.h40{height: 40%;}
.h50{height: 50%;}
.h60{height: 60%;}
.h70{height: 70%;}
.h80{height: 80%;}
.h90{height: 90%;}
.h100{height: 100%;}
.commandes,#textpc{    
    //position: relative;
    //min-height: $hecran;
    //padding-bottom: $mb/4;
    color: #dfdfdf;
    > a, > div{          
        padding: 30px;
    }
}
.commandes{
    display: block;
    //margin-top: $himgpiedpecran;
    text-align: center;
    //padding-top: $mb + $mb / 2;
    i{
        display: block;
        font-weight: 400;
        //vertical-align: middle;
        //margin-right: 15px;
        font-size: 8rem;
        margin-bottom: 1rem;
    }
    a{
        display: inline-block;
        text-transform: uppercase;
        font-size: 2rem;
        text-decoration: none;
        line-height: 1;
        font-weight: 700;  
        color: #dfdfdf;
        opacity: 0.7;
        transition: 0.3s;
        &.active_morph{
            opacity: 1;
        }
        &:hover{
            opacity: 1;
        }
    }
}
#textpc{
    //text-align: left;
    //margin-top: $himgpiedpecran;
    font-size: 1.5rem;
    //margin-right: 1%;
    //margin-left: 19%;
    //display: inline-block;
    text-align: right;
    //width: 80%;
    > div{
        display: none;
        //position: absolute;
        //bottom: -$mb;
        //left: 0;
        //right: 0;
        //padding: 0;
        margin: 0;
        font-size: 2rem;
        text-align: left;
        h2{            
            display: block;
            text-transform: uppercase;
            font-family: lato;
            font-size: 2.6rem;
            text-decoration: none;
            line-height: 1;
            font-weight: 700;  
            color: #fff;
            margin: 0;
            padding-top: 0;
            padding-bottom: 10px;         
            i{
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
            }
        }
        ul{
            display: block;
            li{
                padding: 10px 0;
                &:before{
                    margin-right: 15px;
                    vertical-align: middle;
                }

            }
        }
    }
}
#textsouspc{
    text-align: center;
    position: relative;
    margin-top: $mb/4;
    margin-bottom: $mb/2;
    height: 2.5rem;
    display: block;
    h2{
        display: none;
        text-transform: none;
        position: absolute;
        left: 15px;
        right: 15px;
        color: rgba(255, 255, 255, 0.7);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-family: lato;
        font-size: 3.4rem;
        letter-spacing: 1px;
        height: 2.5rem;
        font-style: italic;
        font-weight: 700;   
        i{
            font-size: 1.2rem;
            line-height: 2.5rem;
            font-weight: 400;
                vertical-align: super; 
            &.icon-quote-left{
                margin-right: 10px;               
            }
            &.icon-quote-right{
                margin-left: 10px;
            }
        } 
    }
}







.bottom_intro_home {
    position: relative;   
    .bg{
        height: 75%;            
        transition: background-color 0.8s ease-in-out;
        }
    .cck_forms{
        margin: $mb auto 0;
        transition:0.3s;
        .cck_label_text{
            label{ 
                font-size: 1.8em;
                text-align: center;
                color: #fff;
                opacity: 0.9;
                &:before{
                    vertical-align: middle; 
                    margin-right: 10px; 
                }
            }
        } 
        &:hover{
        }  
    }
}
#intro_pc{
    .navigation{
       margin-bottom: $mb / 2;
       margin-top: $mb / 4;
       display: block;
        ul{ 
            li{    
                display: inline-block;
                margin: 10px 10px;
                font-size: 1.4rem;
                line-height: 1;
                font-weight: 300;
                a, span{
                    padding: 10px 15px;
                    background: #fff;
                    color:$text;
                    text-decoration: none;
                    border-radius: 50%;
                    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
                    transition: 0.8s;
                    &.active_slide,&:hover{
                            color:$tool;
                            font-weight: 700;
                    }
                    &:before{
                        vertical-align: middle;
                    }
                    &.icon-left{
                        &:before{
                            content:"\58";
                            transition: 0.3s;
                        }
                        &.min_left_slide{                            
                            &:before {
                                content: "\73";
                            }

                        }
                    }
                    &.icon-filtre{
                        color: $toolw;
                        &:before{

                        }
                    }
                }
            }
        }
    }
}
.wrapper_carrousel{
    width: 100%;
    margin-bottom: $mb / 2;
    position: relative;
    overflow: hidden;
    #carrousel{
        position: absolute;
        top: 0;
        left: 0;
        &.low_result{
            left: 50% !important;  
            transform:translateX(-50%);
            transition:0.3s;
        }
    }
}
.module_portolio_home{
    /* padding-left: $mb; */
    /* padding-right: $mb; */ 
    .portfolio_home.item{
        float: left;
        opacity: 1;
        transform:scale(0.8);
        //width: 400px;
        transition: 0.6s 0.3s;      
        .lien_intro{
            text-decoration: none;
            h3{
                margin: 0px auto 0px;
                padding-left: 5%;
                padding-right: 5%;
                padding-top: 0;
                padding-bottom: 0;
                font-family: lato;
                text-decoration: none;
                font-size: 1.6rem;
                letter-spacing: 0.5px;
                color: rgba(255, 255, 255, 1);
                text-shadow: 0 1px 2px rgba(0,0,0,0.3);
                line-height: 1;
                opacity: 0.6;
                transition: 0.6s 0.3s;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        &.active_slide{
            transform:scale(1);
            opacity: 1;
            h3{
                opacity: 0.9;
            }
        }
        &.previous_slide,&.next_slide{
            //opacity: 0.8;
        }
        &.low_result_item{
            transform:scale(0.9);
            opacity: 1;
        }  
    }
    .img_affiche{
        max-width: 100%;
        height: auto;
    }
}
.resultats_slide{
    font-style: italic;
    font-weight: 400;
    font-size: 2rem;
}
.valider{
    position: absolute;
    z-index: 1;
    top: 1.5rem;
    right: 1rem;
    a{
        color: #fff;
        font-size: 3rem;
        text-decoration: none; 
    }
}

#prix_pc{
    position: relative;
    background-image: url('../../../images/bg/bg65.jpg');
    background-size: cover;
    text-align: center;
    color: #fff;
    &:before{
        content:'';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.7);
    }
}

.tableau_prix{
    font-size: 0;
    margin-top: $mb;
    > div {
        position: relative;
        float: none;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        font-size: 1.5rem;
        line-height: 1;
        > div{    
            background: rgba(255,255,255,0.9);
            color: $text;
            text-align: center;
            @extend .bold;
            .head{
                border-bottom: 1px solid #c8c8c8;
                .titre_tab{
                    font-size: 2rem;
                    line-height: 2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    display: block;
                    background: #333;
                    color: #fff;
                    padding: 2rem 1rem;
                    &.recommender{
                        background: $tool;
                        padding: 3rem 1rem;
                    }
                }
                .prix{
                    .euros{
                        @extend .bold;
                        font-size: 2rem;
                        line-height: 2rem;
                        position: relative;
                        top: -2rem;
                    }
                    .mensualite{
                        font-size: 5rem;
                        font-weight: 700;
                        line-height: 5rem;    
                        display: inline-block;
                        margin: 2rem -0px 0px;
                    }
                    .par_mois{
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                    .pendant {
                        display: block;
                        margin-bottom: 2rem;
                        font-size: 1.3rem;
                        color: #ab2828;
                        font-style: italic;
                        font-weight: 700;
                        text-decoration: none;
                    }
                }
            }
            &.tab2{    
                top: -2rem;
                margin-bottom: 6rem;
                padding-bottom: 1rem;
                box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
                z-index: 1;
                position: relative;

            }
            .tab_detail{  
                .tab{
                    border-top: 1px solid #c8c8c8;
                    padding-top: 3rem;
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                    &:first-of-type{
                        border-top: none;
                        padding-top: 0;       
                    }
                    span{  
                        display: block;
                        text-align: center;
                        font-size: 1.3rem;
                        line-height: 1.2;
                        margin-bottom: 1.5rem;
                        text-align: left;
                        padding-left: 8%;
                        padding-right: 1%;
                        &:before{
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                        strong{
                            font-weight: 400;
                        }
                        &.shide{
                            opacity: 0;
                        }
                        &.tab_detail_theme{
                            font-weight: 700;
                            font-style: italic;
                            font-size: 1.7rem;
                            //text-align: center;
                            &:before{
                                font-size: 4rem;
                                color: $toolw;
                            }                     
                        }
                        a{
                            //text-align: center;
                            text-decoration: none;
                            display: block;
                            text-transform: uppercase;
                            color: $toolw;
                            font-size: 1rem;
                            font-weight: 700;
                            margin-top: 0.5rem;
                            &:before{
                                vertical-align: middle;
                                margin-right: 1rem;
                                margin-left: 3rem;
                            }
                        }                    
                    }
                }
            }
            .purchase{    
                border-top: 1px solid #c8c8c8;
                margin-top: 4rem;
                display: block;
                font-size: 2rem;
                line-height: 2rem;
                font-weight: 700;
                //background: $tool;
                padding: 2rem 1rem;
                color: #fff;
                a{
                    display: inline-block;
                    color: $tool;
                    padding: 1.5rem 2.5rem;
                    text-decoration: none;
                    &:before{
                        margin-right: 1rem;
                    }
                }
            }

        }
    }
}


#block_start{
}

#block_haut{
    height: 100vh; 
    color: #fff;
    position: relative;
    .bg_triangle{
        position: absolute;
        z-index: -10;
        right: -105vw;
        top: -105vh;
        width: 200vw;
        height: 200vh;
        transform: rotate(25deg);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
        background-image: linear-gradient(25deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 60%);
    }   

    #text_rotate{
        .text_rotate {
            position: relative;  
            display: inline-block;
            @extend .bold;
            text-transform: uppercase;
            text-align: left;
            color: $blanc;
            //margin-bottom: $mb;
            p {
                display: inline-block;
                vertical-align: top;
                font-size:4rem;
                line-height: 1;
                margin: 0;
                padding: 0;
                text-indent: 0;
                &.pre_text_rotate {
                    span{
                        font-weight: 700;
                        font-size: 4.1rem;
                    }
                }
                &.text_autre{
                    margin-left: 1rem;
                    span{
                        font-style: italic;
                    }
                }
            }
            .word {
                position: absolute;
                font-weight: 700;
                font-size: 6rem;
                opacity: 0;        
                .letter {
                    display: inline-block;
                    position: relative;
                    float: left;    
                    white-space: pre;
                    transform: translateZ(25px);
                    transform-origin: 50% 50% 25px;
                    &.out {
                        transform: rotateX(90deg);
                        transition: transform 0.42s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                    }

                    &.behind {
                        transform: rotateX(-90deg);
                    }

                    &.in {
                        transform: rotateX(0deg);
                        transition: transform 0.42s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    }
                }
            }

            .performante {
                color: darken($tool, 20%);
            }

            .concurrentielle {
                color: darken($tool, 20%);
            }

            .attirante {
                color: darken($tool, 20%);
            }

            .marquante {
                color: darken($tool, 20%);
            }

            .connue {
                color: darken($tool, 20%);
            }
        }
    }

    #anim_top_home{
        display: block;        
        font-size:4rem;
        line-height: 1;
        .avion{
            position: relative;
            z-index: -12;
            right: -100%;
            margin-top: $mb;
            animation: avion_top 5s linear 1.5s forwards;
        }
        .bon_message{
                position: relative;
                z-index: 10;
                right: 15%;
                text-align: right;
                text-transform: uppercase;
                text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
                @extend .bold;
                font-style: italic;
                font-size: 3.4rem;
//                margin-top: -3.4rem;
                margin-top: $mb;
                opacity: 0;
                animation: bon_message 2s ease-out 1.9s forwards;
                &:before{
                    vertical-align: middle;
                    margin-right: 15px;
                    font-size: 6rem;
                    opacity: 0.8;
                }
                span{
                    font-weight: 700;
                }
        }
        .bon_message_detail{
            position: relative;
            right: 0%;
            left: 60%;
            width: 40%;
            background: rgba(255,255,255,0.7);
            box-shadow: -5px 5px 10px rgba(0,0,0,0.1);
            p{
                font-weight: 400;
                font-style: italic;
                font-size: 1.9rem;
                line-height: 1.2;
                letter-spacing: 2px;
                margin: 60px 0px;
                padding: 60px 68px;
                text-align: left;
                color: #000;
                span{
                    font-weight: 700;
                    &.centrer{
                        display: block;
                        text-align: center; 
                        font-size: 110%;
                    }
                }
            }
        }
        .immeuble{
            position: absolute;
            bottom: 15%;
            left: 0;
            //z-index: -1;
            transform: scale(0.95);
            transform-origin: left 50%;
            animation: zoomchill 5s ease-in-out forwards;
        }
        .pointeur_immeuble{
            position: absolute;
            bottom: 14%;
            left: 10%;
            z-index: 0;
            i{
                display: inline-block;
                animation: bounce 2s ease-in-out infinite;
                &:before{
                    color: $tool;
                    font-size: 20rem;
                    line-height: 1;
                }
            }
            &:after {
                bottom: -5px;
                left: 24%;
                right: 24%;
                height: 30px;        
                transform: scale(1);
                animation: ombre_bounce 2s ease-in-out infinite;
            }
        }
        .groupe_personne{
            .deuxieme_ranger,.premiere_ranger{
                .personnes{
                    position: absolute;
                    //z-index: -1;
                    .personne{
                        position: relative;
                        display: inline-block;
                        span{
                            &:after {
                                bottom: 4px;
                                left: 34%;
                                right: 34%;
                                height: 20px;
                                opacity: 0.7;
                            }
                            i{
                                position: relative;
                                &:before{
                                    line-height: 1;
                                    text-align: bottom;
                                    display: block;
                                }
                                &.icon-personne{
                                    font-size: 20rem;
                                }
                                &.icon-bulle-gauche,&.icon-bulle-droite{
                                    font-size: 14rem;
                                    position: absolute;
                                    top: -60%;
                                    opacity: 0.8;
                                    .iconlvl2{
                                        position: absolute;
                                        top: 4rem;
                                        color: #fff;
                                        font-size: 5rem;                                        
                                    }
                                }
                                &.icon-bulle-gauche{
                                    right: -10%;
                                    .iconlvl2{
                                        right: 30%;
                                    }
                                }
                                &.icon-bulle-droite{
                                    left: -10%;
                                    .iconlvl2{
                                        left: 30%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .premiere_ranger{
                .personnes{
                    bottom: 15%;
                    left: $mb * 2;
                    z-index: 0;
                    .personne{
                        margin-left: -5rem;
                        margin-right: -5rem;
                        transform:scale(1);
                        &:nth-of-type(2){
                            transform:scale(1.1);
                            z-index: 2;
                            span{
                                i.icon-bulle-gauche{
                                    top: -68%;
                                    right: 1%;
                                }
                            }
                        }
                        span{

                        }
                    }
                }
            }
            .deuxieme_ranger{
                .personnes{
                    bottom: 17%;
                    left: $mb;
                    z-index: -1;
                    .personne{
                        margin-left: -5rem;
                        margin-right: -5rem;
                        transform:scale(0.8);
                        &:nth-of-type(2),&:nth-of-type(3){
                            span{
                                i{
                                    &.icon-bulle-gauche,&.icon-bulle-droite{
                                        font-size: 8rem;
                                        position: absolute;
                                        top: -40%;
                                        opacity: 0.8;
                                    }
                                    &.icon-bulle-gauche{
                                        right: 15%;
                                    }
                                    &.icon-bulle-droite{
                                        left: 15%;
                                    }
                                }
                            }
                        }
                        span{

                        }
                    }
                }
            }
            /* & > div > div > div  */
        }
    }
}